import { Component, Input, SimpleChanges } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { ApplicationSchemeXActiveIngredientRate, ApplicationSchemeXApplication } from 'src/app/shared/models/application-scheme';
import { Precursor } from 'src/app/shared/models/echo/precursor';
import { Endpoint } from 'src/app/shared/models/endpoint';
import { Project } from 'src/app/shared/models/project';
import { Run } from 'src/app/shared/models/run/run';
import { QcIconComponent } from 'src/app/shared/renderers/qc-icon/qc-icon.component';
import { Constants } from 'src/app/shared/utils/constants';
import { Utils } from 'src/app/shared/utils/utils';
import { EEARunProjectQcColdef } from './eea-run-project-qc.coldef';
import { ProjectXCompoundXModel } from 'src/app/shared/models/project-x-compound-x-model';

@Component({
  selector: 'app-eea-run-project-qc',
  templateUrl: './eea-run-project-qc.component.html',
  styleUrls: ['./eea-run-project-qc.component.css']
})
export class EEARunProjectQCComponent {

  @Input() runs!: Run[];
  @Input() selectedProject?: Project;
  columnsToShow: string[] = []
  missingDataRows: any[] = []
  showGrid: Boolean = false;
  showWarningAlert: Boolean = false;
  warningAlertText: string = '';
  showAlert: Boolean = false;
  columnsDef: any;
  rowData: any;


  constructor(private qcColdef: EEARunProjectQcColdef,) { }

  ngOnInit(): void {
    this.checkMissingDataByRow()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['runs'].currentValue) {
      this.showGrid = false;
      this.showWarningAlert = false;
      this.checkMissingDataByRow();
    }
  }

  groupRowsByModel(): { [key: string]: Run[] } {
    let groupDataByModel: { [key: string]: Run[] } = {};
    this.runs.forEach(run => {
      const groupKey = run!.model!.name;
      if (!groupDataByModel[groupKey]) {
        groupDataByModel[groupKey] = [];
      }
      groupDataByModel[groupKey].push(run);
    });
    return groupDataByModel;
  }

  checkMissingDataByRow() {
    let missingData: any[] = [];
    const groupedRows: { [key: string]: Run[] } = this.groupRowsByModel();
    if (Object.keys(groupedRows).length > 0) {
      Object.entries(groupedRows).forEach(([key, value]) => {
        var requiredColumns = this.validateRequiredColumnsByModel(key, value)
        if (requiredColumns) {
          requiredColumns = [...requiredColumns];
          const rowsImcomplete = key.startsWith(Constants.MODELS.PWC) ? this.checkMissingDataPWC(value, requiredColumns) : this.checkMissingData(value, requiredColumns);
          missingData.push(...rowsImcomplete)
        }
      });
      this.columnsToShow = [...new Set(missingData.flatMap(obj => obj.allMissingColumns))];
      this.missingDataRows = missingData;
      this.insertDynamicColumnDefs();
      this.showQcResult();
    } else {
      this.createWarningAlert();
    }
  }

  createWarningAlert(): void {
    if (this.selectedProject?.projectPk === -1) {
      this.warningAlertText = 'There is no a selected project.'
    }
    else if (this.selectedProject?.dataSets == null) {
      this.warningAlertText = 'There are no datasets for this project.'
    }
    else if (this.runs.length === 0) {
      this.warningAlertText = 'There are no runs to be process by the QC'
    }
    this.showWarningAlert = true;
  }

  checkMissingDataForMetabolites(pData: any): { [key: string]: { column: string, message: string }[] }[] {
    let missingDataByMetabolites: { [key: string]: { column: string, message: string }[] }[] = [];
    pData?.metabolites?.forEach((metabolite: Endpoint) => {
      let metaboliteColumns: string[] = Constants.METABOLITES_BY_MODEL[pData?.model?.name] ?? [];
      metaboliteColumns = this.includeColumnsByConditions(metabolite, metaboliteColumns, pData?.model?.name);
      let columnsByMetabolite: { column: string, message: string }[] = [];
      for (const column of metaboliteColumns) {
        let value = metabolite[column as keyof Endpoint];
        if (Utils.isEmptyValue(value)) {
          columnsByMetabolite.push({ column: column, message: 'Missing Data' });
        }
      }
      let metaboliteFormationFractions = this.checkMissingDataForFractions(metabolite, pData.model.name);
      columnsByMetabolite = [...columnsByMetabolite, ...metaboliteFormationFractions];
      columnsByMetabolite.length > 0 && missingDataByMetabolites.push({ [metabolite.substanceName!]: columnsByMetabolite });
    });
    return missingDataByMetabolites;
  }

  checkMissingDataForFractions(metabolite: Endpoint, pModelName: string): { column: string, message: string }[] {
    let missingColumns: { column: string, message: string }[] = [];
    if (!Constants.MODELS_THAT_REQUIRE_FORMATION_FRACTION.find(x => x == pModelName)) {
      return [];
    }
    missingColumns = [...this.validateExistencePrecursorsInFormationFractions(metabolite, pModelName)];
    this.calculateMissingDataForPrecursors(metabolite, missingColumns, pModelName);
    return [...new Set(missingColumns)];
  }

  private calculateMissingDataForPrecursors(metabolite: Endpoint, missingColumns: { column: string; message: string; }[], pModelName: string) {
    let sumFormationFraction = 0;
    metabolite?.precursors?.forEach((precursor: Precursor) => {
      for (const column of Constants.COLUMS_BY_FRACTIONS) {
        let value = precursor[column as keyof Precursor];
        if (Utils.isEmptyValue(value)) {
          missingColumns.push({ column: precursor.precursorType, message: 'Missing Data' });
          continue;
        }
        if (pModelName === Constants.MODELS.PELMO && column === Constants.FIELD_NAMES.FORMATION_FRACTION) {
          sumFormationFraction += Number(precursor[column as keyof Precursor]);
        }
      }
    });
    if (pModelName === Constants.MODELS.PELMO && sumFormationFraction > 1) {
      missingColumns.push({ column: Constants.FIELD_NAMES.FRACTION_IN_SOIL, message: 'The sum of the fraction transformed in soil must be equal or less than 1' });
    }
  }

  validateExistencePrecursorsInFormationFractions(metabolite: Endpoint, pModelName: string): { column: string, message: string }[] {
    let fractions = metabolite?.precursors?.flatMap((precursor: Precursor) => precursor.precursorType) || [];
    return Constants.REQUIRED_FRACTIONS_BY_METABOLITES_BY_MODEL[pModelName]
      .filter((x: string) => !fractions.includes(x))
      .map((missingFraction: string) => ({ column: missingFraction, message: 'Missing Data' }));
  }

  validateRequiredColumnsByModel(model: string, runs: Run[]): any {
    let columns = Constants.REQUIRED_COLUMNS_BY_MODEL[model];
    if (columns) {
      columns = this.includeColumnsByModel(model, columns, runs);
    }
    return columns;
  }

  validateCropEvent(run: Run, pColumns: string[]): any {
    let columns = pColumns;
    if (Constants.CROP_EVENT_EXCEPTION_MODELS.includes(run.model!.name)) {
      let dateType = run?.applicationSchemeData?.dateType;
      if (!Utils.isEmptyValue(dateType)) {
        columns = dateType === Constants.DATE_TYPE_VALUES.ABSOLUTE ?
          columns.filter((field: any) => !([Constants.FIELD_NAMES.APP_SCHEME_CROP_EVENT, Constants.FIELD_NAMES.APP_SCHEME_DAYS_SINCE].includes(field))) :
          columns;
      } else {
        columns = columns.filter((field: any) => !([Constants.FIELD_NAMES.APP_SCHEME_CROP_EVENT, Constants.FIELD_NAMES.APP_SCHEME_DAYS_SINCE].includes(field)));
      }
    }
    return columns;
  }

  validateCropPk(run: Run, pColumns: string[]): any {
    let columns = [...pColumns];
    if (Utils.isEmptyValue(run?.applicationSchemeData?.coreApplicationSchemePk)) {
      columns = columns.filter((field: string) => field !== Constants.FIELD_NAMES.GLOBAL_CROP);
    }
    return columns;
  }

  validateApplicationNumberPWC(run: Run, pColumns: string[]) {
    let columns = [...pColumns];
    if (Utils.isEmptyValue(run?.applicationSchemeData?.applicationSchemeXApplication)) {
      columns = columns.filter((field: string) => field !== Constants.PWC_FIELD_NAMES.NUMBER_OF_APPLICATIONS);
    }
    return columns;
  }

  includeSwanColumns(model: string, pColumns: string[]): string[] {
    let columns = [...pColumns];
    let swanColumns = [Constants.FIELD_NAMES.APP_SCHEME_NOZZLE_DRIFT_REDUCTION_10M, Constants.FIELD_NAMES.APP_SCHEME_NOZZLE_DRIFT_REDUCTION_20M];
    if (model === Constants.MODELS.SWASH) {
      let runSwanModel = this.selectedProject!.projectXCompoundXModel?.some((x: ProjectXCompoundXModel) => x.ModelName === Constants.MODELS.SWAN);
      if (runSwanModel) {
        columns = [...columns, ...swanColumns];
      }
    }
    return columns;
  }

  includeColumnsByModel(model: string, pColumns: string[], runs: Run[]): string[] {
    let columns = [...pColumns];
    switch (model) {
      case Constants.MODELS.SWASH:
        if (this.selectedProject!.projectXCompoundXModel?.some((x: ProjectXCompoundXModel) => x.ModelName === Constants.MODELS.SWAN)) {
          columns = [...columns, Constants.FIELD_NAMES.APP_SCHEME_NOZZLE_DRIFT_REDUCTION_10M, Constants.FIELD_NAMES.APP_SCHEME_NOZZLE_DRIFT_REDUCTION_20M];
        }
        break;
      case Constants.MODELS.PWC:
        if (this.selectedProject!.projectXCompoundXModel?.some((x: ProjectXCompoundXModel) => x.ModelName === Constants.MODELS.PWC)) {
          columns = [...columns];
        }
        break;
    }
    return columns;
  }

  excludeColumnsByConditions(run: Run, pColumns: string[]): any {
    let columns = [...pColumns];
    columns = this.validateCropEvent(run, columns);
    columns = this.validateCropPk(run, columns);
    columns = this.validateApplicationNumberPWC(run, columns);
    return columns;
  }

  includeColumnsByConditions(run: Run, pColumns: string[], modelName: string): any {
    let columns = [...pColumns];
    columns = this.includeColumnsByKineticModel(run, columns, modelName);
    return columns;
  }

  includeColumnsByKineticModel(run: Run, pColumns: string[], modelName: string): any {
    if (Constants.SOIL_MODELS.includes(modelName) && run.kineticModel != Constants.KINETIC_MODELS.SFO) {
      return [...pColumns, ...(Constants.REQUIRED_COLUMNS_BY_KINETIC_MODEL[run.kineticModel as string] ?? [])];
    }
    return pColumns;
  }

  checkMissingData(runs: Run[], requiredColumns: string[]) {
    let missingDataByRunId: any[] = [];
    runs.forEach((run: Run) => {
      let missingColumns = [];
      let columnsToValidate = [...requiredColumns];
      columnsToValidate = this.excludeColumnsByConditions(run, columnsToValidate);
      columnsToValidate = this.includeColumnsByConditions(run, columnsToValidate, run.model!.name);
      for (const column of columnsToValidate) {
        const properties = column.split('.');
        let nestedData = run;
        for (const property of properties) {
          if (nestedData && nestedData.hasOwnProperty(property)) {
            nestedData = (nestedData as any)[property];
          } else {
            missingColumns.push(column);
            break;
          }
        }
        if (Utils.isEmptyValue(nestedData)) {
          missingColumns.push(column);
        }
      }
      let metabolites = this.checkMissingDataForMetabolites(run);
      let applications = this.checkMissingDataForApplications(run);
      let applicationScheme = this.checkMissingDataForApplicationScheme(run);
      let allMissingColumns = [...new Set([...missingColumns, ...applicationScheme.map(item => item.column), ...this.getAllColumnValues(metabolites), ...Object.values(applications).flat()])]
      if (allMissingColumns.length > 0) {
        missingDataByRunId.push({
          run,
          allMissingColumns,
          missingParentColumns: missingColumns,
          missingColumnsByMetabolites: metabolites,
          missingColumnsByApplications: applications,
          missingColumnsMessages: [...new Set([...applicationScheme])]
        })
      }
    });
    return missingDataByRunId;
  }

  getAllColumnValues(data: { [key: string]: { column: string, message: string }[] }[]): string[] {
    const columnValues: string[] = [];

    for (const obj of data) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          for (const item of obj[key]) {
            columnValues.push(item.column);
          }
        }
      }
    }

    return columnValues;
  }

  checkMissingDataForApplicationScheme(run: Run): { column: string, message: string }[] {
    let columns = [];
    if (run.model?.name === Constants.MODELS.SWASH && run.applicationSchemeData?.numberOfApplications > 5) {
      columns.push({ column: Constants.FIELD_NAMES.NUMBER_OF_APPLICATIONS, message: 'Number of applications exceeds 5 Applications.' });
    }
    if ((run.model?.name === Constants.MODELS.PEARL && (run.applicationSchemeData?.repeatInterval < 1 || run.applicationSchemeData?.repeatInterval > 3)) ||
      (run.model?.name === Constants.MODELS.PELMO && run.applicationSchemeData?.repeatInterval < 1)) {
      columns.push({ column: Constants.FIELD_NAMES.APP_SCHEME_REPEAT_INTERVAL, message: run.model?.name === Constants.MODELS.PELMO ? 'The value must be greater than 0.' : 'The value must be in the range of 1 to 3.' });
    }
    return columns;
  }

  checkMissingDataPWC(runs: Run[], requiredColumns: string[]) {
    let missingDataByRunId: any[] = [];
    runs.forEach((run: Run) => {
      let missingColumns = [];
      let columnsToValidate = [...requiredColumns];
      columnsToValidate = this.excludeColumnsByConditions(run, columnsToValidate);
      for (const column of columnsToValidate) {
        const properties = column.split('.');
        let nestedData = run;
        for (const property of properties) {
          if (nestedData && nestedData.hasOwnProperty(property)) {
            nestedData = (nestedData as any)[property];
          } else {
            missingColumns.push(column);
            break;
          }
        }
        if (Utils.isEmptyValue(nestedData)) {
          missingColumns.push(column);
        }
      }
      let metabolites = this.checkMissingDataForMetabolites(run);
      let applications = this.checkMissingDataForPWCApplications(run);
      let applicationsXApplications = this.checkMissingDataForApplicationsXApplications(run);
      let allMissingColumns = [...new Set([...missingColumns, ...metabolites.flatMap((x: any) => Object.values(x).flat()), ...Object.values(applicationsXApplications).flat()])]
      if (allMissingColumns.length > 0) {
        missingDataByRunId.push({
          run,
          allMissingColumns,
          missingParentColumns: missingColumns,
          missingColumnsByMetabolites: metabolites,
          missingColumnsByApplications: applications
        })
      }
    });
    return missingDataByRunId;
  }

  checkMissingDataForPWCApplications(run: Run): { [key: string]: string[] } {
    let missingColumnsByMolecule: { [key: string]: string[] } = {};
    let rootFields: string[] = this.validateAppSchemeRootFields(run);
    run.applicationSchemeData?.applicationSchemeXActiveIngredientRate?.forEach((item: ApplicationSchemeXActiveIngredientRate) => {
      if (Utils.isEmptyValue(item[Constants.FIELD_NAMES.RATE as keyof ApplicationSchemeXActiveIngredientRate])) {
        let substanceName = item.substanceName!;
        if (!missingColumnsByMolecule[substanceName]) {
          missingColumnsByMolecule[substanceName] = [...rootFields, Constants.FIELD_NAMES.RATE]
        };
      }
    });
    return missingColumnsByMolecule;
  }

  //TODO: Luis Miranda - This method must be uncommented when variable rates work.
  // checkMissingDataForApplications(run: Run): { [key: string]: string[] } {
  //   let missingColumnsByMolecule: { [key: string]: string[] } = {};
  //   let rootFields: string[] = this.validateAppSchemeRootFields(run);
  //   run.applicationSchemeData?.applicationSchemeXActiveIngredientRate?.forEach((item: ApplicationSchemeXActiveIngredientRate) => {
  //     let missingColumns: string[] = [];
  //     let columnsToValidate = this.GetRateColumnsToBeValidated(run);
  //     columnsToValidate.forEach((field: string) => {
  //       if (Utils.isEmptyValue(item[field as keyof ApplicationSchemeXActiveIngredientRate])) {
  //         missingColumns.push(field)
  //       }
  //     });
  //     if (!missingColumnsByMolecule[item.substanceName!]) {
  //       missingColumnsByMolecule[item.substanceName!] = [...rootFields, ...missingColumns]
  //     }else{
  //       missingColumnsByMolecule[item.substanceName!] = [...missingColumnsByMolecule[item.substanceName!], ...missingColumns]
  //     };
  //   });
  //   return missingColumnsByMolecule;
  // }

  //TODO: Luis Miranda - This method must be removed when variable rates work.
  checkMissingDataForApplications(run: Run): { [key: string]: string[] } {
    let missingColumnsByMolecule: { [key: string]: string[] } = {};
    let rootFields: string[] = this.validateAppSchemeRootFields(run);
    run.applicationSchemeData?.applicationSchemeXActiveIngredientRate?.forEach((item: ApplicationSchemeXActiveIngredientRate) => {
      if (Utils.isEmptyValue(item[Constants.FIELD_NAMES.RATE as keyof ApplicationSchemeXActiveIngredientRate])) {
        let substanceName = item.substanceName!;
        if (!missingColumnsByMolecule[substanceName]) {
          missingColumnsByMolecule[substanceName] = [...rootFields, Constants.FIELD_NAMES.RATE]
        };
      }
    });
    return missingColumnsByMolecule;
  }

  GetRateColumnsToBeValidated(run: Run): string[] {
    if (Constants.DATE_TYPE_EXCEPTION_MODELS.includes(run.model!.name)) {
      let dateType = run?.applicationSchemeData?.dateType;
      if (!Utils.isEmptyValue(dateType)) {
        return dateType === Constants.DATE_TYPE_VALUES.RELATIVE ? Constants.REQUIRED_FIELDS_BY_RATES : Constants.REQUIRED_FIELDS_BY_RATES.filter((field: string) => field !== Constants.FIELD_NAMES.APPLICATION_INTERVAL);
      }
    }
    return Constants.REQUIRED_FIELDS_BY_RATES;
  }

  checkMissingDataForApplicationsXApplications(run: Run): { [key: string]: string[] } {
    let missingColumnsByMolecule: { [key: string]: string[] } = {};
    run.applicationSchemeData?.applicationSchemeXApplication?.forEach((item: ApplicationSchemeXApplication) => {
      if (Utils.isEmptyValue(item[Constants.PWC_FIELD_NAMES.NUMBER_OF_APPLICATIONS as keyof ApplicationSchemeXApplication])) {
        let applicationNumber = item.application_number!;
        if (!missingColumnsByMolecule[applicationNumber]) {
          missingColumnsByMolecule[applicationNumber] = [Constants.PWC_FIELD_NAMES.NUMBER_OF_APPLICATIONS]
        };
      }
    });
    return missingColumnsByMolecule;
  }

  validateAppSchemeRootFields(run: Run): string[] {
    let rootFields: string[] = [];
    if (Utils.isEmptyValue(run.applicationSchemeData?.numberOfApplications)) {
      rootFields.push(Constants.FIELD_NAMES.NUMBER_OF_APPLICATIONS);
    }

    if (run.model?.name === Constants.MODELS.PWC && Utils.isEmptyValue(run.applicationSchemeData?.applicationInterval)) {
      rootFields.push(Constants.FIELD_NAMES.APPLICATION_INTERVAL);
    }
    return rootFields;
  }

  convertFieldToHeaderName(field: string) {
    return Constants.HEADER_NAMES_BY_FIELD[field]
  }

  insertDynamicColumnDefs() {
    this.columnsDef = [...this.qcColdef.getQcColumnsDefinition(), ...this.createDynamicColDef()]
  }

  createDynamicColDef(): any[] {
    let columnsDef: ColDef[] = [];
    this.columnsToShow?.forEach((column: string) => {
      columnsDef.push(
        {
          headerName: this.convertFieldToHeaderName(column),
          field: column,
          type: 'default',
          filter: 'agSetColumnFilter',
          width: 100,
          maxWidth: 110,
          hide: false,
          editable: false,
          wrapText: true,
          wrapHeaderText: true,
          cellRenderer: QcIconComponent,
          cellRendererParams: (params: any) => {
            return {
              instance: this,
            };
          },
        }
      );
    });
    return columnsDef;
  }

  showQcResult() {
    this.missingDataRows.length > 0 ? this.showGrid = true : this.showAlert = true;
  }
}
