import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Accordion, AccordionTabCloseEvent } from 'primeng/accordion';
import { ModelLibraryCollection } from 'src/app/shared/models/model-library';
import { Project } from 'src/app/shared/models/project';

@Component({
  selector: 'app-eea-setup-models-to-be-run',
  templateUrl: './eea-setup-models-to-be-run.component.html',
  styleUrls: ['./eea-setup-models-to-be-run.component.css']
})
export class EEASetupModelsToBeRunComponent implements OnChanges {

  @ViewChild('accordion') accordion!: Accordion;

  @Input() modelsToBeRun: any[] = [];
  @Input() columnsDefinition: any = [];
  @Input() selectedProject?: Project;
  @Input() modelsLoading: boolean = false;
  @Input() isProjectOwnershipValid: boolean = false;
  @Input() modelsCollection: ModelLibraryCollection[] = [];

  @Output() areSetupModelsValidEvent = new EventEmitter<boolean>();

  gridStyle: string = 'width: auto; height: 600px; text-align: left; z-index:-1;font-size:10px; color: #656565;';
  rowHeight: number = 180;
  selected = false;
  activeIndex: number | null = null;
  isValid: boolean = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['modelsToBeRun']) {
      this.activeIndex = null;
      this.validateSelectedModels();
    }
  }

  onClose(event: AccordionTabCloseEvent) {
    this.activeIndex = null;
  }

  onOpen({ index, originalEvent }: AccordionTabCloseEvent) {
    if (!this.modelsToBeRun[index].selected) {
      originalEvent.stopPropagation();
      const tab = this.accordion.tabs.find(t => t.findTabIndex() == index);
      if (tab) tab.selected = false;
      this.activeIndex = null;
      return;
    }
    this.activeIndex = index;
  }

  modelsListBoxSelectionChanged({ data, selectedValues, compartment }: any) {
    if (!this.selectedProject) return;
    data[compartment] = selectedValues;
    this.validateSelectedModels();
  }

  validateSelectedModels() {
    this.isValid = false;
    this.modelsToBeRun.map(model => {
      model.rowData.map((row: any) => {
        this.modelsCollection.map(model => {
          if (row[model.name]?.length > 0) this.isValid = true;
        });
      });
    });
    this.areSetupModelsValidEvent.emit(this.isValid);
  }
}