import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { firstValueFrom, take } from 'rxjs';
import { EEAGapApiService } from 'src/app/environmental-exposure-assessment/eea-gap/eea-gap.api.service';
import { EndpointDatasetService } from 'src/app/shared/components/endpoint-dataset/endpoint-dataset.service';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { Formulation } from 'src/app/shared/models/formulation';
import { Project } from 'src/app/shared/models/project';
import Swal from 'sweetalert2';
import { ERACopyProjectDialogComponent } from '../shared/era-copy-project-dialog/era-copy-project-dialog.component';
import { AutoCompleteCompleteEvent, AutoCompleteDropdownClickEvent, AutoCompleteOnSelectEvent } from 'primeng/autocomplete';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';
import { Constants } from 'src/app/shared/utils/constants';
import { PersonalProtectionEquipmentSchemeSharedApiService } from 'src/app/shared/services/personal-protection-equipment-scheme-shared.api.service';

@Component({
  selector: 'app-era-setup-inputs',
  templateUrl: './era-setup-inputs.component.html',
  styleUrls: ['./era-setup-inputs.component.css']
})
export class ERASetupInputsComponent implements OnChanges {

  @ViewChild('appDialog') copyprojectDialog!: ERACopyProjectDialogComponent;

  @Input() filteredFormulations: Formulation[] = [];
  @Input() geographies: Catalog[] = [];
  @Input() selectedProject?: Project;
  @Input() currentUserName: string = "";
  @Input() isProjectOwnershipValid: boolean = false;

  @Output() onformulationSelectionChangeEvent = new EventEmitter<Formulation>();
  @Output() onQueryFilterChangeEvent = new EventEmitter<string>();
  @Output() isSetupValidEvent = new EventEmitter<boolean>();

  createdBy: string = "";
  selectedGeography?: Catalog;
  selectedFormulation?: Formulation;
  loadingFormulations: boolean = true;
  loadingGeographies: boolean = true;
  loading: boolean = false;
  isArchiveButtonDisabled: boolean = true;

  isProjectNameValid: boolean = true;
  isProjectNameDuplicated: boolean = false;
  isFormulationValid: boolean = true;
  isGeographyValid: boolean = true;

  constructor(private gapGWApiService: EEAGapApiService,
    public dataSetService: EndpointDatasetService,
    public projectApiService: SelectedProjectApiService,
    private userService: UserLogicService,
    private personalProtectionEquipmentSchemeSharedApiService: PersonalProtectionEquipmentSchemeSharedApiService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedProject']) {
      this.createdBy = this.selectedProject ? this.selectedProject.createdBy ? this.selectedProject.createdBy : "" : this.currentUserName;
      this.validateSelectedProject();
    }
    this.loadingFormulations = changes['formulations']?.currentValue.length == 0;
    this.loadingGeographies = changes['geographies']?.currentValue.length == 0;
    if (!this.loadingFormulations) this.initSelectedFormulation();
    if (!this.loadingGeographies) this.initSelectedGeography();
    this.setArchiveButtonStatus();
  }

  initSelectedFormulation() {
    this.selectedFormulation = this.filteredFormulations.find(f => f.formulationPK == this.selectedProject?.formulationPk);
    this.updateSelectedFormulation(this.selectedFormulation);
  }

  initSelectedGeography() {
    this.selectedGeography = this.selectedProject?.regionPk ?
      this.geographies.find(g => g.key == this.selectedProject?.regionPk && g.source == 'Region') :
      this.selectedProject?.countryPk ?
        this.geographies.find(g => g.key == this.selectedProject?.countryPk && g.source == 'Country')
        : undefined;
    if (this.selectedGeography)
      this.updateSelectedGeography(this.selectedGeography);
  }

  onQueryFilterEvent({ query }: AutoCompleteCompleteEvent) {
    this.onQueryFilterChangeEvent.emit(query);
  }

  formulationSelectionChanged({ value }: AutoCompleteOnSelectEvent) {
    this.isFormulationValid = value != null;
    this.updateSelectedFormulation(value);
  }

  formulationOnClear(event: any) {
    this.updateSelectedFormulation();
  }

  formulationDropdownClicked({ query }: AutoCompleteDropdownClickEvent) {
    if (query) this.onQueryFilterChangeEvent.emit(query);
    if (!query) this.onQueryFilterChangeEvent.emit('1');
  }

  updateSelectedFormulation(formulation?: Formulation) {
    if (formulation?.formulationPK && this.selectedProject) {
      this.selectedProject.formulationPk = formulation.formulationPK;
      this.selectedProject.formulation = formulation.tradeName;
    }
    this.onformulationSelectionChangeEvent.emit(formulation);
    this.validateSetupInfoInProject();
  }

  geographySelectionChanged({ value }: DropdownChangeEvent) {
    this.isGeographyValid = value != null;
    const geography: Catalog = value as Catalog;
    this.updateSelectedGeography(geography);
  }

  updateSelectedGeography(geography: Catalog) {
    const geographiesToFilter = [Constants.CROP_GEOGRAPHIES.EUROPE_UNION, Constants.CROP_GEOGRAPHIES.UK, Constants.CROP_GEOGRAPHIES.USA];
    
    if (!geography && this.selectedProject) {
      this.selectedProject.regionPk = undefined
      this.selectedProject.countryPk = undefined;
    }

    if (geography?.source == 'Region' && this.selectedProject) {
      this.selectedProject.regionPk = geography.key;
      this.selectedProject.countryPk = undefined;
      this.selectedProject.geography = this.geographies.filter((x: Catalog) => geographiesToFilter.includes(x.name ?? ''))?.find((x: Catalog) => geography.key === x.key)?.name;
    }

    if (geography?.source == 'Country' && this.selectedProject) {
      this.selectedProject.countryPk = geography.key;
      this.selectedProject.regionPk = undefined;
      this.selectedProject.geography = this.geographies.filter((x: Catalog) => geographiesToFilter.includes(x.name ?? ''))?.find((x: Catalog) => geography.key === x.key)?.name;
    }

    this.validateSetupInfoInProject();
  }

  async projectNameChanged(value: string) {
    this.isProjectNameValid = value.length > 0;
    this.isProjectNameDuplicated = false;
    await firstValueFrom(this.projectApiService.projects).then(projects => {
      if(projects.find(p => p.name == value && p.name != '' && p.projectPk != this.selectedProject?.projectPk)) {
        this.isProjectNameValid = false;
        this.isProjectNameDuplicated = true;
      }
    });
    if (this.selectedProject)
      this.selectedProject.name = value;

    this.validateSetupInfoInProject();
  }

  projectDescriptionChanged(value: string) {
    if (this.selectedProject)
      this.selectedProject.description = value;
  }

  validateSetupInfoInProject() {
    if (this.selectedProject?.name && (this.selectedProject.regionPk || this.selectedProject.countryPk) && this.selectedProject?.formulationPk) {
      this.isSetupValidEvent.emit(true);
      return;
    }
    this.isSetupValidEvent.emit(false);
  }

  validateSelectedProject() {
    this.isProjectNameValid = this.selectedProject?.name ? this.selectedProject?.name.length > 0 : false;
    this.isFormulationValid = this.selectedProject?.formulationPk != null && this.selectedProject?.formulationPk != 0;
    this.isGeographyValid = this.selectedProject?.regionPk != null || this.selectedProject?.countryPk != null;
  }

  copyProject() {
    if (this.selectedProject?.name != undefined && this.selectedProject?.name != '')
      this.copyprojectDialog.showDialog(true, this.selectedProject?.projectPk);
  }

  onSaveDataProject(event: any) {
    const projectPkCopy = event.projectPkCopy;
    const datasetList = event.datasetList;

    this.loading = true;
    if (this.selectedProject?.projectPk == -1) {
      this.onCopyProject(projectPkCopy, -1, this.selectedProject?.name, datasetList);
    }
    else if (projectPkCopy != 0 && this.selectedProject?.projectPk != -1) {

      Swal.fire({
        text: 'Do you want to overwrite the project data?',
        showCancelButton: true,
        confirmButtonColor: '#0069be',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        icon: 'warning'
      }).then((result) => {
        if (result.value) {
          this.onCopyProject(projectPkCopy, this.selectedProject?.projectPk, '', datasetList);
        }
        else {
          this.loading = false;
        }
      });

    }
  }

  onCopyProject(projectPkCopy: number, projectPk?: number, nameProject?: string, datasetList?: any[]) {
    this.projectApiService.getAndCopyProject(projectPkCopy, projectPk, nameProject, this.currentUserName).pipe(take(1)).subscribe(projectNew => {
      this.gapGWApiService.getAndCopyApplicationSchemesByProjectAndCompartment(projectPkCopy, projectNew.projectPk)
        .pipe(take(1)).subscribe(applicationSchemes => {
          this.dataSetService.getAndCopyCoreDatasetsByProject(projectPkCopy, projectNew.projectPk, datasetList)
            .subscribe((result) => {
              this.personalProtectionEquipmentSchemeSharedApiService.getAndCopyPersonalProtectionEquipmentSchemes(projectPkCopy, projectNew.projectPk)
                .subscribe(() => {
                  this.projectApiService.setSelectedProject(projectNew);
                  this.loading = false;
                  Swal.fire({
                    text: 'Data Saved Successfully',
                    confirmButtonColor: '#0069be',
                    confirmButtonText: 'Ok',
                  });
                });
            });
        });
    });
  }

  archiveProject() {
    if (this.selectedProject?.name != undefined && this.selectedProject?.name != '')
      if (this.selectedProject?.name != undefined && this.selectedProject?.name != '')
        Swal.fire({
          text: 'Do you want to archive the current project?',
          showCancelButton: true,
          confirmButtonColor: '#0069be',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          icon: 'warning'
        }).then((result) => {
          if (result.value) {
            if (this.selectedProject) {
              this.selectedProject.active = false;
              this.projectApiService.saveProject().subscribe(data => {
                this.loading = false;
                Swal.fire({
                  text: 'Project archived successfully',
                  confirmButtonColor: '#0069be',
                  confirmButtonText: 'Ok',
                  icon: 'success'
                }).then((result) => {
                  if (result.value)
                    location.reload();
                });
              });
            }
          }
          else {
            this.loading = false;
          }
        });
  }

  setArchiveButtonStatus() {
    this.isArchiveButtonDisabled = !this.selectedProject || this.userService.profile.displayName !== this.selectedProject?.createdBy || !this.userService.currentUserCanRunModels();
  }
}
