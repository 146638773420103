<div class="grid flex justify-content-end flex-wrap mr-2 mb-2">
    <app-model-checkbox class="mr-5" *ngIf="useModels" [compartment]="compartment" [selectedProject]="selectedProject"
        [isProjectOwnershipValid]="isProjectOwnershipValid"
        (onModelOptionsChangedEvent)="onModelOptionsChanged($event)">
    </app-model-checkbox>
    <app-action-buttons-panel [useAddAction]="useAddAction" [useSaveAction]="useSaveAction"  (onBtAddEvent)="add()"
        (onBtSaveEvent)="save()" (onBtExportEvent)="onBtExport()">
    </app-action-buttons-panel>
</div>
<ag-grid-angular #agGrid [domLayout]="domLayout" [id]="gridId" [style]="style" class="ag-theme-alpine"
    [rowData]="rowData" [pagination]="pagination" [paginationPageSize]="paginationPageSize"
    [suppressColumnVirtualisation]="true" [columnDefs]="columnDefs" [groupDefaultExpanded]="groupDefaultExpanded"
    [autoGroupColumnDef]="autoGroupColumnDef" [detailCellRendererParams]="detailCellRendererParams"
    [columnTypes]="columnTypes" [components]="frameworkComponents" [detailCellRenderer]="detailCellRenderer"
    [rowSelection]="rowSelection" [doesExternalFilterPass]="doesExternalFilterPass" [defaultColDef]="defaultColDef"
    [suppressRowClickSelection]="suppressRowClickSelection" [rowHeight]="rowHeight" [animateRows]="true"
    [context]="context" (gridReady)="onGridReady($event)" (cellValueChanged)="onCellValueChanged($event)"
    (cellClicked)="onCellClicked($event)" (cellEditingStarted)="onCellEditingStarted($event)"
    (cellEditingStopped)="onCellEditingStopped($event)" (firstDataRendered)="onFirstDataRendered($event)"
    (rowDataChanged)="onFirstDataRendered($event)" (bodyScroll)="onFirstDataRendered($event)"
    (selectionChanged)="onSelectionChanged($event)" (columnGroupOpened)="onFirstDataRendered($event)"
    [masterDetail]="isMasterDetail" [detailRowAutoHeight]="detailAutoRowHeigth" (modelUpdated)="modelUpdated($event)"
    (cellMouseOver)="onCellMouseOver($event)" (rowClicked)="onRowClicked($event)"
    [suppressCopyRowsToClipboard]="suppressCopyRowsToClipboard"
    [rowMultiSelectWithClick]="rowMultiSelectWithClick"
    [gridOptions]="gridOptions"
    (rowSelected)="onRowSelected($event)">
</ag-grid-angular>
