import { Injectable } from '@angular/core';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { Constants } from "src/app/shared/utils/constants";

@Injectable({
    providedIn: 'root',
})

export class HhraRunRiskAssessmentOpexResultsColDef {

    public getColumnsDefinition(isProjectOwnershipValid: boolean): (ColDef | ColGroupDef)[] | null {
        return [
            {
                headerName: 'Product name',
                field: 'activeIngredient',
                width: 200,
                filter: 'agSetColumnFilter',
                wrapText: false,
                resizable: true,
                sortable: true,
                editable: false,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Formulation type',
                field: 'formulationType',
                width: 200,
                filter: 'agSetColumnFilter',
                wrapText: false,
                resizable: true,
                sortable: true,
                editable: false,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'OPEX Formulation type',
                field: 'OPEXFormulationType',
                width: 270,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Active Ingredient',
                field: 'substancePropertiesDataSet.substanceName',
                width: 145,
                filter: 'agSetColumnFilter',
                pinned: "left",
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Endpoints Dataset',
                field: 'substancePropertiesDataSet.description',
                width: 145,
                filter: 'agSetColumnFilter',
                editable: false,
                pinned: "left",
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Application Scheme',
                field: 'humanHealthApplicationScheme.name',
                width: 155,
                filter: 'agSetColumnFilter',
                editable: false,
                pinned: "left",
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Crop type',
                field: 'humanHealthApplicationScheme.cropType',
                width: 150,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Activity',
                field: 'humanHealthApplicationScheme.activity',
                width: 250,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Indoor or Outdoor application',
                field: 'humanHealthApplicationScheme.indoorOutdoorApplication',
                width: 150,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Result',
                field: 'result',
                width: 100,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                pinned: "right",
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
                cellStyle: (params: any) => {
                    if (params.value.toLowerCase() === Constants.RUN_RISK_ASSESSMENT_RESULT_VALUE.Passed.toLocaleLowerCase()) {
                        return {color: 'green'};
                    }
                    else {
                      return {color: 'red'};
                    }
                  }
            },
            {
                headerName: 'Buffer strip (m)',
                field: 'humanHealthApplicationScheme.bufferStrip',
                width: 120,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Drift reduction (%)',
                field: 'humanHealthApplicationScheme.driftReduction',
                width: 130,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Number of \n applications',
                field: 'humanHealthApplicationScheme.numberOfApplications',
                width: 120,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Application \n interval (d)',
                field: 'humanHealthApplicationScheme.applicationInterval',
                width: 120,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Application rate \n (L or Kg a.s./ha)',
                field: 'applicationRate',
                filter: 'agSetColumnFilter',
                width: 129,
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                }
            },
            {
                headerName: 'PPE Scheme',
                field: 'personalProtectionEquipmentScheme.name',
                width: 190,
                filter: 'agSetColumnFilter',
                pinned: "left",
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Dermal absorption \n of product (%)',
                field: 'substancePropertiesDataSet.endpoints.DermalAbsorptionOfProduct',
                width: 145,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Dermal absorption of \n in-use dilution (%)',
                field: 'substancePropertiesDataSet.endpoints.DermalAbsorptionOfInUseDilution',
                width: 160,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'AOEL (mg/kg bw/day)',
                field: 'substancePropertiesDataSet.endpoints.AcceptableOperatorExposureLevel',
                width: 115,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'AAOEL (mg/kg bw/day)',
                field: 'substancePropertiesDataSet.endpoints.AcuteAcceptableOperatorExposureLevel',
                width: 125,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Dislodgeable Foliar Residue \n (µg/cm² of foliage/kg a.s. applied/ha)',
                field: 'substancePropertiesDataSet.endpoints.InitialDislodgeableFoliarResidue',
                width: 235,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Foliar \n DT50 (d)',
                field: 'substancePropertiesDataSet.endpoints.FoliarDT50',
                width: 95,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Oral \n absorption (%)',
                field: 'substancePropertiesDataSet.endpoints.OralAbsorptionOfActiveSubstance',
                width: 125,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Inhalation \n absorption (%)',
                field: 'substancePropertiesDataSet.endpoints.InhalationAbsorptionofActiveSubstance',
                width: 125,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Sub-population',
                field: 'subPopulation',
                width: 450,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Exposure type',
                field: 'exposureType',
                width: 250,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Exposure \n (mg/kg bw/d)',
                field: 'exposure',
                width: 125,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: '%(A)AOEL',
                field: 'aoel',
                width: 100,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Safety \n re-entry',
                field: 'safetyReEntry',
                width: 100,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Hazard Index \n for combined',
                field: 'hazardIndexOfCombined',
                width: 120,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            },
            {
                headerName: 'Safety re-entry \n (d) for combined',
                field: 'safetyReEntryForCombined',
                width: 135,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                wrapText: false,
                sortable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
            }
        ];
    }
}