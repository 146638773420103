import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout/layout.component';
import { EnvironmentalExposureAssessmentComponent } from './environmental-exposure-assessment/environmental-exposure-assessment/environmental-exposure-assessment.component';
import { ModelLibraryComponent } from './administrator/regulatory-models/model-library/model-library.component';
import { GeographyMatchingComponent } from './administrator/regulatory-models/geography-matching/geography-matching.component';
import { ApplicationMethodMatchingComponent } from './administrator/regulatory-models/application-method-matching/application-method-matching.component';
import { TriggersEcotoxComponent } from './administrator/regulatory-models/triggers-ecotox/triggers-ecotox.component';
import { TaxonEndpointsMatchingComponent } from './administrator/regulatory-models/taxon-endpoints-matching/taxon-endpoints-matching.component';
import { ExternalComponent } from './administrator/external/external.component';
import { HomeComponent } from './layout/home/home.component';
import { CropListComponent } from './administrator/regulatory-models/crop-list/crop-list.component';
import { InverseModelingEnvironmentalExposureAssesmentComponent } from './inverse-modeling-environmental-exposure-assesment/inverse-modeling-environmental-exposure-assesment/inverse-modeling-environmental-exposure-assesment.component';
import { CropListMatchingComponent } from './administrator/regulatory-models/crop-list-matching/crop-list-matching.component';
import { EnvironmentalRiskAssessmentComponent } from './environmental-risk-assessment/environmental-risk-assessment/environmental-risk-assessment.component';
import { HumanHealthRiskAssessmentComponent } from './human-health-risk-assessments/human-health-risk-assessment/human-health-risk-assessment.component';

const routes: Routes = [
  {
    path: '', component: LayoutComponent,
    children: [
      { path: 'environmentalExposureAssessment', component: EnvironmentalExposureAssessmentComponent },
      { path: 'inverseEnvironmentalExposureAssessment', component: InverseModelingEnvironmentalExposureAssesmentComponent },
      { path: 'ModelLibrary', component: ModelLibraryComponent },
      { path: 'GeographyMatching', component: GeographyMatchingComponent },
      { path: 'CropMatching', component: CropListMatchingComponent },
      { path: 'CropList', component: CropListComponent },
      { path: 'ApplicationMethodMatching', component: ApplicationMethodMatchingComponent },
      { path: 'TriggersEcotox', component: TriggersEcotoxComponent },
      { path: 'TaxonEndpointsMatching', component: TaxonEndpointsMatchingComponent },
      { path: 'environmentalRiskAssessment', component: EnvironmentalRiskAssessmentComponent },
      { path: 'humanHealthRiskAssessment', component: HumanHealthRiskAssessmentComponent },
      { path: 'ECHO/:ECHOModule', component: ExternalComponent },
      { path: 'ECHO/:ECHOModule/:Application', component: ExternalComponent },
      { path: 'GAP/:GAPModule', component: ExternalComponent },
      { path: 'GAP/:GAPModule/:Application', component: ExternalComponent },
      { path: 'Home', component: HomeComponent },
      { path: '**', component: HomeComponent }      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
