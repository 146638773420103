<app-selected-project></app-selected-project>
<div class="isProjectOwnershipValid">
    <p [hidden]="isProjectOwnershipValid">You cannot modify or run someone's project</p>
</div>
<div class="pt-1 pr-5 pb-5 pl-5">
    <app-wizard class="surface-200" [items]="inverseModelingEEALogicService.items" [message]="message"
        [loading]="loading" [activeItem]="activeItem" [selectedProject]="selectedProject" [isSetupValid]="isSetupValid"
        (activeIndexChangeEmitter)="onActiveIndexChange($event)"></app-wizard>

    <div class="mt-3 shadow-8 surface-0 p-3">
        <ng-container *ngIf="activeItem?.index == 0">
            <app-eea-setup [isInverseModeling]="true" [menuService]="inverseModelingEEALogicService" [modelsCollection]="modelsCollection" [disciplinePk]="disciplinePk" [columnDef]="columnDef"
                (isSetupValidEvent)="validateSetup($event)"></app-eea-setup>
        </ng-container>
        <ng-container *ngIf="activeItem?.index == 1">
            <app-eea-endpoints [selectedProject]="selectedProject" [isInverseModeling]="true"
                [menuService]="inverseModelingEEALogicService"></app-eea-endpoints>
        </ng-container>
        <ng-container *ngIf="activeItem?.index == 2">
            <app-eea-gap [selectedProject]="selectedProject" [isInverseModeling]="true"
                [menuService]="inverseModelingEEALogicService"></app-eea-gap>
        </ng-container>
        <ng-container *ngIf="activeItem?.index == 3">
            <app-inverse-modeling-endpoints [isProjectOwnershipValid]="isProjectOwnershipValid"
                [selectedProject]="selectedProject"
                [menuService]="inverseModelingEEALogicService"
                (isValid)="getIsValidInverseModelingEndpoints($event)"></app-inverse-modeling-endpoints>
        </ng-container>
        <ng-container *ngIf="activeItem?.index == 4">
            <app-eea-run-project [selectedProject]="selectedProject" [menuService]="inverseModelingEEALogicService" [environmentalAssessment]="environmentalAssessment"></app-eea-run-project>
        </ng-container>

        <ng-container *ngIf="activeItem?.index == 5">
            <app-era-run-risk-assessment [isInverseModule]="isInverseModule" [selectedProject]="selectedProject"
                [menuService]="inverseModelingEEALogicService"></app-era-run-risk-assessment>
        </ng-container>
    </div>
</div>
