import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Project } from 'src/app/shared/models/project';
import { DomLayoutType } from 'ag-grid-community';

@Component({
  selector: 'app-eea-output-project-grid',
  templateUrl: './eea-output-project-grid.component.html',
  styleUrls: ['./eea-output-project-grid.component.css']
})
export class EEAOutputProjectGridComponent implements OnInit, OnChanges {
  @Input() selectedProject?: Project;
  @Input()  rowData: any;
  @Input() loading?: boolean;
  @Input()  columnDefs: any;
  rowHeight: number = 45;
  public domLayout: DomLayoutType = 'autoHeight';
  context: any;
  rowSelection: 'single' | 'multiple' = 'single';
  columnTypes: any = {
    default: {
      editable: true,
      flex: 1,
      resizable: true,
      sortable: true,
      hide: false,
    },
    select: { resizable: true, sortable: true, hide: false },
  };
  gridApi: any;
  gridColumnApi: any;

  constructor( ) { }

  ngOnInit(): void {
    this.rowHeight = 40;
    this.context = { componentParent: this };
    this.rowSelection = 'single';
    this.columnTypes = {
      default: {
        editable: true,
        flex: 1,
        resizable: true,
        sortable: true,
        hide: false,
      },
      select: { resizable: true, sortable: true, hide: false },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridApi.enableSorting = true;
    this.gridColumnApi = params.columnApi;
    this.gridApi.hideOverlay();
  }
}