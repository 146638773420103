export class SimulationType{
  epaReservoir ?: boolean;
  epaPond ?: boolean;
  id?: number;
  name?: string;
  constructor(){
      this.epaReservoir = false;
      this.epaPond = false;
  }
}
