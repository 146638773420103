import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.css']
})
export class InputTextComponent implements OnChanges  {

  
  @Input() model: any;
  @Input() style: string = '';
  @Input() placeholder: string = "";
  @Input() type: string = "text";
  @Input() class: string = "";
  @Input() id: string = '';
  @Input() label: string = '';
  @Input() floating: boolean = false;
  @Input() useTitle: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() isValid: boolean = true;
  @Input() isValueDuplicated: boolean = false;
  @Input() isValueDuplicatedMessage: string = '';
  originalLabel: string = '';
  
  @Output() onChangeEvent= new EventEmitter<string>();

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['label']) this.originalLabel = this.label;
    if(changes['isRequired'])
    {
      if(this.label && this.isRequired) this.label = `${this.label}*`;
      this.originalLabel = this.label;
    }
    if(changes['isValueDuplicated']) {
      this.label = this.isValueDuplicated ? `${this.originalLabel} - ${this.isValueDuplicatedMessage}`: this.originalLabel;
    }
  }

  modelChanged(value: string){
    this.onChangeEvent.emit(value);
  }
  
}
