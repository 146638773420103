import { Injectable } from "@angular/core";
import { ColDef, ColGroupDef, EditableCallbackParams } from "ag-grid-community";
import { CalendarRenderer } from "src/app/shared/renderers/calendar/calendar.component";
import { DropdownListRenderer } from "src/app/shared/renderers/dropdown-list/dropdown-list.component";
import { InputNumberRenderer } from "src/app/shared/renderers/input-number/input-number.component";
import { InputTextRenderer } from "src/app/shared/renderers/input-text/input-text.component";
import { Constants } from "src/app/shared/utils/constants";
import { Utils } from "src/app/shared/utils/utils";



@Injectable({
    providedIn: 'root'
})
export class EEAGapPWCSurfaceWaterColdef {

    constructor() { }
    public static getPWCColumnsDefinition(options: any, isProjectOwnershipValid: boolean): any[] {
        return [


            {
                headerName: 'Application Scheme',
                field: 'name',
                type: 'default',
                resizable: true,
                pinned: "left",
                filter: 'agSetColumnFilter',
                cellRenderer: InputTextRenderer,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                    isRequired: true
                },
                editable: false,
                cellEditorParams: {
                    maxLength: 200,
                },
            },
            {
                headerName: 'Region',
                field: 'regionPk',
                type: 'select',
                filter: 'agSetColumnFilter',
                cellRenderer: 'dropdownlistRenderer',
                autoHeight: true,
                editable: false,
                pinned: "left",
                hide: false,
                cellRendererParams: {
                    options: options.geographiesList,
                    compartment: Constants.COMPARTMENTS.SURFACE_WATER,
                    optionValue: 'key',
                    optionLabel: 'name',
                    columnThatModifies: 'regionPk',
                    disabled: !isProjectOwnershipValid,
                },
                wrapText: true,
                resizable: true,
                sortable: true,
                width: 160
            },
            {
                headerName: 'Crop',
                field: 'cropPk',
                type: 'select',
                wrapText: true,
                pinned: "left",
                resizable: true,
                width: 160,
                filter: 'agSetColumnfilter',
                filterParams: {
                    cellRenderer: (pParams: any) => {
                        return Utils.formatFilterValues(pParams, options.cropList, 'CropListPk', 'CropName')
                    },
                },
                cellRenderer: DropdownListRenderer,
                cellRendererParams: {
                    options: options.cropList,
                    optionValue: 'CropListPk',
                    optionLabel: 'CropName',
                    disabled: !isProjectOwnershipValid,
                },
                editable: false
            },
            {
                headerName: 'Simulation Type',
                field: 'simulationType',
                type: 'select',
                width: 170,
                resizable: true,
                wrapText: true,
                filter: 'agSetColumnfilter',
                editable: false,
                cellRenderer: DropdownListRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        instance: this,
                        options: options.simulationTypes,
                        optionValue: 'id',
                        optionLabel: 'name',
                        columnThatModifies: 'simulationType',
                        disabled: !isProjectOwnershipValid,
                    };
                }
            },
            {
                headerName: 'Date Type',
                field: 'dateType',
                resizable: true,
                width: 140,
                wrapText: true,
                type: 'default',
                filter: 'agSetColumnFilter',
                editable: false,
                cellRenderer: DropdownListRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        options: Constants.DATE_TYPES,
                        columnThatModifies: 'dateType',
                        disabled: !isProjectOwnershipValid,
                    };
                },
            },
            {
                headerName: 'Crop Events',
                field: 'cropEvent',
                type: 'select',
                width: 140,
                resizable: true,
            hide: false,
                filter: 'agSetColumnfilter',
                editable: false,
                cellRenderer: DropdownListRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        options: options.cropEvents,
                        optionValue: 'id',
                        optionLabel: 'name',
                        columnThatModifies: 'cropEvent',
                        disabled: !isProjectOwnershipValid,
                    };
                }
            },
            {
                headerName: 'Specify Years',
                editable: false,
                field: 'specifyYear',
                type: 'selected',
                resizable: true,
                width: 90,
                wrapText: true,
                cellRenderer: 'checkBoxRenderer',
                cellRendererParams: (params: any) => {
                    return {
                        instance: this,
                        columnThatModifies: 'specifyYear',
                        disabled: !isProjectOwnershipValid,
                    };
                }
            },
            {
                headerName: 'Application',
                editable: isProjectOwnershipValid,
                cellRenderer: 'agGroupCellRenderer',
                resizable: true,
                type: 'default',
            },
            {
                headerName: 'Application Ocurr every Year(s)',
                field: 'application_occurrance',
                cellRenderer: InputNumberRenderer,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
                editable: false,
                resizable: true,
                width:145,
                valueFormatter: (params: any) => {
                    if (params.value == undefined) return 1

                    return params.value
                },
                wrapHeaderText: true,
                filter: 'agSetColumnFilter'
            },
            {
                headerName: 'Application Ocurr - from year',
                field: 'application_ocurrance_from_year',
                resizable: true,
                width:145,
                valueFormatter: (params: any) => {
                    if (params.value == undefined) return 1

                    return params.value
                },
                wrapHeaderText: true,
                cellRenderer: InputNumberRenderer,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
                editable: false,
                filter: 'agSetColumnFilter'
            },
            {
                headerName: 'Application Ocurr - to year',
                wrapHeaderText: true,
                width:145,
                field: 'application_ocurrance_to_year',
                resizable: true,
                valueFormatter: (params: any) => {
                    if (params.value == undefined) return 30

                    return params.value
                },
                cellRenderer: InputNumberRenderer,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
                editable: false,
                filter: 'agSetColumnFilter'
            },
            {
                headerName: 'Apply pesticide over a time window - Windows (days)',
                wrapHeaderText: true,
                width:190,
                field: 'apply_pesticide_over_a_time_windows',
                resizable: true, valueFormatter: (params: any) => {
                    if (params.value == undefined) return 1

                    return params.value
                },
                cellRenderer: InputNumberRenderer,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
                editable: false,
                filter: 'agSetColumnFilter'
            },
            {
                headerName: 'Apply pesticide over a time window - Steps (days)',
                width:180,
                valueFormatter: (params: any) => {
                    if (params.value == undefined) return 1

                    return params.value
                },
                wrapHeaderText: true,
                field: 'apply_pesticide_over_a_time_windows_steps',
                resizable: true,
                filter: 'agSetColumnFilter',
                cellRenderer: InputNumberRenderer,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
                editable: false,
            },
            {
                headerName: '',
                colId: 'delete',
                field: 'deleteButton',
                cellRenderer: 'deleteButtonRenderer',
                type: 'rightAligned',
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                }
            }

        ]
    }
    public static getPWCDetailsColumnsDefination(options: any, isProjectOwnershipValid: boolean): any[] {
      return [{
          headerName: '',
          children: [
              {

                  headerCheckboxSelection: false,
                  checkboxSelection: true,
                  showDisabledCheckboxes: true,
                  width: 45,
                  minWidth: 45,
                  pinned: "left",
              },
              {
                  headerName: 'Application Number',
                  field: 'application_number',
                  type: 'select',
                  minWidth: 120,
                  maxWidth: 120,
                  wrapText: true,
                  filter: 'agSetColumnfilter',
                  cellRenderer: 'dropdownlistRenderer',
                  cellRendererParams: (params: any) => {
                      return {
                          instance: this,
                          options: options.numbersOfApplication,
                          optionValue: 'value',
                          optionLabel: 'value',
                          columnThatModifies: 'application_number',
                          disabled: !isProjectOwnershipValid,
                      };
                  }
              },
          ]
      },

      {
          headerName: 'Rate (g/ha)',
          children: [
              {
                  headerName: 'Default Active Ingredient',
                  field: 'defaultAI',
                  cellRenderer: '',
                  minWidth: 360,
                  width: 360,
                  type: 'leftAligned',
                  editable: isProjectOwnershipValid,
              },
          ]
      },
      {
          headerName: '',
          children: [
              {
                  headerName: 'Application Date',
                  editable: isProjectOwnershipValid,
                  field: 'application_date',
                  minWidth: 160,
                  hide: false,
                  width: 160,
                  maxWidth: 140,
                  type: 'leftAligned',
                  filter: 'agSetColumnFilter',
                  cellRenderer: 'calendarRender',
                  cellRendererParams: (params: any) => {
                      return {
                          instance: this,
                          columnThatModifies: 'application_date',
                          highlightDate: new Date(2001, 0, 1),
                          editable: isProjectOwnershipValid,
                      };
                  }
              },
              {
                  headerName: "Days Since",
                  field: "days_since",
                  editable: isProjectOwnershipValid,
                  minWidth: 120,
                  wrapHeaderText: true,
                  maxWidth: 120,
                  hide: false,
                  type: 'leftAligned',
                  filter: "agSetColumnFilter"
              },
              {
                  headerName: 'Application Methods',
                  field: 'application_method',
                  type: 'select',
                  wrapHeaderText: true,
                  minWidth: 242,
                  maxWidth: 242,
                  filter: 'agSetColumnfilter',
                  editable: false,
                  cellRenderer: 'dropdownlistRenderer',
                  cellRendererParams: (params: any) => {
                      return {
                          instance: this,
                          options: options.applicationMethods,
                          optionValue: 'id',
                          optionLabel: 'name',
                          columnThatModifies: 'application_method',
                          disabled: !isProjectOwnershipValid,
                      };
                  }
              },
              {
                  headerName: "Depth (cm)",
                  field: "depth",
                  minWidth: 110,
                  wrapHeaderText: true,
                  maxWidth: 110,
                  editable: true,
                  hide: false,
                  type: 'leftAligned',
                  filter: "agSetColumnFilter",
              },
              {
                  headerName: "T-Band Split",
                  field: "t_band_split",
                  minWidth: 170,
                  editable: isProjectOwnershipValid,
                  hide: false,
                  width: 170,
                  type: 'leftAligned',
                  filter: "agSetColumnFilter"

              },
              {
                  headerName: "Application Effiency",
                  editable: true,
                  wrapHeaderText: true,
                  field: "efficiency",
                  minWidth: 170,
                  width: 170,
                  maxWidth: 150,
                  type: 'leftAligned',
                  filter: "agSetColumnFilter"

              },
              {
                  headerName: "Drift Fraction",
                  editable: isProjectOwnershipValid,
                  field: "drift",
                  minWidth: 170,
                  maxWidth: 150,
                  wrapHeaderText: true,
                  width: 170,
                  type: 'leftAligned',
                  filter: "agSetColumnFilter",
              }
          ]
      }

      ]
    /*public static getPWCDetailsColumnsDefination(options: any, isProjectOwnershipValid: boolean): any[] { //TODO The texboxComponet need to be check
        return [{
            headerName: '',
            children: [
                {

                    headerCheckboxSelection: false,
                    checkboxSelection: true,
                    showDisabledCheckboxes: true,
                    width: 45,
                    resizable: true, pinned: "left",
                },
                {
                    headerName: 'Application Number',
                    width:100,
                    field: 'application_number',
                    type: 'select',
                    resizable: true,
                    wrapText: true,
                    filter: 'agSetColumnfilter',
                    editable: false,
                    cellRenderer: DropdownListRenderer,
                    cellRendererParams: (params: any) => {
                        return {
                            options: options.numbersOfApplication,
                            optionValue: 'value',
                            optionLabel: 'value',
                            columnThatModifies: 'application_number',
                            disabled: !isProjectOwnershipValid,
                        };
                    }
                },
            ]
        },

        {
            headerName: 'Rate (g/ha)',
            children: [
                {
                    headerName: 'Default Active Ingredient',
                    field: 'defaultAI',
                    cellRenderer: '',
                    resizable: true,
                    width: 360,
                    type: 'leftAligned',
                    wrapText:true,
                    editable: isProjectOwnershipValid,
                },
            ]
        },
        {
            headerName: '',
            children: [
                {
                    headerName: 'Application Date ',
                    editable: false,
                    field: 'application_date',
                    wrapText:true,
                    resizable: true,
                    hide: false,
                    width:115,
                    type: 'leftAligned',
                    filter: 'agSetColumnFilter',
                    cellRenderer: CalendarRenderer,
                    cellRendererParams: (params: any) => {
                        return {
                            columnThatModifies: 'application_date',
                            highlightDate: new Date(2001, 0, 1),
                            editable: isProjectOwnershipValid,
                        };
                    }
                },
                {
                    headerName: "Days Since",
                    field: "days_since",
                    width:80,
                    resizable: true,
                    wrapHeaderText: true,
                    wrapText: true,
                    hide: false,
                    type: 'leftAligned',
                    cellRenderer: InputNumberRenderer,
                    cellRendererParams: {
                        disabled: !isProjectOwnershipValid,
                    },
                    editable: false,
                    filter: "agSetColumnFilter"
                },
                {
                    headerName: 'Application Methods',
                    field: 'application_method',
                    type: 'select',
                    width:180,
                    wrapHeaderText: true,
                    resizable: true,
                    filter: 'agSetColumnfilter',
                    editable: false,
                    cellRenderer: 'dropdownlistRenderer',
                    cellRendererParams: (params: any) => {
                        return {
                            instance: this,
                            options: options.applicationMethods,
                            optionValue: 'id',
                            optionLabel: 'name',
                            columnThatModifies: 'application_method',
                            disabled: !isProjectOwnershipValid,
                        };
                    }
                },
                {
                    headerName: "Depth (cm)",
                    field: "depth",
                    resizable: true,
                    width:90,
                    wrapHeaderText: true,
                    editable: false,
                    wrapText: true,
                    hide: false,
                    type: 'leftAligned',
                    filter: "agSetColumnFilter",
                    cellRenderer: InputNumberRenderer,
                    cellRendererParams: {
                        disabled: !isProjectOwnershipValid,
                    },
                },
                {
                    headerName: "T-Band Split",
                    field: "t_band_split",
                    resizable: true, wrapText: true,
                    wrapHeaderText: true,
                    editable: false,
                    hide: false,
                    width: 90,
                    type: 'leftAligned',
                    filter: "agSetColumnFilter",
                    cellRenderer: InputNumberRenderer,
                    cellRendererParams: {
                        disabled: !isProjectOwnershipValid,
                    },
                },
                {
                    headerName: "Application Effiency",
                    editable: false,
                    wrapHeaderText: true,
                    field: "efficiency",
                    wrapText: true,
                    resizable: true,
                    width: 120,
                    type: 'leftAligned',
                    filter: "agSetColumnFilter",
                    cellRenderer: InputNumberRenderer,
                    cellRendererParams: {
                        disabled: !isProjectOwnershipValid,
                    },

                },
                {
                    headerName: "Drift Fraction",
                    editable: false,
                    field: "drift",
                    resizable: true,
                    wrapText: true,
                    wrapHeaderText: true,
                    width: 110,
                    type: 'leftAligned',
                    filter: "agSetColumnFilter",
                    cellRenderer: InputNumberRenderer,
                    cellRendererParams: (params: any) => {
                        return {
                            instance: this,
                            columnThatModifies: 'drift',
                            disabled: !isProjectOwnershipValid,
                        };
                    },
                }
            ]
        }

        ]*/

    }
}
