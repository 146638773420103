import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { Project } from 'src/app/shared/models/project';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { HhraRunRiskAssessmentOpexResultsColDef } from './hhra-run-risk-assessment-opex-results-coldef';
import { OpexInputs } from 'src/app/shared/models/opex-Inputs';
import { RunRiskApiService } from 'src/app/shared/services/run-risk.api.service';
import { Subscription, switchMap, take, timer } from 'rxjs';
import { ProjectApiService } from 'src/app/shared/services/project.api.service';
import { Constants } from '../../../shared/utils/constants';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';


@Component({
  selector: 'app-hhra-run-risk-assessment-opex-result',
  templateUrl: './hhra-run-risk-assessment-opex-result.component.html',
  styleUrls: ['./hhra-run-risk-assessment-opex-result.component.css']
})
export class HhraRunRiskAssessmentOpexResultComponent implements OnInit {

  @Input() selectedProject?: Project;
  @Input() subTabMenuService!: TabMenuLogicService;
  @Input() isProjectOwnershipValid: boolean = false;
  @Input() opexInputsCombinations: OpexInputs[] = [];
  @Output() status: EventEmitter<any>;

  style: string = 'width: auto; height: 350px; text-align: left; z-index:-1;font-size:10px; color: #656565;';
  columnDefs: (ColDef | ColGroupDef)[] | null = [];
  rowData: any[] = [];
  isLoading!: boolean;
  subscription!: Subscription;
  projectStatusStyle = {
    statusId: this.selectedProject?.status,
    statusName: "",
    badgeStyle: ""
  }

  @ViewChild('grid') grid!: GridComponent;

  constructor(private readonly hhraRunRiskAssessmentOpexResultsColDef: HhraRunRiskAssessmentOpexResultsColDef,
    private readonly runRiskApiService: RunRiskApiService,
    private projectApiService: ProjectApiService,
    private selectedProjectApiService: SelectedProjectApiService) {
    this.status = new EventEmitter<boolean>();
  }

  ngOnInit() {
    this.rowData = [];
    this.isLoading = true;
    this.monitorProjectStatus();
  }

  getData() {
    this.columnDefs = this.hhraRunRiskAssessmentOpexResultsColDef.getColumnsDefinition(this.isProjectOwnershipValid);
    this.runRiskApiService.GetOPEXRunsByProject(this.selectedProject?.projectPk!).subscribe((data: OpexInputs[]) => {
      this.rowData = this.transformData(data);
      this.isLoading = false;
    });
  }

  monitorProjectStatus(): void {
    this.subscription = timer(0, 10000).pipe(
      switchMap(() => this.projectApiService.getProjectStatus(this.selectedProject!.projectPk).pipe(take(1)))
    ).subscribe({
      next: (status) => {
        this.selectedProject!.status = status;
        if (this.selectedProject!.status == Constants.PROJECT_STATUS_NUMERIC.COMPLETED) {
          this.selectedProjectApiService.updateSelectedProjectStatus(this.selectedProject!.status);
          this.statusStyle(status, Constants.PROJECT_STATUS.COMPLETE, Constants.BADGE_STYLE.COMPLETED);
          this.getData();
          this.subscription.unsubscribe();
        }
        if(this.selectedProject!.status == Constants.PROJECT_STATUS_NUMERIC.FAILED){
          this.statusStyle(status, Constants.PROJECT_STATUS.FAILED, Constants.BADGE_STYLE.FAILED);
          this.isLoading = false;
          this.subscription.unsubscribe();
        }
      },
      error: (error) => {
        console.warn(error)
      }
    });
  }

  statusStyle(statusId: number, status: string, bageStyle: string) {
    this.projectStatusStyle = {
      badgeStyle: bageStyle,
      statusId: statusId,
      statusName: status.concat("\u00A0")
    }
    this.status.emit(this.projectStatusStyle);
  }

  transformData(data: OpexInputs[]): OpexInputs[] {
    let object: any;
    let finalResult: any = [];

    data?.forEach(opexInputsObject => {
      opexInputsObject.substancePropertiesDataSet.forEach(element => {
        object = {
          OPEXFormulationType: opexInputsObject.OPEXFormulationType,
          activeIngredient: opexInputsObject.activeIngredient,
          applicationRate: opexInputsObject.humanHealthApplicationScheme.aeConcentration == undefined ? opexInputsObject.humanHealthApplicationScheme.aiConcentration : opexInputsObject.humanHealthApplicationScheme.aeConcentration,
          formulationType: opexInputsObject.formulationType,
          personalProtectionEquipmentScheme: opexInputsObject.personalProtectionEquipmentScheme,
          humanHealthApplicationScheme: opexInputsObject.humanHealthApplicationScheme,
          substancePropertiesDataSet: {
            calculatorGroup: element.calculatorGroup,
            dataSetPk: element.dataSetPk,
            description: element.description,
            formulationPk: element.formulationPk,
            metabolitePk: element.metabolitePk,
            moleculePk: element.moleculePk,
            projectPk: element.projectPk,
            source: element.source,
            substanceName: element.substanceName,
            endpoints: {
              ConcentrationOfActiveSubstance: element.endpoints.ConcentrationOfActiveSubstance,
              AcceptableOperatorExposureLevel: element.endpoints.AcceptableOperatorExposureLevel,
              AcuteAcceptableOperatorExposureLevel: element.endpoints.AcuteAcceptableOperatorExposureLevel,
              FoliarDT50: element.endpoints.FoliarDT50,
              InitialDislodgeableFoliarResidue: element.endpoints.InitialDislodgeableFoliarResidue,
              DermalAbsorptionOfInUseDilution: element.endpoints.DermalAbsorptionOfInUseDilution * 100,
              DermalAbsorptionOfProduct: element.endpoints.DermalAbsorptionOfProduct * 100,
              OralAbsorptionOfActiveSubstance: element.endpoints.OralAbsorptionOfActiveSubstance * 100,
              InhalationAbsorptionofActiveSubstance: element.endpoints.InhalationAbsorptionofActiveSubstance * 100,
              VapourPressureOfActiveSubstance: element.endpoints.VapourPressureOfActiveSubstance,
              MolecularWeightOfActiveSubstance: element.endpoints.MolecularWeightOfActiveSubstance,
            }
          },
          exposure: element.exposure,
          aoel: element.aoel,
          safetyReEntry: element.safetyReEntry,
          result: element.result ? element.result.substring(0, 1).toUpperCase() + element.result.substring(1, element.result.length).toLowerCase() : '',
          subPopulation: opexInputsObject.subPopulation,
          exposureType: opexInputsObject.exposureType,
          hazardIndexOfCombined: opexInputsObject.hazardIndexOfCombined,
          safetyReEntryForCombined: opexInputsObject.safetyReEntryForCombined,
        }
        finalResult.push(object);
      })
    });
    return finalResult;
  }
}