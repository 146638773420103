import { Component, DestroyRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { ERAPecEccAquaticColDef } from './era-pec-ecc-aquatic.coldef';
import { EraPecEccAquatic, EraPecEccAquaticDataTransaction, EraPecEccAquaticGeography } from 'src/app/environmental-risk-assessment/era-pec-ecc/era-pec-ecc-aquatic/era-pec-ecc-aquatic.model';
import { Project } from 'src/app/shared/models/project';
import { Constants } from 'src/app/shared/utils/constants';
import { ERAPecEccAquaticLogicService } from './era-pec-ecc-aquatic.logic.service';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { ERAConstants } from '../../environmental-risk-assessment.constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';

@Component({
  selector: 'app-era-pec-ecc-aquatic',
  templateUrl: './era-pec-ecc-aquatic.component.html',
  styleUrls: ['./era-pec-ecc-aquatic.component.css']
})
export class ERAPecEccAquaticComponent implements OnInit {

  @Input() selectedProject?: Project;
  @Input() menuService!: TabMenuLogicService;
  @Input() isProjectOwnershipValid: boolean = false;

  @ViewChild('grid') grid!: GridComponent;

  public rowData: EraPecEccAquatic[] = [];
  public columnDefs!: any;
  public isLoading: boolean = false;

  private geography: EraPecEccAquaticGeography = {} as EraPecEccAquaticGeography;
  private pksRowsToDelete: number[] = [];
  private gridData: any;
  private destroyRef = inject(DestroyRef);
  private saveRef?: string;

  constructor(private readonly columnDefsService: ERAPecEccAquaticColDef,
              private readonly logicService: ERAPecEccAquaticLogicService) { }

  public ngOnInit(): void {
    this.isLoading = true;
    this.initSubscribes();
    this.setGeography();

    const {geographyName, geographyPk, geograpyType} = this.geography;
    const { projectPk } = this.selectedProject!;

    this.logicService.getCompartments();
    this.logicService.GetCropsByGeography(geographyName);
    this.logicService.setWaterbodyOptions(geographyName);
    this.logicService.getCountries(geographyName, geographyPk);
    this.logicService.getModelsAndCompounds(geographyPk, geograpyType, projectPk).then(() => {
      this.getSavedData();
    });
    this.setColumnDef();
  }

  initSubscribes(){
    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.saveRef = this.menuService.saveRef;
      this.getRowData();
      this.save()
    });
  }

  private getSavedData(): void {
    this.logicService.getPecEccAquaticByProjectPk(this.selectedProject!.projectPk).then((data) =>{
      
      this.setData(data)
      this.isLoading = false;
    });
  }

  private setData(data: EraPecEccAquatic[]){
    data.forEach(x => x.modelName = this.logicService.modelsDropdown.find((y: any) => y.optionValue == x.modelPk)?.optionLabel as string);
    this.rowData = data.sort((a: EraPecEccAquatic, b: EraPecEccAquatic) => a.runPk! - b.runPk!);
  }

  private setColumnDef(): void{
    this.columnDefs = this.columnDefsService.getColumnsDefinition(this.geography.geographyName, this.isProjectOwnershipValid);
  }

  private setGeography(): void {
    const {EUROPE_UNION, USA} = Constants.GEOGRAPHYS;
    const {COUNTRY, REGION} = ERAConstants.GEOGRAPHY_TYPE;
    const {countryPk, regionPk} = this.selectedProject!;

    this.geography.geographyName = countryPk ? USA : EUROPE_UNION;
    this.geography.geograpyType = countryPk ? COUNTRY : REGION;
    this.geography.geographyPk = countryPk ?? regionPk!;
  }

  public onDropdownChanged(event: any): void {
    const { field, value, params } = event;

    switch(field){
      case 'activeIngredientPk':
        this.grid.gridApi.setColumnDefs(this.columnDefs);
        break;
      case 'modelPk':
        event.row.scenarioFocus = null;
        this.resetValues(event);
        this.addDefaultScenarioValue(event);
        this.grid.gridApi.setColumnDefs(this.columnDefs);
        break;
      case 'scenarioFocus':
        this.resetValues(event);
        this.grid.gridApi.setColumnDefs(this.columnDefs);
        break;
    }
    this.grid.gridApi.redrawRows();
  }

  resetValues(event: any){
    event.row.scenarios = null;
    event.row.waterbody = null;
    event.row.bufferDistance = null;
    event.row.nozzleDriftReduction = null;
    event.row.region = null;
    event.row.period = null;
    event.row.bbchEarliest = null;
  }

  addDefaultScenarioValue(event: any){
    const modelName = this.logicService.modelsDropdown.find((x: any) => x.optionValue == event.value)?.optionLabel;
    switch(modelName){
      case "FOCUS Step 3 (Swash)":
        event.row.scenarioFocus = 'Step 3';
        break;
      case "FOCUS Step 4 (Swan)":
        event.row.scenarioFocus = 'Step 4';
        break;
    }
  }

  private save(): void {
    const { geograpyType } = this.geography;
    const pecEccAquatics = this.logicService.transformGridDataToAquaticModel(this.gridData, geograpyType,this.selectedProject!.projectPk);
    const dataTransaction: EraPecEccAquaticDataTransaction = {pecEccAquatics: pecEccAquatics, rowsToDelete: this.pksRowsToDelete};
    if(pecEccAquatics.length == 0 && this.pksRowsToDelete.length == 0) {
      this.menuService.setSuccess(true);
      return;
    }

    this.logicService.save(dataTransaction).then(() => {
      this.saveRef === Constants.WIZARD_MENU_REF.Master ? this.menuService.setSuccess(true) : this.menuService.setSuccess(true);
    });

  }

  private getRowData() {
    const gridData: any[] = [];
    if(this.grid){
      this.grid.gridApi.forEachNode(function (node: any) {
        gridData.push(node.data);
      });
    }
    this.gridData = gridData;
  }

  public onDelete(event: EraPecEccAquatic): void {
    const { resultSurfaceWaterPk } = event;
    this.setRowsToDelete(resultSurfaceWaterPk);
  }

  private setRowsToDelete(resultSurfaceWaterPk?: number): void{
    if(resultSurfaceWaterPk) {
      this.pksRowsToDelete.push(resultSurfaceWaterPk);
    }
  }
}
