<p-tabView [(activeIndex)]="activeIndex">
  <p-tabPanel class="mb-2" [disabled]="disabledInputs" header="Inputs used in calculation">
    <app-eea-output-project-step1n2-run-inputs [selectedModel]="selectedModel" *ngIf="!disabledInputs"
      [selectedProject]="selectedProject"></app-eea-output-project-step1n2-run-inputs>
  </p-tabPanel>
  <p-tabPanel class="mb-2" [disabled]="disabledInputs" header="Scenario Data Used in the Calculation">
    <app-eea-output-project-step1n2-scenario *ngIf="!disabledInputs"
      [selectedProject]="selectedProject"></app-eea-output-project-step1n2-scenario>
  </p-tabPanel>

  <p-tabPanel class="mb-2" [disabled]="disabledSummary" header="Results of the Calculation">
    <app-eea-output-project-step1n2-results-calculation *ngIf="!disabledSummary"
      [selectedProject]="selectedProject"></app-eea-output-project-step1n2-results-calculation>
  </p-tabPanel>

  <p-tabPanel class="mb-2" [disabled]="disabledSummary" header="Results Summary">
    <app-eea-output-project-step1n2-result-summary [selectedModel]="selectedModel" *ngIf="!disabledSummary"
      [selectedProject]="selectedProject"></app-eea-output-project-step1n2-result-summary>
  </p-tabPanel>
</p-tabView>