import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.css']
})
export class InputSearchRenderer implements ICellRendererAngularComp {

  public params: any;
  public value: any;
  public listItems: Array<any> = [];
  public field: any;
  public key: string = "";
  public selectedValue: any;
  public columnThatModifies: string = "";
  public parent: any;
  forceSelection: boolean = false;
  filteredvalues: string[] = [];
  disabled: boolean = false;

  constructor() { }

  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
    this.forceSelection = params.forceSelection == this.forceSelection;
    this.key = params.optionValue;
    this.listItems = params.options?.map((x: { [x: string]: any; }) => x[this.key]);
    this.parent = params.context;
    this.field = params.colDef.field;
    this.columnThatModifies = params.columnThatModifies;
    this.disabled = params.disabled;
  }

  public onSelect() {
    this.selectedValue = this.value;
    this.searchChanged();
  }

  public onBlur() {
    setTimeout(() => {
      if (this.value != this.selectedValue)
        this.searchChanged()
    }, 500);
  }

  public searchChanged() {
    this.params.data[this.field] = this.value;
    this.params.data.haveChanges = true;
    this.onChange();
  }

  public onFilter(event: any) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.listItems.length; i++) {
      let item = this.listItems[i];
      if (item.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(item);
      }
    }
    this.filteredvalues = filtered;
  }

  onChange() {
    this.parent.dropDownSelectionChanged({
      field: this.columnThatModifies,
      id: this.params.node.id,
      row: this.params.data,
      sourceValues: this.listItems,
      value: this.value
    });
  }
}
