import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AccordionModule } from 'primeng/accordion';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { MenubarModule } from 'primeng/menubar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SplitterModule } from 'primeng/splitter';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { BlockUIModule } from 'primeng/blockui';
import { MessagesModule } from 'primeng/messages';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FieldsetModule } from 'primeng/fieldset';
import { KeyFilterModule } from 'primeng/keyfilter';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { MultiSelectModule } from 'primeng/multiselect';



@NgModule({
  declarations: [],
  imports: [
    AccordionModule,
    AvatarModule,
    BadgeModule,
    ButtonModule,
    CommonModule,
    DividerModule,
    DropdownModule,
    InputNumberModule,
    InputTextareaModule,
    InputTextModule,
    ListboxModule,
    MenubarModule,
    PanelMenuModule,
    SplitterModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    InputTextareaModule,
    ListboxModule,
    BlockUIModule,
    MessagesModule,
    AutoCompleteModule,
    KeyFilterModule,
    CheckboxModule,
    TabViewModule,
    DialogModule,
    CalendarModule,
    CardModule,
    ScrollPanelModule,
    MultiSelectModule
  ],
  exports: [
    AccordionModule,
    AvatarModule,
    BadgeModule,
    ButtonModule,
    DividerModule,
    DropdownModule,
    InputNumberModule,
    InputTextareaModule,
    InputTextModule,
    ListboxModule,
    MenubarModule,
    PanelMenuModule,
    SplitterModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    InputTextareaModule,
    ListboxModule,
    BlockUIModule,
    MessagesModule,
    AutoCompleteModule,
    CheckboxModule,
    TabViewModule,
    RadioButtonModule,
    FieldsetModule,
    KeyFilterModule,
    DialogModule,
    CalendarModule,
    CardModule,
    ScrollPanelModule,
    MultiSelectModule
  ]
})
export class PrimengModule { }
