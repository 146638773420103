import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { forkJoin } from 'rxjs';
import { DisciplineService } from 'src/app/administrator/filters/discipline/discipline.service';
import { DataSet } from 'src/app/shared/models/echo/data-set';
import { Discipline } from 'src/app/shared/models/echo/discipline';
import { Endpoint } from 'src/app/shared/models/endpoint';
import { Project } from 'src/app/shared/models/project';
import { DataSetApiService } from 'src/app/shared/services/echo/data-set.api.service';
import { EfateApiService } from 'src/app/shared/services/echo/efate.api.service';
import { UsersLogicService } from 'src/app/shared/services/users.logic.service';
import { Constants } from 'src/app/shared/utils/constants';
import { Utils } from 'src/app/shared/utils/utils';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';

@Component({
  selector: 'app-eea-endpoints-core',
  templateUrl: './eea-endpoints-core.component.html',
  styleUrls: ['./eea-endpoints-core.component.css']
})

export class EEAEndpointsCoreComponent implements OnInit {
  @Input() selectedProject?: Project;
  @Input() menuService!: TabMenuLogicService;
  @Input() subTabMenuService!: TabMenuLogicService;
  @Input() isProjectOwnershipValid: boolean = false;

  afterSearch: boolean = false;
  isLoading: boolean = false;
  rowData: any[] = [];
  originalRowData: any;
  headerLabels: any;
  colDef: any;
  dataToSave: any;
  disciplines: Discipline[] = [];
  dataset: DataSet[] = [];
  mainDiscipline = Constants.DISCIPLINE_IDENTITIES.ENVIRONMENTAL_E_FATE;
  transactionType: number = 1;
  endpointsList: Endpoint[] = [];
  editedRows: any[] = [];
  updatedValues: any[] = [];
  saveRef?: string;
  regexExponentialNumbers = Constants.REGEX_EXPONENTIAL_NUMBERS;


  destroyRef = inject(DestroyRef);

  constructor(private dataSetService: DataSetApiService,
    private disciplineService: DisciplineService,
    private efateService: EfateApiService,
    private usersService: UsersLogicService) { }

  ngOnInit(): void {
    this.initSubscribes();
    this.setLoadingState(true);
    this.getData();
    this.headerLabels = Constants.CORE_ENDPOINTS_TABLE_LABELS;
  }

  getData() {
    if (!this.selectedProject) return;
    this.dataSetService.saveCoreDataSet({ project: this.selectedProject, projectXCompoundXModels: this.selectedProject.projectXCompoundXModel }).subscribe({
      next: () => {
        this.disciplineService.getDisciplineInformation().subscribe((discipline: any) => {
          if (!this.selectedProject) return;
          this.disciplines = discipline;
          this.dataSetService.GetCoreDatasetsByProject(this.selectedProject.projectPk).subscribe((dataSets: DataSet[]) => {
            this.dataSetService.getCoreEndpointsByDataSets(dataSets).subscribe((data: any) => {
              this.dataset = data;
              this.setEndpointsOrder();
              this.dataset?.forEach((x: any) => {
                this.endpointsList.push(...x.endpoints)
              });
              this.filterAllRowData(this.endpointsList);
            });
          });
        });
      },
      error: (err) => {
        console.warn(err);
      }
    })
  };

  setLoadingState(pState: boolean): void {
    this.isLoading = pState;
    this.afterSearch = !pState;
  }

  filterAllRowData(pData: any): void {
    this.rowData = pData;
    this.filterRowDataByEndpointTypePreselectedValue();
  }

  filterRowDataByEndpointTypePreselectedValue(): void {
    this.rowData =
      this.rowData.filter(
        (x: any) =>
          this.rowData.filter((x: any) => x.disciplinePk === this.disciplines.filter(x => x.name == this.mainDiscipline).map(x => x.disciplinePk)[0])
      );
    this.rowData = this.processGroupedEndpoints();
    this.rowData = this.setCoreValues();
    this.setGroupDataEndpoints();
    this.originalRowData = JSON.parse(JSON.stringify(this.rowData));
    this.setLoadingState(false);
  }

  processGroupedEndpoints() {
    this.rowData.forEach((row: any) => {
      if (row.groupedEndpointFieldValues.length > 0)
        Constants.PEC_SURFACEWATER_ENDPOINTS.forEach((endpoint) => {
          if (row.groupedEndpointFieldValues.find((x: any) => x.key.toLowerCase() == endpoint.name))
            row[endpoint.field] = row.groupedEndpointFieldValues.filter(
              (x: any) => x.key.toLowerCase() == endpoint.name
            )[0].value;
        });
    });

    return this.rowData;
  }

  setGroupDataEndpoints() {
    this.rowData.forEach((row: any) => {
      const index = this.dataset.findIndex(x => x.dataSetPk === row.dataSetPk);
      if (index % 2 === 0)
        row.style = 0;
    });
  }

  setEndpointsOrder() {
    this.dataset.sort((a: any, b: any) => a.endpoints?.filter((row: any) => row.substanceType === Constants.SUBSTANCE_TYPES.ACTIVE)[0]?.substanceName
      .localeCompare(b.endpoints?.filter((row: any) => row.substanceType === Constants.SUBSTANCE_TYPES.ACTIVE)[0]?.substanceName));

    this.dataset.forEach((x: any) => {
      x.endpoints.sort((a: any, b: any) => a.substanceName.localeCompare(b.substanceName)).sort((a: any, b: any) => a.substanceType === Constants.SUBSTANCE_TYPES.ACTIVE ? -1 : 1);
    })
  }

  setCoreValues(): any[] {
    this.rowData.forEach((row: any) => {
      Constants.CORE_ENDPOINTS_TABLE_FIELDS.forEach((field: string) => {
        const hasField = field in row;
        if (!hasField)
          row[field] = undefined;
      })
    });

    return this.rowData;
  }

  setExponentialNumber(pValue: number) {
    if (pValue === undefined || pValue == null) return undefined;
    return Utils.convertNumberToExponential(pValue);
  }

  getKomValue(pKfoc: number) {
    if (pKfoc === undefined || pKfoc == null) return undefined;
    return Utils.formatToExponential(Utils.getKomValue(pKfoc));
  }

  initSubscribes() {
    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.saveRef = this.menuService.saveRef;
      this.prepareDataToSave();
    });
    this.subTabMenuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.saveRef = this.subTabMenuService.saveRef;
      this.prepareDataToSave();
    });
  }

  prepareDataToSave() {
    if (this.rowData.length <= 0) {
      this.menuService.setSuccess(true);
      this.subTabMenuService.setSuccess(true);
      return;
    }

    this.getEditedRow();
    this.setGroupedEndpointFieldValues();

    this.dataToSave = this.editedRows.map((data: any) => {
      return {
        transactionType: 1,
        row: data
      }
    });

    this.save();
  }

  getEditedRow() {
    this.rowData.forEach((row: any) => {
      let rowToCompare = this.originalRowData.filter((x: any) => x.endpointPk === row.endpointPk)[0];

      if (JSON.stringify(row) !== JSON.stringify(rowToCompare)) {
        this.editedRows.push(row);

        Constants.PEC_SURFACEWATER_ENDPOINTS.forEach((value: any) => {
          if (row[value.field] !== rowToCompare[value.field]) {
            this.updatedValues.push({
              projectPk: this.selectedProject?.projectPk,
              moleculePk: row.moleculePk,
              metabolitePk: row.metabolitePk,
              field: value.name,
              value: row[value.field]
            });
          }
        });
      } else if (this.IsNewCore())
        this.editedRows.push(row);
    });
  }

  private IsNewCore(): boolean {
    const { molecularWeight, solubilityInWater, saturatedVapourPressure, kfoc, kom, exponent, halfLifeInSoil, measuredAtSoil } = this.rowData[0];

    if (molecularWeight && !solubilityInWater && !saturatedVapourPressure && !kfoc && !kom && !exponent && !halfLifeInSoil && !measuredAtSoil)
      return true;
    else
      return false;
  }

  save() {
    this.setDataToSave();
    this.saveData(this.dataToSave);
  }

  saveData(pData: any) {
    if (!this.selectedProject) return;
    forkJoin([
      this.efateService.saveData(pData, Constants.THIS_APPLICATION),
      this.efateService.updateCoreParameters(this.updatedValues, this.selectedProject.projectPk)
    ]).subscribe({
      next: ([_afterSave, _afterUpdate]) => {
        this.saveRef === Constants.WIZARD_MENU_REF.Master ? this.menuService.setSuccess(true) : this.subTabMenuService.setSuccess(true);
      },
      error: (err) => {
        this.saveRef === Constants.WIZARD_MENU_REF.Master ? this.menuService.setSuccess(false) : this.subTabMenuService.setSuccess(false);
        console.warn(err);
      }
    });
  }

  setDataToSave(): void {
    this.addAuthor();
    this.addLastModifiedDate();
  }

  addAuthor(): void {
    this.dataToSave.forEach((transaction: any) => {
      if (transaction['row'].status === Constants.CONST_APPROVED) {
        transaction['row'].reviewedBy = this.usersService.getProfile();
      } else {
        transaction['row'].modifiedBy = this.usersService.getActiveUserID();
      }
      transaction['row'].dataSource = Constants.THIS_APPLICATION_ENDPOINT_DATA_SOURCE;
    });
  }

  addLastModifiedDate(): void {
    this.dataToSave.forEach((transaction: any) => {
      transaction['row'].lastModifiedDate = Utils.getDateFormatToSaveData();
    });
  }

  setGroupedEndpointFieldValues() {
    Constants.CORE_ENDPOINTS_TABLE_FIELDS.forEach((field: any) => {
      this.rowData.forEach((row: any) => {
        if (row.groupedEndpointFieldValues.length === 0) {
          row.groupedEndpointFieldValues = [];
          row.groupedEndpointFieldValues.push({
            endpointPK: -1,
            key: Constants.PEC_SURFACEWATER_ENDPOINTS.filter((x) => x.field === field)[0]?.name,
            value: row[field]
          });
        }
        else {
          if (row.groupedEndpointFieldValues.find((x: any) =>
            x.key.toLowerCase() == Constants.PEC_SURFACEWATER_ENDPOINTS.filter((x: any) => x.field === field)[0]?.name.toLocaleLowerCase())) {
            row.groupedEndpointFieldValues.filter((x: any) =>
              x.key.toLowerCase() === Constants.PEC_SURFACEWATER_ENDPOINTS.filter((x) => x.field === field)[0]?.name.toLowerCase())[0].value = row[field];
          }
          else {
            row.groupedEndpointFieldValues.push({
              endpointPK: -1,
              key: Constants.PEC_SURFACEWATER_ENDPOINTS.filter((x) => x.field === field)[0]?.name,
              value: row[field]
            });
          }
        }
      });
    })
  }


  onBlur(columnName: string, row: any): void{
    this.rowData.find(r => r.endpointPk == row?.endpointPk)[columnName] = this.regexExponentialNumbers.test(row[columnName]) ? Utils.convertNumberToExponential(row[columnName]) : undefined;
  }

  validateRegExp(pValue: string) {
    if(!this.regexExponentialNumbers.test(pValue)) return undefined;
    return Utils.convertNumberToExponential(pValue);
  }

}
