import { Injectable } from '@angular/core';
import { HttpApiService } from './http.api.service';
import { environment } from 'src/environments/environment';
import { RunRisk } from '../models/run-risk';
import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs';
import { OpexInputs } from '../models/opex-Inputs';

@Injectable({
  providedIn: 'root'
})
export class RunRiskApiService extends HttpApiService {
  override apiUrl: string = environment.BAMS_Api_URL;

  GetAquaticRiskRunsByProject(data: RunRisk[], projectPk: number, geography: string){
    const body = JSON.stringify(data);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `api/aquaticRiskRunsByProject/${projectPk}/${geography}`;
    return this.put(query, body, headers)
      .pipe(map((data: any) => data));
  }

  RunOpex(data: OpexInputs[], projectPk: number, isRefresh: boolean){
    const body = JSON.stringify(data);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `api/runOpex/${projectPk}/${isRefresh}`;
    return this.put(query, body, headers)
      .pipe(map((data: any) => data.Value));
  }

  GetOPEXRunsByProject(projectPk: number){
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `api/GetOPEXByProject/${projectPk}`;
    return this.get(query, headers)
      .pipe(map((data: any) => data));
  }
}