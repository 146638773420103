import { Injectable } from '@angular/core';
import { Project } from '../models/project';
import { FormulationApiService } from './echo/formulation.api.service';
import { DataSetApiService } from './echo/data-set.api.service';
import { DataSet } from '../models/echo/data-set';
import { HumanHealthApplicationScheme, SubstancePropertiesDataSet, OpexInputs, PersonalProtectionEquipmentScheme } from '../models/opex-Inputs';
import { Constants } from '../utils/constants'
import { firstValueFrom } from 'rxjs';
import { PersonalProtectionEquipmentSchemeApiService } from 'src/app/human-health-risk-assessments/personal-protection-equipment-scheme/personal-protection-equipment-scheme.api.service';
import { HhraGapApiService } from 'src/app/human-health-risk-assessments/hhra-gap/hhra-gap-non-dietary/hhra-gap-non-dietary.api.service';
import { EndpointDatasetService } from '../components/endpoint-dataset/endpoint-dataset.service';

@Injectable({
  providedIn: 'root'
})
export class HumanHealthOpexLogicService {

  constructor(private readonly dataSetApiService: DataSetApiService,
    private readonly personalProtectionEquipmentSchemeApiService: PersonalProtectionEquipmentSchemeApiService,
    private readonly HhraGapApiService: HhraGapApiService,
    private readonly formulationApiService: FormulationApiService,
    private readonly endpointDatasetService: EndpointDatasetService
  ) { }

  async fillOpexInputs(selectedProject: Project): Promise<OpexInputs[]> {
    let opexInputsList: OpexInputs[] = [];
    let substancePropertiesData: SubstancePropertiesDataSet[] = [];
    let substancePropertiesNonGroupData: SubstancePropertiesDataSet[] = [];
    let group: boolean = false;
    let counter = 0;
    let opexInputs: OpexInputs;

    const substancePropertiesDataSetList = await this.getSubstanceProperties(selectedProject);
    const humanHealthApplicationSchemes = await this.getHumanHealthApplicationScheme(selectedProject);
    const personalProtectionEquipmentScheme = await this.getPersonalProtectionEquipmentScheme(selectedProject);

    for (let index = 0; index < Constants.CALCULATOR_GROUPS.length + 1; index++) {

      if (Constants.CALCULATOR_GROUPS[index]) {
        substancePropertiesData = substancePropertiesDataSetList.filter(substanceProperties => substanceProperties.calculatorGroup === Constants.CALCULATOR_GROUPS[index].name);
        counter = substancePropertiesData.length > 0 ? substancePropertiesData.length - 1 : 0;
      } else {
        group = true;
        substancePropertiesData = substancePropertiesDataSetList.filter(substanceProperties => substanceProperties.calculatorGroup === null);
        counter = 0;
      }
      for (let i = counter; i < substancePropertiesData.length; i++) {
        humanHealthApplicationSchemes.forEach((humanHealthApplicationScheme: HumanHealthApplicationScheme) => {
          substancePropertiesData = this.transformSubstanceProperty(substancePropertiesData, humanHealthApplicationScheme);
          personalProtectionEquipmentScheme.forEach((personalProtectionEquipmentScheme: PersonalProtectionEquipmentScheme) => {

            if (group)
              substancePropertiesNonGroupData.push(substancePropertiesData[i]);

            opexInputs = {
              projectFullName: selectedProject!.name,
              formulationType: selectedProject!.formulationType!,
              OPEXFormulationType: selectedProject!.OPEXFormulationType!,
              activeIngredient: selectedProject!.formulation!,
              personalProtectionEquipmentScheme: personalProtectionEquipmentScheme,
              humanHealthApplicationScheme: humanHealthApplicationScheme,
              substancePropertiesDataSet: group ? substancePropertiesNonGroupData : substancePropertiesData,
              subPopulation: "",
              exposureType: "",
              applicationRate: humanHealthApplicationScheme.aiConcentration == undefined ? humanHealthApplicationScheme.aeConcentration : humanHealthApplicationScheme.aiConcentration
            }
            opexInputsList.push(opexInputs);
          });
        });
      }
    }
    return opexInputsList;
  }

  transformSubstanceProperty(substancePropertiesData: SubstancePropertiesDataSet[], humanHealthApplicationScheme: HumanHealthApplicationScheme): SubstancePropertiesDataSet[] {
    let substanceProperties: SubstancePropertiesDataSet[] = [];
    substancePropertiesData.forEach(substanceProperty => {
      let substancePropertiesObject: SubstancePropertiesDataSet = {
        dataSetPk: substanceProperty.dataSetPk,
        substanceName: substanceProperty.substanceName,
        moleculePk: substanceProperty.moleculePk,
        metabolitePk: substanceProperty.metabolitePk,
        formulationPk: substanceProperty.formulationPk,
        description: substanceProperty.description,
        source: substanceProperty.source,
        calculatorGroup: substanceProperty.calculatorGroup,
        projectPk: substanceProperty.projectPk,
        endpoints: {
          ConcentrationOfActiveSubstance: humanHealthApplicationScheme.applicationSchemeXActiveIngredientRate.find(x => x.moleculePk === substanceProperty.moleculePk)?.rate!,
          AcceptableOperatorExposureLevel: substanceProperty.endpoints.AcceptableOperatorExposureLevel,
          AcuteAcceptableOperatorExposureLevel: substanceProperty.endpoints.AcuteAcceptableOperatorExposureLevel,
          FoliarDT50: substanceProperty.endpoints.FoliarDT50,
          InitialDislodgeableFoliarResidue: substanceProperty.endpoints.InitialDislodgeableFoliarResidue,
          DermalAbsorptionOfInUseDilution: substanceProperty.endpoints.DermalAbsorptionOfInUseDilution,
          DermalAbsorptionOfProduct: substanceProperty.endpoints.DermalAbsorptionOfProduct,
          OralAbsorptionOfActiveSubstance: substanceProperty.endpoints.OralAbsorptionOfActiveSubstance,
          InhalationAbsorptionofActiveSubstance: substanceProperty.endpoints.InhalationAbsorptionofActiveSubstance,
          VapourPressureOfActiveSubstance: substanceProperty.endpoints.VapourPressureOfActiveSubstance,
          MolecularWeightOfActiveSubstance: substanceProperty.endpoints.MolecularWeightOfActiveSubstance
        },
        exposure: substanceProperty.exposure,
        aoel: substanceProperty.aoel,
        result: substanceProperty.result,
        safetyReEntry: substanceProperty.safetyReEntry,
      };
      substanceProperties.push(substancePropertiesObject);
    });
    return substanceProperties;
  }

  async getSubstanceProperties(selectedProject: Project): Promise<SubstancePropertiesDataSet[]> {
    let substanceProperties: SubstancePropertiesDataSet[] = [];
    let substancePropertiesObject: SubstancePropertiesDataSet;

    const endpoint = await firstValueFrom(this.endpointDatasetService.getDataSetsByProject(selectedProject.projectPk, Constants.ENVIRONMENTAL_ASSESSMENTS.HUMAN_HEALTH_RISK_ASSESSMENT));
    let concentration: number = 0;
    const endpointByDataSet = await firstValueFrom(this.dataSetApiService.getCoreEndpointsByDataSets(endpoint));
    const formulation = await firstValueFrom(this.formulationApiService.getFormulationConcentration(selectedProject?.formulationPk!));

    endpoint.forEach((dataset: DataSet) => {
      if (dataset.useInProject) {
        substancePropertiesObject = {
          dataSetPk: dataset.dataSetPk!,
          substanceName: formulation.find(molecule => molecule.moleculePk === dataset.moleculePk)?.molecule!,
          moleculePk: dataset.moleculePk!,
          metabolitePk: dataset.metabolitePk!,
          formulationPk: dataset.metabolitePk!,
          description: dataset.description!,
          source: dataset.source!,
          calculatorGroup: dataset.calculatorGroup!,
          projectPk: dataset.projectPk!,
          endpoints: {
            ConcentrationOfActiveSubstance: concentration,
            AcceptableOperatorExposureLevel: endpointByDataSet.find(endpointByDataSet => endpointByDataSet.dataSetPk === dataset.dataSetPk).endpoints.find((endpoint: { substanceType: string; }) => endpoint.substanceType === Constants.SUBSTANCE_TYPES.ACTIVE)?.groupedEndpointFieldValues.find((groupedEndpoint: { key: string; }) => groupedEndpoint.key === Constants.DATA_VALUES_NAMES.acceptableOperatorExposureLevel)?.textValue,
            AcuteAcceptableOperatorExposureLevel: endpointByDataSet.find(endpointByDataSet => endpointByDataSet.dataSetPk === dataset.dataSetPk).endpoints.find((endpoint: { substanceType: string; }) => endpoint.substanceType === Constants.SUBSTANCE_TYPES.ACTIVE)?.groupedEndpointFieldValues.find((groupedEndpoint: { key: string; }) => groupedEndpoint.key === Constants.DATA_VALUES_NAMES.acuteAcceptableOperatorExposureLevel)?.textValue,
            FoliarDT50: endpointByDataSet.find(endpointByDataSet => endpointByDataSet.dataSetPk === dataset.dataSetPk).endpoints.find((endpoint: { substanceType: string; }) => endpoint.substanceType === Constants.SUBSTANCE_TYPES.ACTIVE)?.groupedEndpointFieldValues.find((groupedEndpoint: { key: string; }) => groupedEndpoint.key === Constants.DATA_VALUES_NAMES.foliarHalfLife)?.textValue,
            InitialDislodgeableFoliarResidue: endpointByDataSet.find(endpointByDataSet => endpointByDataSet.dataSetPk === dataset.dataSetPk).endpoints.find((endpoint: { substanceType: string; }) => endpoint.substanceType === Constants.SUBSTANCE_TYPES.ACTIVE)?.groupedEndpointFieldValues.find((groupedEndpoint: { key: string; }) => groupedEndpoint.key === Constants.DATA_VALUES_NAMES.initialDislodgableFoliarResidue)?.textValue,
            DermalAbsorptionOfProduct: endpointByDataSet.find(endpointByDataSet => endpointByDataSet.dataSetPk === dataset.dataSetPk).endpoints.find((endpoint: { substanceType: string; }) => endpoint.substanceType === Constants.SUBSTANCE_TYPES.ACTIVE)?.groupedEndpointFieldValues.find((groupedEndpoint: { key: string; }) => groupedEndpoint.key === Constants.DATA_VALUES_NAMES.dermalAbsorptionProduct)?.textValue / 100,
            DermalAbsorptionOfInUseDilution: endpointByDataSet.find(endpointByDataSet => endpointByDataSet.dataSetPk === dataset.dataSetPk).endpoints.find((endpoint: { substanceType: string; }) => endpoint.substanceType === Constants.SUBSTANCE_TYPES.ACTIVE)?.groupedEndpointFieldValues.find((groupedEndpoint: { key: string; }) => groupedEndpoint.key === Constants.DATA_VALUES_NAMES.dermalAbsorptionInUseDilution)?.textValue / 100,
            OralAbsorptionOfActiveSubstance: endpointByDataSet.find(endpointByDataSet => endpointByDataSet.dataSetPk === dataset.dataSetPk).endpoints.find((endpoint: { substanceType: string; }) => endpoint.substanceType === Constants.SUBSTANCE_TYPES.ACTIVE)?.groupedEndpointFieldValues.find((groupedEndpoint: { key: string; }) => groupedEndpoint.key === Constants.DATA_VALUES_NAMES.oralAbsorptionActiveSubstance)?.textValue / 100,
            InhalationAbsorptionofActiveSubstance: endpointByDataSet.find(endpointByDataSet => endpointByDataSet.dataSetPk === dataset.dataSetPk).endpoints.find((endpoint: { substanceType: string; }) => endpoint.substanceType === Constants.SUBSTANCE_TYPES.ACTIVE)?.groupedEndpointFieldValues.find((groupedEndpoint: { key: string; }) => groupedEndpoint.key === Constants.DATA_VALUES_NAMES.inhalationAbsorptionActiveSubstance)?.textValue / 100,
            VapourPressureOfActiveSubstance: endpointByDataSet.find(endpointByDataSet => endpointByDataSet.dataSetPk === dataset.dataSetPk).endpoints.find((endpoint: { substanceType: string; }) => endpoint.substanceType === Constants.SUBSTANCE_TYPES.ACTIVE)?.groupedEndpointFieldValues.find((groupedEndpoint: { key: string; }) => groupedEndpoint.key === Constants.DATA_VALUES_NAMES.saturatedVapourPressure)?.textValue,
            MolecularWeightOfActiveSubstance: endpointByDataSet.find(endpointByDataSet => endpointByDataSet.dataSetPk === dataset.dataSetPk).endpoints.find((endpoint: { substanceType: string; }) => endpoint.substanceType === Constants.SUBSTANCE_TYPES.ACTIVE)?.groupedEndpointFieldValues.find((groupedEndpoint: { key: string; }) => groupedEndpoint.key === Constants.DATA_VALUES_NAMES.molecularWeight)?.textValue
          }

        };
        substanceProperties.push(substancePropertiesObject);
      }
    });
    return substanceProperties ?? [];
  }

  async getHumanHealthApplicationScheme(selectedProject: Project): Promise<HumanHealthApplicationScheme[]> {
    let humanHealthApplicationSchemes: HumanHealthApplicationScheme[] = [];
    let humanHealthApplicationSchemesObject: HumanHealthApplicationScheme;
    const compartment = await firstValueFrom(this.dataSetApiService.getCompartments(Constants.CONST_COMPARTMENT));
    const applicationSchemesByProjectAndCompartment = await firstValueFrom(this.HhraGapApiService.getHumanHealthApplicationSchemesByProjectAndCompartment(selectedProject?.projectPk!, compartment.find(x => x.compartment.toLowerCase() === Constants.HUMAN_HEALTH_RISK_ASSESSMENT_GAP[0].label.toLowerCase())?.endpointCompartmentPk!));
    applicationSchemesByProjectAndCompartment.forEach((applicationScheme: any) => {
      if (applicationScheme.useInProject) {
        humanHealthApplicationSchemesObject = {
          projectPk: applicationScheme.projectPk,
          name: applicationScheme.name,
          compartmentPk: applicationScheme.compartmentPk,
          numberOfApplications: applicationScheme.numberOfApplications,
          applicationInterval: applicationScheme.applicationInterval,
          applicationSchemeHumanHealthPk: applicationScheme.applicationSchemeHumanHealthPk,
          applicationSchemePk: applicationScheme.applicationSchemePk,
          applicationMethod: applicationScheme.applicationMethod,
          cropType: applicationScheme.applicationSchemeHumanHealth.cropType,
          activity: applicationScheme.applicationSchemeHumanHealth.activity,
          indoorOutdoorApplication: applicationScheme.applicationSchemeHumanHealth.indoorOutdoorApplication,
          maxProductApplicationRate: applicationScheme.applicationSchemeHumanHealth.maxProductApplicationRate!,
          applicationWaterVolumeMin: applicationScheme.applicationSchemeHumanHealth.applicationWaterVolumeMin!,
          applicationWaterVolumeMax: applicationScheme.applicationSchemeHumanHealth.applicationWaterVolumeMax!,
          applicationEquipment: applicationScheme.applicationSchemeHumanHealth.applicationEquipment,
          bufferStrip: applicationScheme.applicationSchemeHumanHealth.bufferStrip!,
          season: applicationScheme.applicationSchemeHumanHealth.season,
          driftReduction: applicationScheme.applicationSchemeHumanHealth.driftReduction,
          applicationClosedCab: applicationScheme.applicationSchemeHumanHealth.applicationClosedCab,
          aiConcentration: applicationScheme.applicationSchemeXActiveIngredientRate.find((x: { unitOfMeasure: string; }) => x.unitOfMeasure === Constants.CONCENTRATION_ACTIVE.AI)?.rate!,
          aeConcentration: applicationScheme.applicationSchemeXActiveIngredientRate.find((x: { unitOfMeasure: string; }) => x.unitOfMeasure === Constants.CONCENTRATION_ACTIVE.AE)?.rate!,
          applicationSchemeXActiveIngredientRate: applicationScheme.applicationSchemeXActiveIngredientRate
        };
        humanHealthApplicationSchemes.push(humanHealthApplicationSchemesObject);
      }
    })
    return humanHealthApplicationSchemes ?? [];
  }

  async getPersonalProtectionEquipmentScheme(selectedProject: Project): Promise<PersonalProtectionEquipmentScheme[]> {
    let personalProtectionEquipmentScheme: PersonalProtectionEquipmentScheme[] = [];
    let personalProtectionEquipmentSchemeObject: PersonalProtectionEquipmentScheme;

    const personalProtectionEquipment = await firstValueFrom(this.personalProtectionEquipmentSchemeApiService.getPersonalProtectionEquipmentScheme(selectedProject?.projectPk!));
    personalProtectionEquipment.forEach((ppe: any) => {
      if (ppe.useInProject) {
        personalProtectionEquipmentSchemeObject = {
          personalProtectionEquipmentSchemePK: ppe.personalProtectionEquipmentSchemePK,
          projectPK: ppe.projectPK,
          name: ppe.name,
          mixingLoadingGloves: ppe.mixingLoadingGloves,
          applicationGloves: ppe.applicationGloves,
          mixingLoadingBody: ppe.mixingLoadingBody,
          mixingLoadingClothing: ppe.mixingLoadingClothing,
          mixingLoadingHeadRespiratory: ppe.mixingLoadingHeadRespiratory,
          mixingLoadingWaterSolubleBag: ppe.mixingLoadingWaterSolubleBag ? ppe.mixingLoadingWaterSolubleBag : false,
          mixingLoadingRPE: ppe.mixingLoadingRPE,
          applicationClothing: ppe.applicationClothing,
          applicationHeadRespiratory: ppe.applicationHeadRespiratory,
          applicationBody: ppe.applicationBody,
          applicationRPE: ppe.applicationRPE
        }
        personalProtectionEquipmentScheme.push(personalProtectionEquipmentSchemeObject);
      }
    });

    return personalProtectionEquipmentScheme?? [];
  }
}