import { Component, EventEmitter, Input, Output, SimpleChanges, OnInit, inject, DestroyRef } from '@angular/core';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { MetabolitePkAndName } from 'src/app/shared/models/echo/metabolite';
import { EEAEndpointsByCompartmentLogicService } from '../../eea-endpoints-by-compartment/eea-endpoints-by-compartment.logic.service';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-eea-endpoints-select-compound',
  templateUrl: './eea-endpoints-select-compound.component.html',
  styleUrls: ['./eea-endpoints-select-compound.component.css']
})
export class EEAEndpointsSelectCompoundComponent implements OnInit {
  @Input() projectPk: number;
  @Input() moleculePk: number;
  @Input() compartment!: string;
  @Input() metabolitesOptions!: MetabolitePkAndName[];
  @Input() isProjectOwnershipValid: boolean = false;

  @Output() selectedMetabolitePk = new EventEmitter<number>();
  @Output() selectedActiveIngredientPk = new EventEmitter<number>();
  public metaboliteSelected: boolean;
  public activeButtonColor: string;
  public metaboliteButtonColor: string;
  public selectedMetabolitePkValue?: number;
  private unSelectedButtonColor: string;
  private selectedButtonColor: string;
  public excludeMetaboliteFromAI: boolean | undefined;
  destroyRef = inject(DestroyRef);

  constructor(private readonly endpointByCompartmentLogicService: EEAEndpointsByCompartmentLogicService){
    this.projectPk = 0;
    this.moleculePk = 0;
    this.metaboliteSelected = false;
    this.unSelectedButtonColor = 'secondary';
    this.selectedButtonColor = 'warning';
    this.activeButtonColor =  this.selectedButtonColor;
    this.metaboliteButtonColor =  this.unSelectedButtonColor;
    this.selectedMetabolitePkValue = 0;
    this.excludeMetaboliteFromAI = undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const metabolitesOptions = changes['metabolitesOptions'];
    if (metabolitesOptions && metabolitesOptions.previousValue !== metabolitesOptions.currentValue) {
      this.metaboliteSelected = false;
      this.selectedMetabolitePkValue = 0;
    }
  }

  ngOnInit(){
    this.endpointByCompartmentLogicService.excludeMetabolite$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if(value != undefined){
        this.excludeMetaboliteFromAI = value;
      }
    });
    this.selectedActiveIngredientPk.emit(this.moleculePk);
  }

  public onActiveIngredientClick() : void{
    this.endpointByCompartmentLogicService.excludeMetaboliteFromAI(undefined);
    this.metaboliteSelected = false;
    this.selectedMetabolitePkValue = 0;
    this.selectedActiveIngredientPk.emit(this.moleculePk);
    this.activeButtonColor = this.selectedButtonColor;
    this.metaboliteButtonColor = this.unSelectedButtonColor;
    this.endpointByCompartmentLogicService.runSaveObservable();
  }

  public onMetaboliteClick() : void{
    this.selectedActiveIngredientPk.emit(undefined);
    this.metaboliteSelected = true;
    this.selectedMetabolitePkValue = 0;
    this.activeButtonColor = this.unSelectedButtonColor;
    this.metaboliteButtonColor = this.selectedButtonColor;
    this.endpointByCompartmentLogicService.runSaveObservable();
  }

  public getSelectedMetabolite(dropdownEvent: DropdownChangeEvent): void{
    this.selectedMetabolitePkValue = dropdownEvent.value.metabolitePk;
    this.selectedActiveIngredientPk.emit(undefined);
    this.selectedMetabolitePk.emit(dropdownEvent.value.metabolitePk);
    this.endpointByCompartmentLogicService.runSaveObservable();
  }

  public onChangeExcludeMetabolite(checkBoxEvent: CheckboxChangeEvent): void{
    this.endpointByCompartmentLogicService.excludeMetaboliteFromAI(checkBoxEvent.checked);
  }
}
