
//AgGrid Imports
import { AgGridModule } from 'ag-grid-angular';

import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { EEASetupInputsComponent } from './eea-setup/eea-setup-inputs/eea-setup-inputs.component';
import { EEASetupModelsToBeRunComponent } from './eea-setup/eea-setup-models-to-be-run/eea-setup-models-to-be-run.component';
import { SharedModule } from '../shared/shared.module';
import { EEAEndpointsComponent } from './eea-endpoints/eea-endpoints/eea-endpoints.component';
import { EEAEndpointsCoreComponent } from './eea-endpoints/eea-endpoints-core/eea-endpoints-core.component';
import { FormsModule } from '@angular/forms';
import { EEAGapComponent } from './eea-gap/eea-gap/eea-gap.component';
import { EEARunProjectComponent } from './eea-run-project/eea-run-project/eea-run-project.component';
import { EEASetupComponent } from './eea-setup/eea-setup/eea-setup.component';
import { EnvironmentalExposureAssessmentComponent } from './environmental-exposure-assessment/environmental-exposure-assessment.component';
import { OrderByPipe } from '../shared/pipes/order-by.pipe';
import { EEAOutputProjectComponent } from './eea-output/eea-output-project/eea-output-project/eea-output-project.component';
import { EEAOutputStep1n2Component } from './eea-output/eea-output-project/eea-output-project-step1n2/eea-output-project-step1n2/eea-output-project-step1n2.component';
import { EEAOutputProjectGridComponent } from './eea-output/eea-output-project/eea-output-project-grid/eea-output-project-grid.component';
import { EEAOutputProjectStep1n2ScenarioComponent } from './eea-output/eea-output-project/eea-output-project-step1n2/eea-output-project-step1n2-scenario/eea-output-project-step1n2-scenario.component';
import { EEAOutputProjectResultsCalculationComponent } from './eea-output/eea-output-project/eea-output-project-step1n2/eea-output-project-step1n2-results-calculation/eea-output-project-step1n2-results-calculation.component';
import { EEAOutputProjectStep1n2ResultSummaryComponent } from './eea-output/eea-output-project/eea-output-project-step1n2/eea-output-project-step1n2-result-summary/eea-output-project-step1n2-result-summary.component';
import { EEAOutputProjectSwashSwanComponent } from './eea-output/eea-output-project/eea-output-project-swash-swan/eea-output-project-swash-swan/eea-output-project-swash-swan.component';
import { EEAOutputProjectSwashSwanResultSummaryComponent } from './eea-output/eea-output-project/eea-output-project-swash-swan/eea-output-project-swash-swan-result-summary/eea-output-project-swash-swan-result-summary.component';
import { EEAOutputProjectTier1ukComponent } from './eea-output/eea-output-project/eea-output-project-tier1uk/eea-output-project-tier1uk/eea-output-project-tier1uk.component';
import { EEAOutputProjectTier1ukDrainflowAndStreamComponent } from './eea-output/eea-output-project/eea-output-project-tier1uk/eea-output-project-tier1uk-drainflow-and-stream/eea-output-project-tier1uk-drainflow-and-stream.component';
import { EEAOutputProjectTier1ukPecDistanceComponent } from './eea-output/eea-output-project/eea-output-project-tier1uk/eea-output-project-tier1uk-pec-distance/eea-output-project-tier1uk-pec-distance.component';
import { EEAOutputProjectTier1ukPecTimeComponent } from './eea-output/eea-output-project/eea-output-project-tier1uk/eea-output-project-tier1uk-pec-time/eea-output-project-tier1uk-pec-time.component';
import { EEAOutputComponent } from './eea-output/eea-output/eea-output.component';
import { EEAOutputProjectMacroGWComponent } from './eea-output/eea-output-project/eea-output-project.macrogw/eea-output-project-macro-gw/eea-output-project-macro-gw.component';
import { EEAOutputProjectMacrogwResultSummaryComponent } from './eea-output/eea-output-project/eea-output-project.macrogw/eea-output-project-macrogw-result-summary/eea-output-project-macrogw-result-summary.component';
import { EEAEndpointsByCompartmentComponent } from './eea-endpoints/eea-endpoints-by-compartment/eea-endpoints-by-compartment.component';
import { EEAEndpointsSelectCompoundComponent } from './eea-endpoints/shared/eea-endpoints-select-compound/eea-endpoints-select-compound.component';
import { EeaEndpointsParametersComponent } from './eea-endpoints/shared/eea-endpoints-parameters/eea-endpoints-parameters.component';
import { EEAGapCoreComponent } from './eea-gap/eea-gap-core/eea-gap-core.component';
import { EeaSetupGapOutputsComponent } from './eea-setup/eea-setup-gap-outputs/eea-setup-gap-outputs.component';
import { EEAGapSurfaceWaterComponent } from './eea-gap/eea-gap-surfacewater/eea-gap-surfacewater.component';
import { EEAOutputProjectPelmoComponent } from './eea-output/eea-output-project/eea-output-project-pelmo/eea-output-project-pelmo/eea-output-project-pelmo.component';
import { EEAOutputProjectPelmoResultSummaryComponent } from './eea-output/eea-output-project/eea-output-project-pelmo/eea-output-project-pelmo-result-summary/eea-output-project-pelmo-result-summary.component';
import { EEAOutputViewComponent } from './eea-output/eea-output-view/eea-output-view.component';
import { EeaOutputProjectPearlResultSummaryComponent } from './eea-output/eea-output-project/eea-output-project-pearl/eea-output-project-pearl-result-summary/eea-output-project-pearl-result-summary.component';
import { EEAApplicationDialogComponent } from './eea-gap/shared/eea-application-dialog/eea-application-dialog.component';
import { EeaOutputProjectPwcComponent } from './eea-output/eea-output-project/eea-output-project-pwc/eea-output-project-pwc/eea-output-project-pwc.component';
import { EeaOutputProjectPwcInputsUsedInCalculationComponent } from './eea-output/eea-output-project/eea-output-project-pwc/eea-output-project-pwc-inputs-used-in-calculation/eea-output-project-pwc-inputs-used-in-calculation.component';
import { EEAGapGroundWaterComponent } from './eea-gap/eea-gap-ground-water/eea-gap-ground-water.component';
import { EeaGapNonCoreCompartmentComponent } from './eea-gap/eea-gap-non-core-compartment/eea-gap-non-core-compartment.component';
import { CropDropdownListRenderer } from './eea-gap/shared/renderers/crop-dropdown-list/crop-dropdown-list.component';
import { EEAOutputProjectPearlComponent } from './eea-output/eea-output-project/eea-output-project-pearl/eea-output-project-pearl/eea-output-project-pearl.component';
import { EEAEndpointsFormationFractionComponent } from './eea-endpoints/shared/eea-endpoints-formation-fraction/eea-endpoints-formation-fraction.component';
import { EEAGapSoilComponent } from './eea-gap/eea-gap-soil/eea-gap-soil.component';
import { EEARunProjectQCComponent } from './eea-run-project/shared/eea-run-project-qc/eea-run-project-qc.component';
import { EaaOutputProjectPwcResultSummmaryComponent } from './eea-output/eea-output-project/eea-output-project-pwc/eaa-output-project-pwc-result-summmary/eaa-output-project-pwc-result-summmary.component';
import { EEACopyProjectDialogComponent } from './eea-setup/shared/eea-copy-project-dialog/eea-copy-project-dialog.component';
import { EEAGapSurfacewaterPwcComponent } from './eea-gap/eea-gap-surfacewater-pwc/eea-gap-surfacewater-pwc.component';
import { EeaOutputProjectStep1n2RunInputsComponent } from './eea-output/eea-output-project/eea-output-project-step1n2/eea-output-project-step1n2-run-inputs/eea-output-project-step1n2-run-inputs.component';
import { EeaOutputProjectTier1ukRunInputsComponent } from './eea-output/eea-output-project/eea-output-project-tier1uk/eea-output-project-tier1uk-run-inputs/eea-output-project-tier1uk-run-inputs.component';
import { EeaOutputProjectMacroGwRunInputsComponent } from './eea-output/eea-output-project/eea-output-project.macrogw/eea-output-project-macro-gw-run-inputs/eea-output-project-macro-gw-run-inputs.component';
import { EeaOutputProjectSwanRunInputsUseInCalculationComponent } from './eea-output/eea-output-project/eea-output-project-swash-swan/eea-output-project-swan-run-inputs-use-in-calculation/eea-output-project-swan-run-inputs-use-in-calculation.component';
import { EeaOutputProjectPelmoRunInputsInputsUseInCalculationComponent } from './eea-output/eea-output-project/eea-output-project-pelmo/eea-output-project-pelmo-run-inputs-inputs-use-in-calculation/eea-output-project-pelmo-run-inputs-inputs-use-in-calculation.component';
import { EeaOutputPearlRunInputsUseInCalculationComponent } from './eea-output/eea-output-project/eea-output-project-pearl/eea-output-pearl-run-inputs-use-in-calculation/eea-output-pearl-run-inputs-use-in-calculation.component';
import { EnvironmentalRiskAssessmentModule } from '../environmental-risk-assessment/environmental-risk-assessment.module';


@NgModule({
  declarations: [
    EEASetupInputsComponent,
    EEASetupModelsToBeRunComponent,
    EEAEndpointsComponent,
    EEAEndpointsCoreComponent,
    EEAOutputViewComponent,
    EEAGapComponent,
    EEARunProjectComponent,
    EEASetupComponent,
    EnvironmentalExposureAssessmentComponent,
    EEAOutputProjectComponent,
    EEAOutputStep1n2Component,
    EEAOutputProjectGridComponent,
    EEAOutputProjectStep1n2ScenarioComponent,
    EEAOutputProjectResultsCalculationComponent,
    EEAOutputProjectStep1n2ResultSummaryComponent,
    EEAOutputProjectSwashSwanComponent,
    EEAOutputProjectSwashSwanResultSummaryComponent,
    EEAOutputProjectTier1ukComponent,
    EEAOutputProjectTier1ukDrainflowAndStreamComponent,
    EEAOutputProjectTier1ukPecDistanceComponent,
    EEAOutputProjectTier1ukPecTimeComponent,
    EEAOutputComponent,
    EEAEndpointsByCompartmentComponent,
    EEAEndpointsSelectCompoundComponent,
    EeaEndpointsParametersComponent,
    EEAOutputProjectMacroGWComponent,
    EEAOutputProjectMacrogwResultSummaryComponent,
    EEAGapCoreComponent,
    EeaSetupGapOutputsComponent,
    EEAGapSurfaceWaterComponent,
    EEAEndpointsFormationFractionComponent,
    EEAApplicationDialogComponent,
    EEAOutputProjectPelmoComponent,
    EEAOutputProjectPelmoResultSummaryComponent,
    EeaOutputProjectPearlResultSummaryComponent,
    EeaOutputProjectPwcComponent,
    EeaOutputProjectPwcInputsUsedInCalculationComponent,
    EeaGapNonCoreCompartmentComponent,
    EEAGapGroundWaterComponent,
    CropDropdownListRenderer,
    EEAOutputProjectPearlComponent,
    EEAGapSoilComponent,
    EEARunProjectQCComponent,
    EaaOutputProjectPwcResultSummmaryComponent,
    EEACopyProjectDialogComponent,
    EEAGapSurfacewaterPwcComponent,
    EeaOutputProjectStep1n2RunInputsComponent,
    EeaOutputProjectTier1ukRunInputsComponent,
    EeaOutputProjectMacroGwRunInputsComponent,
    EeaOutputProjectSwanRunInputsUseInCalculationComponent,
    EeaOutputProjectPelmoRunInputsInputsUseInCalculationComponent,
    EeaOutputPearlRunInputsUseInCalculationComponent
  ],
  imports: [
    AgGridModule,
    FormsModule,
    CommonModule,
    SharedModule,
    EnvironmentalRiskAssessmentModule
  ],
  providers:[
    OrderByPipe,
    DatePipe
  ],
  exports:[
    EEASetupComponent,
    EEAEndpointsComponent,
    EEAGapComponent,
    EEAGapSoilComponent,
    EEAGapGroundWaterComponent,
    EEAGapSurfacewaterPwcComponent,
    EEAGapSurfaceWaterComponent,
    EEAOutputComponent,
    EEACopyProjectDialogComponent,
    EEARunProjectComponent,
    EeaEndpointsParametersComponent,
    EEAEndpointsSelectCompoundComponent
  ]
})
export class EnvironmentalExposureAssessmentModule { }
