import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Project } from '../../models/project';
import { Constants } from '../../utils/constants';
import Swal from 'sweetalert2';
import { ProjectApiService } from '../../services/project.api.service';
import { SelectedProjectApiService } from '../selected-project/selected-project.api.service';
import { Subscription, switchMap, take, timer } from 'rxjs';
import { RunApiService } from '../../services/run.api.service';
import { ProjectPreviousRun } from '../../models/project-previous-run';
import { ColDef } from 'ag-grid-community';
import { GridComponent } from '../grid/grid.component';
import { PreviosRunsColdef } from './previous-runs.coldef';

@Component({
  selector: 'app-project-status',
  templateUrl: './project-status.component.html',
  styleUrls: ['./project-status.component.css']
})
export class ProjectStatusComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('grid') private grid!: GridComponent;
  @Input() selectedProject?: Project;
  @Input() selectedProjectStatus?: number;
  statusId?: number;
  statusName: string = "";
  badgeStyle: string = "";
  models: any[] = [];
  isAllFilesDownloading: boolean = false;
  isSummaryDownloading: boolean = false;
  isStoping: boolean = false;
  subscription!: Subscription;
  subscriptionRunsSaved!: Subscription;
  visible: boolean = false;
  rowData: any[] = [];
  public rowSelection: "single" | "multiple" = "multiple";
  downloadAllFiles: boolean = false;
  paginationPageSize: number = 5;
  divStyle: any = {
    width: '100%',
    height: '350px',
    flex: '1 1 auto',
  };

  gridStyle: any = {
    width: 'auto',
    height: '341px'
  }

  constructor(private projectService: ProjectApiService,
    private selectedProjectApiService: SelectedProjectApiService,
    public runApiService: RunApiService,
    public previosRunsColdef: PreviosRunsColdef) { }

  columnDefs: ColDef[] = this.previosRunsColdef.getColumnsDefinition();

  refresh(): boolean {
    return false;
  }

  ngOnInit(): void {
    this.statusId = this.selectedProject?.status;
    this.selectedProjectApiService.updateSelectedProjectStatus(this.selectedProject!.status);
    this.statusStyle(this.statusId ?? 0);
    this.monitorProjectStatus();
    this.monitorRunsHaveBeenSaved();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges['selectedProject']) {
      this.statusId = this.selectedProject?.status;
      this.statusStyle(this.statusId ?? 0);
    };
  }

  statusStyle(statusId: number) {
    switch (statusId) {
      case 0:
        this.statusName = Constants.PROJECT_STATUS.NOT_STARTED;
        this.badgeStyle = '';
        break;
      case 1:
        this.statusName = Constants.PROJECT_STATUS.IN_PROGRESS.concat("\u00A0");
        this.badgeStyle = 'inProgress';
        break;
      case 2:
        this.statusName = Constants.PROJECT_STATUS.COMPLETE.concat("\u00A0");
        this.badgeStyle = 'complete';
        break;
      case 3:
        this.statusName = Constants.PROJECT_STATUS.FAILED.concat("\u00A0");
        this.badgeStyle = 'failed';
        break;
      default:
        this.badgeStyle = '';
        break;
    }
  }

  showDownloadButton(): boolean {
    return this.statusId === 2;
  }

  showStopProjectButton(): boolean {
    return this.statusName === Constants.PROJECT_STATUS.IN_PROGRESS.concat("\u00A0");
  }

  onDownloadOutputFilesClick(pDownloadAllFiles: boolean = true): void {
    if (this.selectedProject?.previousRuns && this.selectedProject.previousRuns.length == 1) {
      this.visible = false;
      this.downloadOutputFilesByOutputFolders(this.selectedProject.previousRuns, pDownloadAllFiles);
    }
    if (this.selectedProject?.previousRuns && this.selectedProject.previousRuns.length > 1) {
      this.visible = true;
      this.downloadAllFiles = pDownloadAllFiles;
    }
  }

  onDownloadSelectedRowsClick() {
    this.visible = false;
    this.downloadOutputFilesByOutputFolders(this.grid.gridApi.getSelectedRows(), this.downloadAllFiles);
  }

  downloadOutputFilesByOutputFolders(previousRuns: any, pDownloadAllFiles: boolean = true): void {
    this.setButtonLoading(pDownloadAllFiles);
    let outpuFolders = previousRuns
      .map((run: any) => run.lastOutputFolder)
      .flat();
    this.projectService.downloadOutputFilesByOutputFolders(outpuFolders, pDownloadAllFiles)
      .subscribe({
        next: (urls) => {
          this.setButtonLoading(pDownloadAllFiles);
          if (urls != null && urls.length > 0) {
            urls.forEach((url: string) => {
              window.open(url, '_blank');
            });
          }
        },
        error: (error) => {
          console.error(error);
          this.setButtonLoading(pDownloadAllFiles);
          this.showErrorAlert("Files could not be downloaded")
        },
      });
  }

  onStopProjectClick(): void {
    if (this.selectedProject) {
      this.isStoping = true;
      this.projectService.stopProject(this.selectedProject).subscribe({
        next: () => {
          const status = 0;
          this.selectedProject!.status = status;
          this.statusId = status;
          this.isStoping = false;
          this.statusStyle(this.statusId ?? 0);
          this.selectedProjectApiService.updateSelectedProjectStatus(this.selectedProject!.status);
          this.subscription.unsubscribe();
        },
        error: (error) => {
          this.isStoping = false;
          console.warn(error);
        },
      })
    }
  }

  monitorProjectStatus(): void {
    this.subscription = timer(0, 30000).pipe(
      switchMap(() => this.projectService.getProjectStatus(this.selectedProject!.projectPk).pipe(take(1)))
    ).subscribe({
      next: (status) => {
        this.selectedProject!.status = status;
        this.statusId = this.selectedProject?.status;
        this.statusStyle(this.statusId ?? 0);
        if (this.selectedProject!.status !== 1) {
          this.getPreviousRuns(this.selectedProject);
          this.selectedProjectApiService.updateSelectedProjectStatus(this.selectedProject!.status);
          this.subscription.unsubscribe();
        }
      },
      error: (error) => {
        console.warn(error)
      }
    });
  }

  getPreviousRuns(selectedProject: Project | undefined) {
    if (selectedProject?.status === 2) {
      this.projectService.getPreviousRunsByProject(selectedProject.projectPk).subscribe({
        next: (projectPreviousRun: ProjectPreviousRun[]) => {
          selectedProject.previousRuns = projectPreviousRun;
          this.rowData = projectPreviousRun.filter((run: any) => run.projectStatus === 2 && run.status === 2);
        },
        error: (error: Error) => {
          console.warn(error)
        }
      });
    };
  }

  monitorRunsHaveBeenSaved(): void {
    this.subscriptionRunsSaved = timer(0, 30000).pipe(
      switchMap(() => this.runApiService.runsHaveBeenSaved(this.selectedProject!.projectPk).pipe(take(1)))
    ).subscribe({
      next: (status) => {
        this.selectedProject!.runsSaved = status;
        if (status) {
          this.selectedProjectApiService.updateRunsHaveBeenSaved(this.selectedProject!.runsSaved);
          this.subscriptionRunsSaved.unsubscribe();
        }
      },
      error: (error) => {
        console.warn(error)
      }
    });
  }

  setButtonLoading(pDownloadAllFiles: boolean = true): void {
    if (pDownloadAllFiles) {
      this.isAllFilesDownloading = !this.isAllFilesDownloading;
    } else {
      this.isSummaryDownloading = !this.isSummaryDownloading;
    }
  }

  setStopProjectButtonStoping(): void {
    this.isStoping = !this.isStoping;
  }

  stopProject(pParams: any): void {
    this.setStopProjectButtonStoping();
    this.projectService.stopProject(this.createProjectObject(pParams))
      .subscribe({
        next: (url) => {
          this.setStopProjectButtonStoping();
          pParams.data.statusName = Constants.PROJECT_STATUS.NOT_STARTED;
          pParams.data.status = 0;
          pParams.data.startDate = undefined;
        },
        error: (error) => {
          console.error(error);
          this.setStopProjectButtonStoping();
          this.showErrorAlert(Constants.MESSAGES.PROJECT_NOT_STOPED);
        },
      });
  }

  showErrorAlert(text: string) {
    Swal.fire({
      title: 'Notification',
      html: text,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'OK',
    }).then((result) => {

    });
  }

  createProjectObject(data: any, pDownloadAllFiles: boolean = true): Project {
    const project: Project = {
      projectPk: data.projectPk,
      active: data.active,
      createdBy: data.createdBy,
      deletable: data.deletable,
      description: data.description,
      formulationPk: data.formulationPk,
      name: data.name,
      status: data.status,
      downloadAllFiles: pDownloadAllFiles,
      createdDate: data.createdDate
    };
    return project;
  }
}