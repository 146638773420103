import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { User } from '../models/user.model';
import { Profile } from '../models/profile';
import { environment } from 'src/environments/environment';
import { Role } from '../models/role';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { RoleApiService } from './role.api.service';
import { Constants } from '../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class UserLogicService {

  profile: Profile = {};
  userRoles: Role[] = [];
  loading: boolean = true;
  private readonly _destroying$ = new Subject<void>();
  private selectedRoleSubject = new BehaviorSubject<Role | null>(null);
  selectedRole$ = this.selectedRoleSubject.asObservable();
  selectedRole!: Role;

  readerRole: Role = {
    rolesPk: 0,
    role: 'Reader',
    bitmask: 0,
    comment: '',
    active: '',
    rolesXPermissions: [],
    rolesXDisciplines: [],
  };

  logoutRole: Role = {
    rolesPk: 0,
    role: '(Logout)',
    bitmask: -1,
    comment: '',
    active: '',
    rolesXPermissions: [],
    rolesXDisciplines: [],
  };

  constructor(private authService: MsalService,
              private msalBroadcastService: MsalBroadcastService,
              private rolesApiService: RoleApiService,
              private http: HttpClient) {
  }

  async initProfile()
  {
    this.loading = true;
    this.msalBroadcastService.inProgress$
    .pipe(
      filter(
        (status: InteractionStatus) => status === InteractionStatus.None
      ),
      takeUntil(this._destroying$)
    )
    .subscribe(async () => {
      await this.getProfile();
    });
  }

  async getProfile() {
    try {
      const profile: Profile = await firstValueFrom(this.http.get('https://graph.microsoft.com/v1.0/me'));
      this.profile = profile;
      await this.getUserRoles(this.profile.mail);
      this.loading = false;
      this.checkAndSetActiveAccount();
    } catch (e) {
      console.error(e);
    }
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }
  
  GetAll() : Observable<User[]>
	{
    const query = environment.BAMS_Api_URL + 'api/users';
    return this.http.get(query)
      .pipe(map((data: any) => data.value));
  }

  GetEchoUsers(): Observable<any[]>
  {
    const query = environment.ECHO_Api_URL + "api/users";
    return this.http.get(query)
    .pipe(map((data: any) => data.Value));
  }

  GetByID(id: number) : Observable<User>
	{
    const query = environment.BAMS_Api_URL + 'api/user/' + id;
    return this.http.get(query)
    .pipe(map((response: any) => response.value as User));
  }

  GetByNickName(nickName: string) : Observable<any>
	{
    return this.http.get("https://graph.microsoft.com/v1.0/users?$filter=mailNickname eq '" + nickName + "'");
  }
  
  getUserRoles(pUserID?: string): void {
    try {
      this.rolesApiService.getUserRoles(pUserID).subscribe({
        next: (usersRoles: Role[]) => {
          this.userRoles = usersRoles;
          this.userRoles.push(this.readerRole);
          this.userRoles.push(this.logoutRole);
          this.selectedRole = this.userRoles.find((x) => x.bitmask == 1)
            ? this.userRoles.filter((x) => x.bitmask == 1)[0]
            : this.userRoles.find((c) => c.bitmask == 2)
            ? this.userRoles.filter((x) => x.bitmask == 2)[0]
            : this.readerRole;
          this.rolesApiService.userRolesBitmask = this.userRoles.find(
            (x) => x.bitmask == 1
          )
            ? 1
            : this.userRoles.find((c) => c.bitmask == 2)
            ? 2
            : this.readerRole.bitmask;
          this.selectedRoleSubject.next(this.selectedRole);
          this.rolesApiService.systemRoles = this.userRoles;
          this.rolesApiService.userRolesBitmask = this.selectedRole?.bitmask;
        },
        error: (e:Error) => {
          console.error(e);
        },
      });
    } catch (e) {
      console.error(e);
    }
  }
  
  roleSelectionChange(event: DropdownChangeEvent): void {
    if(this.selectedRole?.bitmask == -1)
    {
      this.logout();
      return;
    }
    this.rolesApiService.userRolesBitmask = this.selectedRole?.bitmask;
    this.selectedRoleSubject.next(this.selectedRole);
  }
  
  logout(): void {
    this.authService.logout();
    localStorage.clear();
  }

  verifyProjectOwnership(createdBy?: string) {
    return this.profile.displayName == createdBy && this.currentUserCanRunModels();
  }

  async currentUserCanEditAdmin(): Promise<boolean> {
    return new Promise((resolve) => {
      this.selectedRole$.pipe(
        filter((role) => !!role),
        take(1)
      ).subscribe((role) => {
        const isAdmin = this.userRoles.map((r: Role) => r.role).includes(Constants.ROLE_ADMIN);
        resolve(isAdmin && (role?.bitmask!= undefined && role?.bitmask > 0));
      });
    });
  }
  
  currentUserCanRunModels(): boolean {
    return  this.selectedRole?.bitmask!= undefined && this.selectedRole?.bitmask > 0;
  }

  currentUserCanRunModelsAsync(): Promise<boolean> {
    return new Promise((resolve) => {
      this.selectedRole$.pipe(
        filter((role) => !!role),
        take(1)
      ).subscribe((role) => {
        resolve((role?.bitmask!= undefined && role?.bitmask > 0));
      });
    });
  }
}