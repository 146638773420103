import { Injectable } from '@angular/core';
import { ColDef } from 'ag-grid-enterprise';
import { ModelLibrary } from 'src/app/shared/models/model-library';
import { Constants } from 'src/app/shared/utils/constants';

@Injectable({
  providedIn: 'root',
})

export class EEASetupColDef {

  public getColumnsDefinition(swModels: ModelLibrary[], gwModels: ModelLibrary[], soilModels: ModelLibrary[], isProjectOwnershipValid: boolean): ColDef[] {
    return [
      {
        headerName: 'Compound',
        field: 'metaboliteName',
        width: 250,
        filter: 'agSetColumnFilter',
        wrapText: true,
        resizable: true,
        sortable: true,
        editable: false,
      },
      {
        headerName: 'SW Models',
        field: Constants.COMPARTMENTS.SURFACE_WATER.replace(" ","").toLowerCase(),
        width: 250,
        cellRenderer: 'listBoxRenderer',
        cellRendererParams: {
          options: swModels,
          optionValue: 'modelPk',
          optionLabel: 'nameVersion',
          checkbox: true,
          filter: false,
          multiple: true,
          disabled: !isProjectOwnershipValid,
          validateModel: true,
          compartment: Constants.COMPARTMENTS.SURFACE_WATER.replace(" ","").toLowerCase(),
        },
        filter: 'agSetColumnFilter',
        wrapText: true,
        resizable: true,
        sortable: true,
        editable: false,
      },
      {
        headerName: 'GW Models',
        field: Constants.COMPARTMENTS.GROUND_WATER.toLowerCase(),
        width: 250,
        cellRenderer: 'listBoxRenderer',
        cellRendererParams: {
          options: gwModels,
          optionValue: 'modelPk',
          optionLabel: 'nameVersion',
          checkbox: true,
          filter: false,
          multiple: true,
          disabled: !isProjectOwnershipValid,
          validateModel: true,
          compartment: Constants.COMPARTMENTS.GROUND_WATER.toLowerCase(),
         
        },
        filter: 'agSetColumnFilter',
        wrapText: true,
        resizable: true,
        sortable: true,
        editable: false,
      },
      {
        headerName: 'Soil Models',
        field: Constants.COMPARTMENTS.SOIL.toLowerCase(),
        width: 250,
        cellRenderer: 'listBoxRenderer',
        cellRendererParams: {
          options: soilModels,
          optionValue: 'modelPk',
          optionLabel: 'nameVersion',
          checkbox: true,
          filter: false,
          multiple: true,
          disabled: !isProjectOwnershipValid,
          validateModel: true,
          compartment: Constants.COMPARTMENTS.SOIL.toLowerCase(),
        },
        filter: 'agSetColumnFilter',
        wrapText: true,
        resizable: true,
        sortable: true,
        editable: false,
      }
    ];
  }
}
