<div class="grid border-bottom-5 border-dotted border-300 border-x-none border-top-none pt-3 pb-1">
    <div class="col-12 flex justify-content-center">
        <div class="col-3"></div>
        <div class="col-7">
            <div class="grid">
                <div class="col-7 pb-0 flex justify-content-start">
                    <label class="pt-3 text-secundary text-xl">Select an existing Project:</label>
                </div>
                <div *ngIf="currentUserCanRunModels"  class="col-5 pb-0 flex justify-content-start">
                    <label class="pt-3 text-secundary font-italic text-xl">or create a new one:</label>
                    <app-button class="ml-2 pt-2" [icon]="'pi pi-plus'" [rounded]="true" [raised]="true"
                        [severity]="'secondary'" [size]="'small'" (onClickEvent)="initNewProject()"></app-button>
                </div>
            </div>
        </div>
        <div class="col-2"></div>
    </div>
    <div class="col-12 flex justify-content-center">
        <div class="col-3"></div>
        <div class="col-7">
            <div class="grid">
                <div class="w-8_5">
                    <div class="flex flex-column h-full">
                        <p-dropdown #selectedProjectDDL [(ngModel)]="selectedProject" [options]="searchFilteredProjects"
                            [placeholder]="placeholder" optionLabel="name" [showClear]="false" [filter]="true"
                            filterBy="name, projectPk" [filterFields]="['name','projectPk']"  scrollHeight="600px"
                            (onChange)="onSelectionChanged($event.value)">
                            <ng-template pTemplate="selectedItem">
                                <div class="flex align-items-center text-lg"
                                    *ngIf="selectedProject && selectedProject.projectPk > 0 && !loading">
                                    <div>
                                        <p class="project"><b>{{ selectedProject.name }}
                                            </b><em>{{selectedProject.projectPk}}</em></p>
                                    </div>
                                </div>
                                <div class="flex align-items-center text-lg"
                                    *ngIf="!selectedProject && placeholder === ' '">
                                    <div>
                                        <p class="project h-1-5"></p>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template let-project pTemplate="item">
                                <div class="flex align-items-center text-lg">
                                    <div>
                                        <p><b>{{ project.name }} </b><em>{{project.projectPk}}</em></p>
                                    </div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="col pt-0">
                    <app-button [label]="'My Projects'" [size]="'large'" [text]="true" [plain]="true"
                        [icon]="myProjectsFilter ? 'pi pi-check-square': 'pi pi-stop'" [iconPos]="'left'"
                        (onClickEvent)="userFilterProjects(!myProjectsFilter)"></app-button>
                </div>
            </div>
        </div>
        <div class="col-2 flex justify-content-end pt-1">
            <app-button class="pt-1" [label]="'Advanced Filters'" [size]="'large'" [text]="true" [plain]="true"
                [icon]="'pi pi-filter'" [iconPos]="'left'"
                (onClickEvent)="showAdvancedFiltersDialog()"></app-button>
        </div>
    </div>
</div>
<app-advanced-filters-dialog (rowSelected)="handleAdvancedFilterRow($event)" *ngIf="this.advancedFiltersVisibles"></app-advanced-filters-dialog>