import { ChangeDetectorRef, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { CustomTabMenuItem } from 'src/app/shared/models/custom-tab-menu-item';
import { HHRALogicService } from './human-health-risk-assessment.logic.service';
import { Message } from 'primeng/api';
import { Project } from 'src/app/shared/models/project';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Constants } from 'src/app/shared/utils/constants';
import { ModelLibrary, ModelLibraryCollection } from 'src/app/shared/models/model-library';
import { firstValueFrom, take } from 'rxjs';
import { DisciplineApiService } from 'src/app/shared/services/echo/discipline.api.service';
import { Discipline } from 'src/app/shared/models/echo/discipline';
import { DataSetApiService } from 'src/app/shared/services/echo/data-set.api.service';
import { OrderByPipe } from 'src/app/shared/pipes/order-by.pipe';
import { ModelLibraryApiService } from 'src/app/shared/services/model-library.api.service';
import { HHEASetupColDef } from './hhea-setup-coldef';

@Component({
  selector: 'app-human-health-risk-assessment',
  templateUrl: './human-health-risk-assessment.component.html',
  styleUrls: ['./human-health-risk-assessment.component.css']
})
export class HumanHealthRiskAssessmentComponent implements OnInit{

  activeItem!: CustomTabMenuItem;
  message!: Message;
  loading: boolean = false;
  nextIndex?: number;
  selectedProject?: Project;
  selectedProjectStatus?: number;
  isSetupValid: boolean = false;
  destroyRef = inject(DestroyRef)
  isProjectOwnershipValid: boolean = true;
  disciplinePk: number = 0;
  compartments: string[] = [Constants.COMPARTMENTS.DIETARY.toLowerCase(), Constants.COMPARTMENTS.NONDIETARY.toLowerCase(), Constants.COMPARTMENTS.CORE.toLowerCase()];
  columnDef: any[] = [];
  modelsCollection: ModelLibraryCollection[] = [];
  compartmentList: any[] = [];

  constructor(public HHRALogicService: HHRALogicService,
              private selectedProjectApiService: SelectedProjectApiService,
              private disciplineApiService: DisciplineApiService,
              private userLogicService: UserLogicService,
              private orderByPipe: OrderByPipe,
              private modelLibraryApiService: ModelLibraryApiService,
              private HHEASetupColDef: HHEASetupColDef,
              private dataSetService: DataSetApiService,
              private cdr: ChangeDetectorRef){}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.initCompartemtList();
    await this.getDiscipline();
    await this.initModels();
    this.initSubscribes();
  }

  async initCompartemtList(): Promise<void> {
    await firstValueFrom(this.dataSetService.getCompartments(Constants.CONST_COMPARTMENT).pipe(take(1))).then(((compartment: any) => {
      this.compartmentList = compartment;
    }));
  }

  async getDiscipline(): Promise<void> {
    await firstValueFrom(this.disciplineApiService.getDisciplineByName(Constants.DISCIPLINE_IDENTITIES.TOXICOLOGY).pipe(take(1))).then((discipline: Discipline) =>{
        this.disciplinePk = discipline?.disciplinePk ?? -1;
    });
  }

  onActiveIndexChange(index: number) {
    this.nextIndex = index;
    this.HHRALogicService.setActiveIndexChanged(true, index);
  }

  initSubscribes(): void {
    this.selectedProjectApiService.selectedProject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((project?: Project) => {
      this.selectedProject = project;
      this.isProjectOwnershipValid = this.selectedProject ? this.userLogicService.verifyProjectOwnership(this.selectedProject?.createdBy) : true;
      this.initColumnDef();
      this.onActiveIndexChange(0);
    });

    this.selectedProjectApiService.selectedProjectStatus.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((status?: number) => {
      this.selectedProjectStatus = status;
    });

    this.HHRALogicService.activeItem.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(activeItem => {
      this.activeItem = activeItem
    });

    this.HHRALogicService.isLoading$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(isLoading => {
      this.loading = isLoading;
    });

    this.HHRALogicService.success.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(success => {
      this.loading = false;
      if (success) {
        const nextItem = this.HHRALogicService.items.find(i => i.index == this.nextIndex);
        if (nextItem)
          this.HHRALogicService.setActiveItem(nextItem);
      }
    });
  }

  async initModels() {
    let localCollection: ModelLibraryCollection[] = [];
    let models: ModelLibrary[] = [];
    this.compartments.forEach(compartment => {
      localCollection.push({name: compartment, library: []});
    });

    await firstValueFrom(this.modelLibraryApiService.getModels()).then((library: ModelLibrary[]) => {
      let dietary = localCollection.find(x => x.name.toLowerCase() === Constants.COMPARTMENTS.DIETARY.toLowerCase())
      let nonDietary = localCollection.find(x => x.name.toLowerCase() === Constants.COMPARTMENTS.NONDIETARY.toLowerCase());
      models = library.filter(f => f.compartmentXModel?.length && f.compartmentXModel?.length >= 0 && f.disciplineXModel?.some(d => d == this.disciplinePk));
      if(dietary) dietary.library = this.orderByPipe.transform(models.filter(m => m.compartmentXModel?.includes(this.compartmentList?.find(x => x.compartment.toLowerCase() === Constants.COMPARTMENTS.DIETARY.toLowerCase())?.endpointCompartmentPk)), 'nameVersion');
      if(nonDietary) nonDietary.library = this.orderByPipe.transform(models.filter(m => m.compartmentXModel?.includes(this.compartmentList?.find(x =>  x.compartment.replace("-","").toLowerCase() === Constants.COMPARTMENTS.NONDIETARY.toLowerCase())?.endpointCompartmentPk)), 'nameVersion');
    });
    this.modelsCollection = localCollection;
  }

  initColumnDef() {
    let dietary = this.modelsCollection.find(x => x.name.toLowerCase() === Constants.COMPARTMENTS.DIETARY.toLowerCase())?.library;
    let nonDietary = this.modelsCollection.find(x => x.name.toLowerCase() === Constants.COMPARTMENTS.NONDIETARY.toLowerCase())?.library;
    this.columnDef = this.HHEASetupColDef.getColumnsDefinition(dietary ?? [], nonDietary ?? [], this.isProjectOwnershipValid);
  }

  validateSetup(isSetupValid: boolean) {
    const copyItems = [...this.HHRALogicService.items];
    copyItems[1].label = this.selectedProject?.geography == Constants.GEOGRAPHYS.USA ? Constants.COMPARTMENTS.TOX_EXPO_INPUTS: Constants.COMPARTMENTS.SUBSTANCE_PROPERTIES;
    copyItems[1].visible = isSetupValid;
    copyItems[2].visible = isSetupValid;
    copyItems[3].visible = isSetupValid && this.selectedProject?.geography != Constants.GEOGRAPHYS.USA;
    copyItems[4].visible = isSetupValid;
    this.HHRALogicService.items = copyItems;
    this.isSetupValid = isSetupValid;
    this.cdr.detectChanges();
  }

  public getIsValidApplicationScheme(isValid: boolean): void {
    this.isSetupValid = isValid;
    this.cdr.detectChanges();
  }
}