import { Component, DestroyRef, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MessageService } from 'primeng/api';
import { take } from 'rxjs';
import { ModelLibraryService } from 'src/app/administrator/regulatory-models/model-library/model-library.service';
import { ModelLibrary } from 'src/app/shared/models/model-library';
import { Project } from 'src/app/shared/models/project';
import { Constants } from 'src/app/shared/utils/constants';
import { ExposureAssessmentOutputViewApiService } from '../../eea-output/eea-output.api.service';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-eea-output-project',
  templateUrl: './eea-output-project.component.html',
  styleUrls: ['./eea-output-project.component.css'],
  providers: [MessageService]
})
export class EEAOutputProjectComponent implements OnInit, OnChanges {
  @Input() selectedProject?: Project;
  afterSearch: boolean = false;
  loading: boolean = false;
  showingFiles: boolean = false;
  models: ModelLibrary[] = [];
  MODELNAMES: any;
  selectedModel!: string;
  selected!: DropdownChangeEvent
  options: any[] = [];
  selectedProjectStatus: number | undefined = 0;
  selectedProjectRunsSaved: boolean | undefined = false;
  destroyRef = inject(DestroyRef)

  constructor(private modelService: ModelLibraryService,
    private messageService: MessageService,
    private viewOutputService: ExposureAssessmentOutputViewApiService,
    private selectedProjectApiService: SelectedProjectApiService,) { }

  ngOnInit(): void {
    this.selectedProjectStatus = this.selectedProject?.status;
    this.selectedProjectRunsSaved = this.selectedProject?.runsSaved;
    this.selectedModel = '';
    this.viewOutputService.getAllFields();
    this.MODELNAMES = Constants.MODELS;
    this.loadModels();
    this.selectedProjectApiService.selectedProjectStatus.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((status?: number) => {
      this.selectedProjectStatus = status;
    });
    this.selectedProjectApiService.selectedProjectRunsSaved.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((runsSaved?: boolean) => {
      this.selectedProjectRunsSaved = runsSaved;
    });
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges['selectedProject']) this.loadModels();
  }

  loadModels() {
    this.setLoadingState(true);
    this.modelService.getModelsByProjectPk(this.selectedProject?.projectPk ?? 0)
      .pipe(take(1))
      .subscribe({
        next: (models: ModelLibrary[]) => {
          this.models = models;
          this.options = this.getSelectedModels();
          if (this.options.length <= 1)
            this.selectedModel = this.options[0];
        },
        error: (error) => {
          console.error(error);
          this.messageService.add({
            severity: 'error',
            summary: error.error.Value.Title,
            detail: error.error.Value.Detail,
          });
        },
        complete: () => {
          this.setLoadingState(false);
        }
      });
  }

  setLoadingState(pState: boolean): void {
    this.loading = pState;
    this.afterSearch = !pState;
  }

  onSeletedModelChange({ value }: DropdownChangeEvent): void {
    this.selectedModel = value;
  }

  getSelectedModels() {
    let models: any = this.models?.map((model) => model.name)
    return [...new Set(models)];
  }
}
