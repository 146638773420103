import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { MenuService } from './menu.service';
import { Constants } from 'src/app/shared/utils/constants';
import { Router } from '@angular/router';
import { filter, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-layout-menu',
  templateUrl: './layout-menu.component.html',
  styleUrls: ['./layout-menu.component.css'],
  providers: [MessageService],
})
export class LayoutMenuComponent implements OnInit {
  Items: MenuItem[] = [];
  hidden = false;
  iconCollapse = 'pi pi-angle-left';
  iconExpande = 'pi pi-angle-right';
  icon = this.iconCollapse;

  @Output() MenuHideEvent = new EventEmitter<any>();

  constructor(
    private menuService: MenuService,
    private messageService: MessageService,
    //private broadcastService: MsalBroadcastService
    private route: Router
  ) {}
  
  async ngOnInit(): Promise<void> {
    // this.broadcastService.inProgress$
    //   .pipe(
    //     filter(
    //       (status: InteractionStatus) => status === InteractionStatus.None
    //     ),
    //     takeUntil(this._destroying$)
    //   )
    //   .subscribe(() => {
         this.getMenu();
    //   });

  }
  getMenu() {
    try {      
      this.menuService.getMenu().subscribe({
        next: (menu: any) => {
          this.Items = menu;
          this.organizeMenuItems(this.Items);
          this.setClickEventListener();
        },
        error: (error: any) => {
          console.error(error);
          this.messageService.add({
            severity: 'error',
            summary: error.error.Value.Title,
            detail: error.error.Value.Detail,
          });
        },
      });
    } catch (e) {
      console.error(e);
    }
  }

  organizeMenuItems(items: any[]) {
    items?.forEach((item) => {
      if (item.items?.length == 0) {
        delete item.items;
      } else {
        this.organizeMenuItems(item.items);
      }
    });
  }
  setClickEventListener()
  {
    const menuItems = document.querySelectorAll(Constants.ELEMENTS_TAGS.panelMenu);
    menuItems.forEach(el => el.addEventListener('click', event => {
      this.removeActiveClassFromAllElements();
      this.addActiveClassToTarget(event);
    }))
  }  
  removeActiveClassFromAllElements()
  {
    const activeItems = document.querySelectorAll(Constants.ELEMENTS_TAGS.linkItemActive);
    activeItems.forEach(ai => {
      ai.classList.remove(Constants.ELEMENTS_TAGS.linkItemActive)
    });
  }  
  addActiveClassToTarget(event: Event)
  {
    // var target = event.target as HTMLElement
    //   if (target instanceof HTMLSpanElement)
    //     target.parentElement.classList.add(Constants.ELEMENTS_TAGS.linkItemActive);
    //   else
    //     target.classList.add(Constants.ELEMENTS_TAGS.linkItemActive);
  }
  toggleMenu(){
    this.hidden = !this.hidden;
    this.icon = this.hidden ? this.iconExpande : this.iconCollapse;
    this.MenuHideEvent.emit(this.hidden);
  }
}
