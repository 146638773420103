import { Injectable } from '@angular/core';
import { ColDef } from 'ag-grid-enterprise';
import { ModelLibrary } from 'src/app/shared/models/model-library';
import { Constants } from 'src/app/shared/utils/constants';

@Injectable({
  providedIn: 'root',
})

export class InverseModelingModelsColDef {

  public getColumnsDefinition(swModels: ModelLibrary[], aquaticModels: ModelLibrary[], terrestrialModels: ModelLibrary[], isProjectOwnershipValid: boolean): ColDef[] {
    return [
      {
        headerName: 'Compound',
        field: 'metaboliteName',
        width: 250,
        filter: 'agSetColumnFilter',
        wrapText: true,
        resizable: true,
        sortable: true,
        editable: false,
      },
      {
        headerName: 'SW Models',
        field: Constants.COMPARTMENTS.SURFACE_WATER.replace(" ","").toLowerCase(),
        width: 250,
        cellRenderer: 'listBoxRenderer',
        cellRendererParams: {
          options: swModels,
          optionValue: 'modelPk',
          optionLabel: 'nameVersion',
          checkbox: true,
          filter: false,
          multiple: true,
          disabled: !isProjectOwnershipValid,
          validateModel: true,
          compartment: Constants.COMPARTMENTS.SURFACE_WATER.replace(" ","").toLowerCase(),
        },
        filter: 'agSetColumnFilter',
        wrapText: true,
        resizable: true,
        sortable: true,
        editable: false,
      },
      {
        headerName: 'Aquatic',
        field: 'aquatic',
        width: 250,
        cellRenderer: 'listBoxRenderer',
        cellRendererParams: {
          options: aquaticModels,
          optionValue: 'name',
          optionLabel: 'name',
          checkbox: true,
          filter: false,
          multiple: true,
          disabled: !isProjectOwnershipValid,
          validateModel: true,
          compartment: Constants.COMPARTMENTS.AQUATIC.replace(" ","").toLowerCase(),
        },
        filter: 'agSetColumnFilter',
        wrapText: true,
        resizable: true,
        sortable: true,
        editable: false,
      },
      {
        headerName: 'Terrestrial',
        field: 'terrestrial',
        width: 250,
        cellRenderer: 'listBoxRenderer',
        cellRendererParams: {
          options: terrestrialModels,
          optionValue: 'name',
          optionLabel: 'name',
          checkbox: true,
          filter: false,
          multiple: true,
          disabled: !isProjectOwnershipValid,
          validateModel: true,
          compartment:  Constants.COMPARTMENTS.TERRESTRIAL.replace(" ","").toLowerCase(),
        },
        filter: 'agSetColumnFilter',
        wrapText: true,
        resizable: true,
        sortable: true,
        editable: false,
      }
    
    ];
  }
}
