<div class="grid">
  <div class="col-12 mt2">
    <app-button [outlined]="true" severity="{{activeButtonColor}}" label="Active Ingredient"
      (onClickEvent)="onActiveIngredientClick()"></app-button>
    <app-button [outlined]="true" severity="{{metaboliteButtonColor}}" label="Metabolites"
      (onClickEvent)="onMetaboliteClick()"></app-button>
    <app-dropdown [filter]="true" [options]="metabolitesOptions" optionLabel="metaboliteName"
      placeholder="Select Metabolite" *ngIf="metaboliteSelected"
      (onChangeEvent)="getSelectedMetabolite($event)"></app-dropdown>
    <p-checkbox *ngIf="selectedMetabolitePkValue !== 0" [ngClass]="isProjectOwnershipValid? '': 'disabled'"  label="N/A" name="N/A" [binary]="true" [(ngModel)]="excludeMetaboliteFromAI"  (onChange) = "onChangeExcludeMetabolite($event)" />
  </div>
</div>