

<div class="grid">
  <div class="col-12">
    <app-endpoint-dataset [isProjectOwnershipValid] ="isProjectOwnershipValid" [subTabMenuService]="subTabMenuService" [menuService]="menuService" [selectedProject]="selectedProject" (datasetData)="getDatasetDataOutput($event)" (showDetail)="showDetailOutput($event)" [environmentalAssessment]="environmentalAssessment" [isSubstanceProperties]="isSubstanceProperties"></app-endpoint-dataset>
  </div>
  <div *ngIf="showDetail && !isLoading" class="col-12 ml-2">
    <div class="grid">
      <div class="col-6 mt-4 mb-3">
        <app-eea-endpoints-select-compound [isProjectOwnershipValid] ="isProjectOwnershipValid" [metabolitesOptions]="metabolitesOptions" [compartment]="compartment" [moleculePk]="moleculePk" [projectPk]="projectPk" (selectedMetabolitePk)="getSelectedMetabolite($event)" (selectedActiveIngredientPk)="getSelectedActiveIngredient($event)"></app-eea-endpoints-select-compound>
      </div>
      <div class="col-6 mt-3">
        <app-model-checkbox [isProjectOwnershipValid] ="isProjectOwnershipValid"  *ngIf="selectedCompound != undefined" [compartment]="compartment" [selectedProject]="selectedProject" [metabolitePk]="selectedMetabolitePk" (onModelOptionsChangedEvent)="getSelectedModels($event)"></app-model-checkbox>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="selectedCompound != undefined && !isLoading">
    <app-eea-endpoints-parameters [isProjectOwnershipValid] ="isProjectOwnershipValid" [subTabMenuService]="subTabMenuService" [menuService]="menuService"  [dataset]="dataset" [selectedProject]="selectedProject" [coreDefaultValues]="coreDefaultValues" [precursorOptions]="precursorOptions" [compartment]="compartment" [selectedModels]="selectedModels" [endpointParameterCompound]="selectedCompound"></app-eea-endpoints-parameters>
  </div>
</div>
<app-loading class="flex align-items-center justify-content-center" *ngIf="isLoading"></app-loading>
