<div class="grid flex">
    <div class="flex align-items-center justify-content-center">
        <app-button [icon]="'pi pi-save'" [size]="'small'" [outlined]="true" [severity]="'secondary'" (onClickEvent)="save()"
            [hidden]="!useSaveAction"></app-button>
    </div>


    <div class="flex align-items-center justify-content-center ml-2">
        <app-button [icon]="'pi pi-plus'" [size]="'small'" [outlined]="true" [severity]="'secondary'" (onClickEvent)="add()"
        [hidden]="!useAddAction" ></app-button>
    </div>
</div>