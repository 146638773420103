import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomTabMenuItem } from 'src/app/shared/models/custom-tab-menu-item';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { Constants } from 'src/app/shared/utils/constants';

@Injectable({
  providedIn: 'root'
})
export class EEALogicService extends TabMenuLogicService {

  override items = Constants.EXPOSURE_ASSESSMENT_STEPS;
  override saveRef = Constants.WIZARD_MENU_REF.Master;

  override activeItemSubject = new BehaviorSubject<CustomTabMenuItem> ({} as CustomTabMenuItem);
  override activeItem = this.activeItemSubject.asObservable();

  override activeIndexChangedSubject = new BehaviorSubject(false);
  override activeIndexChanged = this.activeIndexChangedSubject.asObservable();

  override successSubject = new BehaviorSubject(true);
  override success = this.successSubject.asObservable();

  public usedModelsSubject = new BehaviorSubject([] as string[]);
  private readonly usedModels = this.usedModelsSubject.asObservable();

  private isLoading = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoading.asObservable();

  updateIsLoading(newValue: boolean) {
    this.isLoading.next(newValue);
  }

  SetUsedModels(usedModels: string[]) {
    this.usedModelsSubject.next(usedModels);
  }
}
