import { Injectable } from "@angular/core";
import { TriggersEcotoxService } from "src/app/administrator/regulatory-models/triggers-ecotox/triggers-ecotox.service";
import { EraGapBeesBamsApiService } from "src/app/environmental-risk-assessment/era-gap/era-gap-bees/era-gap-bees-bams.api.service";
import { EndpointDatasetService } from "src/app/shared/components/endpoint-dataset/endpoint-dataset.service";
import { BeesForInputs, Chemical, RiskQuotientData, ToxicityData } from "src/app/shared/models/bees/bees-for-Inputs";


import { DataSet } from "src/app/shared/models/echo/data-set";
import { Molecule } from "src/app/shared/models/echo/molecule";
import { Endpoint } from "src/app/shared/models/endpoint";
import { Project } from "src/app/shared/models/project";
import { TriggerEcotox } from "src/app/shared/models/trigger-ecotox";
import { ActiveIngredientApiService } from "src/app/shared/services/echo/active-ingredient.api.service";
import { DataSetApiService } from "src/app/shared/services/echo/data-set.api.service";
import { Constants } from "src/app/shared/utils/constants";

@Injectable({
  providedIn: 'root'
})
export class EraRunRiskAssessmentBeesLogicService {



  constructor(private GapBeesBamsApiService: EraGapBeesBamsApiService,
    private datasetService: DataSetApiService,
    private activeIngredientApiService: ActiveIngredientApiService,
    private endpointService: EndpointDatasetService,
    private triggersEcotoxService: TriggersEcotoxService
  ) {
  }

  public async getGapBeesApplication(project: Project, taxonTriggerList: TriggerEcotox[]): Promise<BeesForInputs[]> {
    const beesForRuns: BeesForInputs[] = [];
    let runBees: BeesForInputs = {} as BeesForInputs;
    let activeIngredients: Chemical[] = [];
    let dataSet: DataSet[] = [];
    let compartmentPk: number = 0;
   await this.getCompartments().then((compartments: any) => {
      compartmentPk = compartments.find((x: any) => x.compartment === "Plant")?.endpointCompartmentPk!;
      runBees.compartmentPk = compartmentPk;
    });
  await  this.getMoleculeByMoleculePks(project.projectXCompoundXModel?.flatMap(x => x.MoleculePk!) ?? []).then((active: Molecule[]) => {
      activeIngredients = active.map((x: Molecule) => {
        const chemical: Chemical = {
          moleculePk: x.moleculePk,
          chemicalName: x.moleculeName
        };
        return chemical;
      });
      runBees.formulationName = project.formulation!;
      runBees.projectFullName = project.name!;
      runBees.IdProject= project.projectPk!;
    });

    let endPoint: DataSet[] = [];

    await this.getDataSetByProjectPk(project.projectPk!).then(async (dataSet: DataSet[]) => {
    await this.datasetService.getEndpointsByDataSetsByTaxonGroup(dataSet, Constants.TAXON_GROUPS.BEES).toPromise().then(async (data: DataSet[] | undefined) => {
        endPoint = data ?? [];
        endPoint?.forEach(async (x: DataSet) => {
          if (x.endpoints && x.endpoints.length > 0 && x.useInProject) {
            let active = activeIngredients.find((y: Chemical) => y.moleculePk === x.moleculePk);
            runBees = {
              actives: active! ,
              endPoints: this.getToxicityData(x.endpoints),
              dataSet: dataSet,
              projectFullName: project.name!,
              IdProject: project.projectPk!,
              formulationName: project.formulation!,
              compartmentPk: compartmentPk,
              riskQuotientData: this.getLOCData(x.endpoints, taxonTriggerList) 
            };
            beesForRuns.push(runBees);
            
          }
        });

      });

    });
    return beesForRuns;
  }

  

  async getCompartments(): Promise<any[]> {
    return new Promise<number[]>((resolve, reject) => {
      this.datasetService.getCompartments(Constants.CONST_COMPARTMENT).subscribe({
        next: (comparments: any) => {
          resolve(comparments);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  async getDataSetByProjectPk(projectPk: number): Promise<DataSet[]> {
    return new Promise<DataSet[]>((resolve, reject) => {
      this.endpointService.getDataSetsByProject(projectPk, Constants.ENVIRONMENTAL_ASSESSMENTS.ENVIRONMENTAL_RISK_ASSESSMENT).subscribe({
        next: (dataSets: DataSet[]) => {
          resolve(dataSets);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  getMoleculeByMoleculePks(activeIngredientsRates: number[]): Promise<Molecule[]> {
    return new Promise<Molecule[]>((resolve, reject) => {
      this.activeIngredientApiService.getMoleculeByMoleculePks(activeIngredientsRates).subscribe({
        next: (activeIngredients: Molecule[]) => {
          resolve(activeIngredients);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  getToxicityData(endPointData: DataSet[]): ToxicityData {
    const data: ToxicityData = {} as ToxicityData;
    endPointData?.forEach((ToxicityData: any) => {

      switch (ToxicityData.endpointValue) {
        case Constants.BEES_TYPES.AdultContactLD50:
          data.AdultContactLD50 = ToxicityData?.value;
          break;
        case Constants.BEES_TYPES.AdultOralLD50:
          data.AdultOralLD50 = ToxicityData?.value;
          break;
        case Constants.BEES_TYPES.LarvalLD50:
          data.LarvalLD50 = ToxicityData?.value;
          break;
        case Constants.BEES_TYPES.AdultOralNOAEL:
          data.AdultOralNOAEL = ToxicityData?.value;
          break;
        case Constants.BEES_TYPES.LarvalNOAEL:
          data.LarvalNOAEL = ToxicityData?.value;
          break;
      }

    });
    return data;
  }

  async runBees(beesForRuns: BeesForInputs[]): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (beesForRuns) {
        this.GapBeesBamsApiService.runBees(beesForRuns).subscribe({
          next: (data: any) => {
            resolve(data);
          },
          error: (error: Error) => {
            reject(error);
          }
        });
      }
    });
  }

  createProjectObject(selectProject:Project): Project {
    const project: Project = {
      projectPk: selectProject?.projectPk!,
      active: selectProject?.active!,
      deletable: selectProject?.deletable!,
      description: selectProject?.description!,
      formulationPk: selectProject?.formulationPk!,
      name: selectProject?.name!,
      createdBy: selectProject?.createdBy!,
      createdDate: selectProject?.createdDate!,
      status: selectProject?.status!,
    };
    return project;
  }

  DownloadBeesOutputFilesByProject(selectProject: Project): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.GapBeesBamsApiService.DownloadBeesOutputFilesByProject(selectProject).subscribe({
        next: (url: string) => {
          resolve(url);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });

  }

  getTaxonTriggers(): Promise<TriggerEcotox[]> {
    return new Promise<TriggerEcotox[]>((resolve, reject) => {
        this.triggersEcotoxService.getTaxonTriggerList().subscribe({
            next: (taxonTriggers: TriggerEcotox[]) => {
                resolve(taxonTriggers);
            },
            error: (error: Error) => {
                reject(error);
            }
        });
    });
}

  getRiskQuotientValue(taxonTriggerList: TriggerEcotox[], listed: boolean, taxonExposureDuration: number) : number{
    let riskQuotientValue: number = 0;

    let taxonTrigger = taxonTriggerList.find((x: TriggerEcotox) => x.id == taxonExposureDuration);
           
    if(taxonTrigger){
        riskQuotientValue = listed ? taxonTrigger.riskQuotientListed : taxonTrigger.riskQuotientNonListed;
    }

    return riskQuotientValue;
}

  getLOCData(endPointData: DataSet[], taxonTriggerList: TriggerEcotox[]): RiskQuotientData {
    const data: RiskQuotientData = {} as RiskQuotientData;
    endPointData?.forEach((ToxicityData: any) => {

      switch (ToxicityData.endpointValue) {
        case Constants.BEES_TYPES.AdultContactLD50:
          data.AdultContactLD50Listed = this.getRiskQuotientValue(taxonTriggerList, true, ToxicityData?.taxonExposureDuration);
          data.AdultContactLD50NoListed = this.getRiskQuotientValue(taxonTriggerList, false, ToxicityData?.taxonExposureDuration);
          break;
        case Constants.BEES_TYPES.AdultOralLD50:
          data.AdultOralLD50Listed = this.getRiskQuotientValue(taxonTriggerList, true, ToxicityData?.taxonExposureDuration);
          data.AdultOralLD50NoListed  = this.getRiskQuotientValue(taxonTriggerList, false, ToxicityData?.taxonExposureDuration);
          break;
        case Constants.BEES_TYPES.LarvalLD50:
          data.LarvalLD50Listed = this.getRiskQuotientValue(taxonTriggerList, true, ToxicityData?.taxonExposureDuration);
          data.LarvalLD50NoListed  = this.getRiskQuotientValue(taxonTriggerList, false, ToxicityData?.taxonExposureDuration);
          break;
        case Constants.BEES_TYPES.AdultOralNOAEL:
          data.AdultOralNOAELListed = this.getRiskQuotientValue(taxonTriggerList, true, ToxicityData?.taxonExposureDuration);
          data.AdultOralNOAELNoListed  = this.getRiskQuotientValue(taxonTriggerList, false, ToxicityData?.taxonExposureDuration);
          break;
        case Constants.BEES_TYPES.LarvalNOAEL:
          data.LarvalNOAELListed = this.getRiskQuotientValue(taxonTriggerList, true, ToxicityData?.taxonExposureDuration);
          data.LarvalNOAELNoListed  = this.getRiskQuotientValue(taxonTriggerList, false, ToxicityData?.taxonExposureDuration);
          break;
      }
    });
    return data;
  }
}