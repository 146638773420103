import { Component, Input, OnChanges, OnInit, SimpleChanges, } from '@angular/core';
import { Constants } from 'src/app/shared/utils/constants';
import { EEAGapSurfaceWaterColdef } from './eea-gap-surfacewater.coldef';
import { EEAGapApiService } from '../eea-gap.api.service';
import { EEAGapLogicService } from '../eea-gap.logic.service';
import { EEAGAPMenuLogicService } from '../eea-gap/eea-gap-menu.logic.service';
import { ApplicationDateSelection, ApplicationDateSurfaceWater } from 'src/app/shared/models/application-date';
import { ApplicationDateApiService } from 'src/app/shared/services/application-date.api.service';
import { CropList } from 'src/app/shared/models/crop-list';
import { EeaGapNonCoreCompartmentComponent } from '../eea-gap-non-core-compartment/eea-gap-non-core-compartment.component';
import { CropListMatching } from 'src/app/shared/models/crop-list-matching';
import { catchError, forkJoin } from 'rxjs';
import { ApplicationSchemeXApplicationWindow } from 'src/app/shared/models/application-scheme';
import { Utils } from 'src/app/shared/utils/utils';
import { RowNode } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { GapApplicationSchemeLogicService } from 'src/app/shared/services/gap-application-scheme.logic.service';

@Component({
  selector: 'app-eea-gap-surfacewater',
  templateUrl: '../eea-gap-non-core-compartment/eea-gap-non-core-compartment.component.html',
  styleUrls: ['./eea-gap-surfacewater.component.css']
})
export class EEAGapSurfaceWaterComponent extends EeaGapNonCoreCompartmentComponent implements OnInit, OnChanges {

  @Input() override isProjectOwnershipValid: boolean = false;
  visible: boolean = false;
  cropInterceptionsStep1And2: any[] = [];
  override cropListMatchings: CropListMatching[] = [];
  applicationDates: ApplicationDateSurfaceWater[] = [];
  override applicationDatesByCrop: ApplicationDateSurfaceWater[] = [];
  override compartment: string = Constants.COMPARTMENTS.SURFACE_WATER;

  constructor(
    private gapSWApiService: EEAGapApiService,
    private gapSurfaceWaterColdef: EEAGapSurfaceWaterColdef,
    private gapLogicSWService: EEAGapLogicService,
    EEAGAPMenuLogicService: EEAGAPMenuLogicService,
    private applicationDateSWApiService: ApplicationDateApiService,
    datepipe: DatePipe,
    gapApplicationSchemeLogicService: GapApplicationSchemeLogicService) {
    super(gapSWApiService, gapLogicSWService, EEAGAPMenuLogicService, applicationDateSWApiService, datepipe, gapApplicationSchemeLogicService);
  }


  override fillInitValues(changes: SimpleChanges): void {
    let promises = [
      this.gapLogicSWService.getCompartments(),
      this.gapLogicSWService.getRegionCountry(changes['selectedProject'].currentValue),
      this.gapLogicSWService.getAllCropList(),
      this.gapLogicSWService.getStep1n2Interceptions(),
      this.gapLogicSWService.getCropListMatchings(),
      this.gapLogicSWService.getCropInterceptions()
    ]
    Promise.all(promises)
      .then((results) => {
        this.compartmentList = results[0];
        this.geographiesList = results[1];
        this.cropList = results[2];
        this.cropInterceptionsStep1And2 = results[3];
        this.cropListMatchings = results[4];
        this.getApplicationSchemesByProjectAndCompartment(changes['selectedProject'].currentValue.projectPk, this.getCoreCompartment());
      })
  }

  override getColDefFilteredBySelectedModels() {
    if (!this.selectedProject) return;
    let models = this.gapLogicSWService.getModelsByCompartment(this.compartment, this.selectedProject);
    let columnsByModel = this.gapLogicSWService.getDisplayedColumnsByModel(models, this.selectedProject.geography!);

    const colDefParams = {
      bbch: this.gapLogicSWService.bbch,
      numerOfApplications: this.gapLogicSWService.numerOfApplications,
      applicationMethods: Object.keys(Constants.APPLICATION_METHOD_VALUES_SURFACEWATER).map(key => ({ value: key })),
      geographiesList: this.geographiesList,
      cropList: Utils.sortObject((this.selectedProject.geography == Constants.CROP_GEOGRAPHIES.USA) ? this.cropList?.filter((x: CropList) => x.Geography === Constants.CROP_GEOGRAPHIES.USA) : this.cropList, 'CropName'),
      geographySelected: this.selectedProject.geography,
      cropListMatching: this.cropListMatchings,
      selectedModels: models
    };
    const columnsDefinition = this.isInverseModeling
      ? this.gapSurfaceWaterColdef.getInverseColumnsDefinition(colDefParams, this.selectedProject, this.isProjectOwnershipValid)
      : this.gapSurfaceWaterColdef.getColumnsDefinition(colDefParams, this.isProjectOwnershipValid);

    const detailsColumnDef = this.gapSurfaceWaterColdef.configureDetailGrid(this.isProjectOwnershipValid);

    let columnsToShow = [...Constants.GAP_CORE_FIELDS, ...new Set(columnsByModel), ...this.fixedColumns];
    if (this.isInverseModeling) columnsToShow = [...columnsToShow, ...Constants.GAP_CORE_INVERSE_MODELING_FIELDS];
    return this.gapLogicSWService.getFilteredColumns(columnsToShow, columnsDefinition);
  }

  override onClickViewButton(event: any) {
    this.applicationDatesByCrop = [];
    this.selectedApplicationWindowRow = event.rowNode;
    this.applicationScheme = event.rowNode.data;

    let gapCropName = this.cropList?.find((x: CropList) => x.CropListPk === this.selectedApplicationWindowRow?.data?.cropAppDatePk)?.CropName!;
    if (this.selectedApplicationWindowRow?.data?.cropAppDatePk && this.selectedApplicationWindowRow?.data?.bbchEarliest) {
      let promises = [
        this.gapLogicSWService.getApplicationDatesSurfaceWaterByCropListPkAndBBCH(this.selectedApplicationWindowRow?.data?.cropAppDatePk, Number(this.selectedApplicationWindowRow?.data?.bbchEarliest)),
      ]
      Promise.all(promises)
        .then((results) => {
          this.applicationDates = results[0];
          if (gapCropName && Constants.GAP_CROPS_WITHOUT_APP_DATES.includes(gapCropName ?? '')) {
            this.applicationDatesByCrop = this.applicationDates.filter((x: any) => Constants.GAP_CROP_SCENARIOS_WITHOUT_APP_DATES[gapCropName].includes(x.location ?? ''));
          }
          else {
            this.applicationDatesByCrop = this.applicationDates.filter((x: ApplicationDateSurfaceWater) => x.beginWindow != null || x.endWindow != null);;
          }

          this.selectedApplicationWindowRow!.data.cropWithoutAppDates = false;
          this.getScenariosWithoutCropListPk();
          this.applicationDatesByCrop?.forEach((appDate: any) => {
            let value = event?.data?.applicationSchemeXApplicationWindow?.find((x: any) => x.applicationDateSurfaceWaterPk === appDate.applicationDateSurfaceWaterPk);
            if (value) {
              appDate.beginWindow = value.firstDate;
              appDate.endWindow = Utils.GetEndDateByApplication(value.firstDate, this.selectedApplicationWindowRow?.data.applicationInterval, this.selectedApplicationWindowRow?.data.numberOfApplications); //value.endDate;
              appDate.min = value.min;
              appDate.max = value.max;
              appDate.increment = value.increment;
            }
            else if (gapCropName && Constants.GAP_CROPS_WITHOUT_APP_DATES.includes(gapCropName ?? '')) {
              appDate.beginWindow = this.datepipe.transform(this.gapLogicSWService.getNonLeapDate(), Constants.SERVICE_DATE_FORMAT)!;
              appDate.endWindow = this.datepipe.transform(this.gapLogicSWService.getNonLeapDate(), Constants.SERVICE_DATE_FORMAT)!;
            }
          });
        });
    }
    this.applicationDialog.showDialog(event.rowNode, this.compartment);
  }

  override getScenariosWithoutCropListPk() {
    let gapCrop = this.selectedApplicationWindowRow?.data?.cropAppDatePk;
    if (gapCrop) {
      let gapCropName = this.cropList?.find((x: CropList) => x.CropListPk === gapCrop)?.CropName;
      if (gapCropName && Constants.GAP_CROPS_WITHOUT_APP_DATES.includes(gapCropName ?? '')) {
        let gapCropScenarios: string[] = Constants.GAP_CROP_SCENARIOS_WITHOUT_APP_DATES[gapCropName];
        let scenarios = this.applicationDates.filter((x: ApplicationDateSurfaceWater) => x.cropListPk == gapCrop);
        this.selectedApplicationWindowRow!.data.cropWithoutAppDates = Constants.GAP_CROPS_WITHOUT_APP_DATES.includes(gapCropName ?? '');
        this.applicationDatesByCrop = scenarios;
      }
    }
  }

  override onSaveApplicationDates(selectedAppDates: ApplicationDateSelection): void {
    let currentDates = selectedAppDates.parentRowNode.data.applicationSchemeXApplicationWindow;
    if (currentDates?.length !== selectedAppDates.selectedRows?.length) {
      this.createTransactionForApplicationDates(selectedAppDates);
    } else {
      for (const selectedDated of selectedAppDates.selectedRows) {
        const existAppDate = currentDates.some((currentDate: any) =>
          currentDate.applicationDateSurfaceWaterPk === selectedDated.data.applicationDateSurfaceWaterPk &&
          currentDate.firstDate === selectedDated.data.beginWindow &&
          currentDate.endDate === selectedDated.data.endWindow &&
          currentDate.min === selectedDated.data.min &&
          currentDate.max === selectedDated.data.max &&
          currentDate.increment === selectedDated.data.increment
        );
        if (!existAppDate) {
          this.createTransactionForApplicationDates(selectedAppDates);
          break;
        }
      }
    }
  }

  override getApplicationSchemesByProjectAndCompartment(projectPk: number, compartmentPk: number) {
    if (projectPk != 0) {
      this.loading = true;
      const detailsColumnDef = this.gapSurfaceWaterColdef.configureDetailGrid(this.isProjectOwnershipValid);
      forkJoin({
        applicationSchemes: this.gapSWApiService.getApplicationSchemesByProjectAndCompartment(projectPk, compartmentPk),
      })
        .pipe(
          catchError((error) => {
            console.error(error);
            this.loading = false;
            return [];
          })
        )
        .subscribe({
          next: ({ applicationSchemes }) => {
            if (!this.selectedProject) return;
            this.gapLogicSWService.addActiveIngredientsRatesToGrid(applicationSchemes, this.getColDefFilteredBySelectedModels(), this.isInverseModeling, this.selectedProject, this.isProjectOwnershipValid, this.compartment).then(() => {
              this.gapLogicSWService.addActiveIngredientsRatesToDetailGrid(this.gapLogicSWService.rowData, detailsColumnDef, this.isInverseModeling, this.selectedProject!, this.isProjectOwnershipValid, this.compartment);
              this.rowData = this.gapLogicSWService.rowData;
              this.rowData.forEach((x: any) => {
                let selectedModels = (this.selectedProject?.projectXCompoundXModel?.map(model => model?.ModelName) ?? []) as string[];
                let interceptionModel = Utils.getFirstMatchBetweenTwoArrays(Constants.MODELS_WITH_INTERCEPTIONS, selectedModels);
                x.selectedInterceptionModel = interceptionModel;
                if (Utils.isEmptyValue(x.cropInterception))
                  this.gapLogicSWService.setCropInterceptionByCropAndBBCH(x, this.compartment);
                this.gapLogicSWService.setCropCoverage(x);
              });
              this.columsDef = this.gapLogicSWService.columsDef;
              this.detailsColumsDef = this.gapLogicSWService.detailsColumnDef;
              this.refreshSelectedValuesTimeout();
              this.setLoadingState(false);
            });
          },
        });
    }
  }

  override onDropDownSelectionChanged(event: any) {
    event.row.geographies = event.row.applicationSchemeXGeography?.length > 0 ? this.geographiesList.filter((x: any) => event.row.applicationSchemeXGeography.map((y: any) => y.geographyPk).includes(x.key)) : [];
    if (event.field === Constants.GAP_FIELD_NAMES.INTERCEPTION_PK || event.field === Constants.GAP_FIELD_NAMES.DRIFT_INTERCEPTION_PK || event.field === Constants.GAP_FIELD_NAMES.BBCH) {
      let selectedModels = (this.selectedProject?.projectXCompoundXModel?.map(model => model?.ModelName) ?? []) as string[];
      let interceptionModel = Utils.getFirstMatchBetweenTwoArrays(Constants.MODELS_WITH_INTERCEPTIONS, selectedModels);
      event.row.selectedInterceptionModel = interceptionModel;
      switch (event.field) {
        case Constants.GAP_FIELD_NAMES.DRIFT_INTERCEPTION_PK:
          event.row.cropDriftInterceptionPk = event.value;
          break;
        case Constants.GAP_FIELD_NAMES.INTERCEPTION_PK:
          event.row.cropInterceptionPk = event.value;
          break;
        default:
          break;
      }
      this.gapLogicSWService.setCropInterceptionByCropAndBBCH(event.row, this.compartment);
    }
    else if (event.field === Constants.GAP_FIELD_NAMES.APPLICATION_METHOD) {
      this.grid.gridApi.redrawRows();
    }
    if (event.field === Constants.GAP_FIELD_NAMES.CROP_APP_DATE_PK || event.field === Constants.GAP_FIELD_NAMES.BBCH) {
      this.saveAppWindowsAfterLoad(this.grid.gridApi.getRowNode(event.id));
    }
    if (event.field === Constants.GAP_FIELD_NAMES.APPLICATION_NUMBER) {
      if (event.value === 1) {
        event.row.applicationInterval = 1;
        event.row.hasVariableRates = false;
      }
      else {
        event.row.hasVariableRates = true;
      }
      this.gapLogicSWService.setInnerGridValues(event, this.grid);
      this.grid.gridApi.redrawRows();
    }
    if (event.field === Constants.GAP_FIELD_NAMES.HAS_VARIABLE_RATES) {
      if (!event.value) {
        event.row.isExpanded = false;
        this.gapLogicSWService.toggleDetailGrid(event, this.grid);
      }
      this.grid.gridApi.redrawRows();
    }
    this.grid.gridApi.refreshCells();
  }

  override createTransactionForApplicationDates(selectedAppDates: ApplicationDateSelection): void {
    let dates = selectedAppDates.selectedRows.map((x: any) => {
      let object: ApplicationSchemeXApplicationWindow = {
        applicationDateSurfaceWaterPk: x.data.applicationDateSurfaceWaterPk,
        firstDate: this.gapLogicSWService.transformLeapDate(x.data.beginWindow),
        endDate: this.gapLogicSWService.transformLeapDate(new Date(Utils.GetEndDateByApplication(x.data.beginWindow!, selectedAppDates.parentRowNode.data?.applicationInterval, selectedAppDates.parentRowNode.data?.numberOfApplications))),
        min: x.data.min,
        max: x.data.max,
        increment: x.data.increment,
      }
      return object;
    })
    let parentRowNode = this.grid.gridApi.getRowNode(selectedAppDates.parentRowNode.id);
    let oldvalue = parentRowNode.data.applicationSchemeXApplicationWindow;
    parentRowNode.data.applicationSchemeXApplicationWindow = dates;
    this.grid.CreateTransaction(parentRowNode.id, parentRowNode.id, oldvalue, parentRowNode.data);
  }

  override onFirstDataRendered(params: any): void {
    if (params?.type === Constants.GRID_EVENTS.FIRST_DATA_RENDERED) {
      this.applicationDatesByCrop = [];
      this.grid.gridApi.forEachNode((node: RowNode) => {
        if (node?.data?.coreApplicationSchemePk) {
          node!.data.reloadCore = this.validateCoreCropPK(node, this.compartment, this.selectedProject?.geography!, this.cropListMatchings);
        }
        if (node?.data?.applicationSchemeXApplicationWindow?.length == 0) {
          this.saveAppWindowsAfterLoad(node);
        }
        if (node?.data?.numberOfApplications === 1) {
          node!.data.applicationInterval = 1;
          node!.data.hasVariableRates = false;
        }
      });
      this.grid.gridApi.redrawRows();
      this.grid.gridApi.forEachNode((node: RowNode) => { node!.data.reloadCore = false; });
    }
  }

  saveAppWindowsAfterLoad(node: RowNode): void {
    let gapCropPk: number | undefined = node.data?.cropAppDatePk;
    let gapCropName: string = "";

    if (Utils.isEmptyValue(gapCropPk)) {
      gapCropPk = this.getCropValueMatchings(node);
    }
    gapCropName = this.cropList?.find((x: CropList) => x.CropListPk === gapCropPk)?.CropName!;

    if (gapCropPk && node.data?.bbchEarliest) {
      let promises = [
        this.gapLogicSWService.getApplicationDatesSurfaceWaterByCropListPkAndBBCH(gapCropPk, Number(node.data?.bbchEarliest)),
      ]
      Promise.all(promises)
        .then((results) => {
          let appDates: any[] = [];
          this.applicationDates = results[0];

          if (gapCropName && Constants.GAP_CROPS_WITHOUT_APP_DATES.includes(gapCropName ?? '')) {
            this.applicationDatesByCrop = this.applicationDates.filter((x: any) => Constants.GAP_CROP_SCENARIOS_WITHOUT_APP_DATES[gapCropName].includes(x.location ?? ''));
            this.applicationDatesByCrop?.forEach((appDate: ApplicationDateSurfaceWater) => {
              appDate.beginWindow = this.datepipe.transform(this.gapLogicSWService.getNonLeapDate(), Constants.SERVICE_DATE_FORMAT)!;
              appDate.beginWindowJulian = Utils.getDayOfYear(this.gapLogicSWService.getNonLeapDate());
              appDate.endWindow = this.datepipe.transform(new Date(Utils.GetEndDateByApplication(appDate.beginWindow!, node.data?.applicationInterval, node.data?.numberOfApplications)), Constants.SERVICE_DATE_FORMAT)!;
              appDate.endWindowJulian = Utils.getDayOfYear(new Date(Utils.GetEndDateByApplication(appDate.beginWindow!, node.data?.applicationInterval, node.data?.numberOfApplications)));
              appDate.emergencyDate = this.datepipe.transform(new Date(1999, 0, 1), Constants.SERVICE_DATE_FORMAT)!;
              appDate.harvestDate = this.datepipe.transform(new Date(1999, 0, 31), Constants.SERVICE_DATE_FORMAT)!;
              appDates.push({ data: appDate });
            });
          }
          else {
            this.applicationDatesByCrop = this.applicationDates.filter((x: ApplicationDateSurfaceWater) => x.beginWindow != null || x.endWindow != null);
            this.applicationDatesByCrop?.forEach((appDate: ApplicationDateSurfaceWater) => {
              if (this.selectedModels.includes(Constants.MODELS.SWASH) || this.selectedModels.includes(Constants.MODELS.SWAN)) {
                const currentYear = new Date(2001, 0, 1).getFullYear();
                appDate.endWindow = Utils.GetEndDateByApplication(appDate.beginWindow!, node.data?.applicationInterval, node.data?.numberOfApplications);
                appDate.beginWindow = appDate?.beginWindow!.replace(/(\d{4})/, currentYear.toString());
                if (appDate?.endWindow)
                  appDate.endWindow = appDate?.endWindow!.replace(/(\d{4})/, currentYear.toString());
              }
              appDates.push({ data: appDate });
            });
          }

          if (appDates.length > 0) {
            this.onSaveApplicationDates({ selectedRows: appDates, parentRowNode: node });
          }
        });
    }
  }
}
