import { Component, DestroyRef, EventEmitter, OnInit, Output, ViewChild, inject } from '@angular/core';
import { Project } from 'src/app/shared/models/project';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { OrderByPipe } from 'src/app/shared/pipes/order-by.pipe';
import { take } from 'rxjs';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { EndpointDatasetLogicService } from 'src/app/shared/components/endpoint-dataset/endpoint-dataset.logic.service';
import { DataSetApiService } from 'src/app/shared/services/echo/data-set.api.service';
import { Utils } from 'src/app/shared/utils/utils';
import { Constants } from 'src/app/shared/utils/constants';
import { ERACopyProjectDialogColDef } from './era-copy-project-dialog.coldef';
import { GridOptions, RowSelectedEvent } from 'ag-grid-community';

@Component({
  selector: 'app-era-copy-project-dialog',
  templateUrl: './era-copy-project-dialog.component.html',
  styleUrls: ['./era-copy-project-dialog.component.css']
})

export class ERACopyProjectDialogComponent implements OnInit {

  @Output() onClickSaveButtonEvent = new EventEmitter<any>();

  visible: boolean = false;
  loading: boolean = false;
  selectedProjectCopy?: Project;
  filteredProjectsCopy: Project[] = [];
  projectsCopy: Project[] = [];
  myProjectsFilter: boolean = false;
  createdBy: string = '';
  projectPk?: number;
  rowData: any[] = [];
  rowHeight: number = 45;
  public columnDefs: any[] = [];
  style: string = 'width: auto; height: 355px; text-align: left; z-index:-1;font-size:10px; color: #656565;';
  showDataSetGrid:  boolean = false;
  public blockUi: boolean = false;
  public disableButton: boolean = false;
  isLoading!: boolean;

  @ViewChild('grid') grid!: GridComponent;

  constructor(public projectApiService: SelectedProjectApiService, 
              private orderByPipe: OrderByPipe,
              private eRACopyProjectDialogColDef: ERACopyProjectDialogColDef,
              private readonly logicService: EndpointDatasetLogicService,
              private dataSetApiService: DataSetApiService) { }

  ngOnInit() { }

  async GetProject(): Promise<void> {
    this.projectApiService.projects
    .pipe(take(1))
    .subscribe((projects: Project[]) => {
      this.projectsCopy = projects;
      this.projectsFilter(false);
    });
    await this.projectApiService.getProjects('NA');
    this.loading = false;
  }  

  async projectsFilter(state: boolean): Promise<void> {
    this.myProjectsFilter = state;
    this.filteredProjectsCopy = this.myProjectsFilter
      ? this.orderByPipe.transform([...this.projectsCopy.filter((p: Project) => p.createdBy === this.createdBy)], 'name')
      : this.orderByPipe.transform([...this.projectsCopy], 'name');
  }

  showDialog(visiblePopUp: boolean, projectPk?: number) {
    this.visible = visiblePopUp;
    this.projectPk = projectPk;
    this.GetProject();
  }

  onSelectionChanged({ originalEvent, value }: DropdownChangeEvent) {
    if (originalEvent && value) {
      this.selectedProjectCopy = value;
      this.getGridData();
      return;
    }
    this.selectedProjectCopy = undefined;
  }

  closeDialog() {
    if (this.selectedProjectCopy?.projectPk != undefined) {
      this.onClickSaveButtonEvent.emit({ projectPkCopy: this.selectedProjectCopy?.projectPk, datasetList: this.grid.gridApi.getSelectedRows() });
      this.visible = false;
      this.selectedProjectCopy=undefined;    
      this.showGrid(false);
    }
  }

  close()
  {
    this.selectedProjectCopy=undefined;   
    this.showDataSetGrid = false;  
  }

  getGridData() {    
    this.loading = true;
    this.dataSetApiService.getAllDatasetByProjectPk(this.selectedProjectCopy?.projectPk!)
    .subscribe((data: any) => {
      this.rowData = data;
      this.columnDefs = this.eRACopyProjectDialogColDef.getColumnsDefinition();
      this.showGrid(true);    
      this.loading = false;  
    })
  }

  showGrid(value: boolean){
    this.showDataSetGrid = value;
  }

  onGridSelectionChanged(event: any){
    if(event.row.createCopyOrReference == Constants.COPY_PROJECT_CREATE_VALUES.COPY){
      event.row.description = null;
      this.disableControls(true);
    }
    
    this.grid?.gridApi.redrawRows();
  }

  public onBlurInputText({row} : {row:any}): void {
    this.checkIfTheDatasetIsValid(row.description, row.moleculePk, row.dataSetPk);
  }

  private async checkIfTheDatasetIsValid(datasetName: string | undefined, moleculePk: number | undefined, datasetPk: number | undefined): Promise<void> {
    this.disableControls(true);
    const invalidRows = this.rowData.some((row) =>
        row.description == undefined ||
        row.description == '' ||
        row.moleculePk == -1 ||
        row.moleculePk == undefined);

    const transactionOcurrences = this.logicService.getTransactionOccurrences(this.rowData);
    const duplicateDatasetsInTransaction = this.logicService.duplicateDataInRecord(transactionOcurrences);
    datasetPk = datasetPk ?? -1;

    let existsDatasetProjectPk: number = -1;
    
    if (datasetName != undefined && datasetName != '' && moleculePk != undefined) {
      this.blockUi = true;
      existsDatasetProjectPk = await this.logicService.existsDatasetByName(datasetName, moleculePk, datasetPk);
      this.blockUi = false;

      if (duplicateDatasetsInTransaction)
        Utils.showErrorMessage(
          'The Dataset name already exists on selected active ingredient.',
          'Please use another name'
        );
      else if (existsDatasetProjectPk > 0){
        this.showExistingDatasetMessage(existsDatasetProjectPk);
        this.disableControls(true);
      }
    }

    if (
      invalidRows ||
      existsDatasetProjectPk > 0 ||
      duplicateDatasetsInTransaction
    )
      this.disableControls(true);
    else this.disableControls(false);
  }

  private async showExistingDatasetMessage(
    existsDatasetProjectPk: number
  ): Promise<void> {
    const { name, description, projectPk, createdBy } =
      await this.logicService.getProjectByPk(existsDatasetProjectPk);
    Utils.showErrorMessageHtml(
      'The Dataset name already exists on selected active ingredient.',
      `<br> <b> Project:</b> ${name} - <b>${projectPk}</b>
     <br> <b> Created by:</b> ${createdBy}
     <br> <b> Description:</b> ${description || 'No description'}
     <br> <b><u>Please use another name</u></b>`
    );
  }

  private disableControls(enable: boolean): void {
    this.disableButton = enable;
  }

  onRowSelected(event: any){
    event.api.forEachNode((row: any) => {
      row.data.isEditable = row.selected;
    });

    this.grid?.gridApi.redrawRows();
  }
}
