<app-grid #grid   ng-if="!show" [rowData]="rowData"  
    [columnDefs]="columnsDef" [columnSizeMode]="columnSizeMode" [isMasterDetail]="true"
    [pagination]="true" 
    [useAddAction]="isProjectOwnershipValid" [useModels]="true"
    [rowData]="rowData"[useDeleteAction]="true"
    [compartment]="compartment" [selectedProject]="selectedProject"
    [useModels]="true"
    [detailAutoRowHeigth]="true" [pagination]="true" [detailColumnDefs]="detailsColumnDef"
    (listBoxSelectionChangeEvent)="geographiesListBoxSelectionChanged($event)"
    (deleteEvent)="deleteRow($event)" (AddDetailEvent)="onAddDetail($event)"
    (DeleteDetailEvent)="onDeleteDetails($event)" (selectionChangedEvent)="dropDownSelectionChanged($event)"
    (dropDownSelectionChangeEvent)="dropDownSelectionChanged($event)"
    (selectionChangedEvent)="setSelectionValues($event)"
    (onModelOptionsChangedEvent)="onModelOptionsChanged($event)" (addEvent)="newRowAdded($event)" (onBlurInputText)="onBlurInputText($event)"></app-grid>