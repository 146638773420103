import { ChangeDetectorRef, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { Message } from 'primeng/api';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { Project } from 'src/app/shared/models/project';
import { Constants } from 'src/app/shared/utils/constants';
import { EnviromentalRiskAssessmentService } from './environmental-risk-assessment.service';
import { DataSetApiService } from 'src/app/shared/services/echo/data-set.api.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';
import { CustomTabMenuItem } from 'src/app/shared/models/custom-tab-menu-item';
import { firstValueFrom, take } from 'rxjs';
import { ModelLibrary, ModelLibraryCollection } from 'src/app/shared/models/model-library';
import { Discipline } from 'src/app/shared/models/echo/discipline';
import { DisciplineApiService } from 'src/app/shared/services/echo/discipline.api.service';
import { ModelLibraryApiService } from 'src/app/shared/services/model-library.api.service';
import { OrderByPipe } from 'src/app/shared/pipes/order-by.pipe';
import { ERASetupColDef } from '../era-setup/era-setup-coldef';
import { Compartment } from 'src/app/shared/models/echo/compartment';
import { ColDef } from 'ag-grid-community';

@Component({
  selector: 'app-environmental-risk-assessment',
  templateUrl: './environmental-risk-assessment.component.html',
  styleUrls: ['./environmental-risk-assessment.component.css']
})
export class EnvironmentalRiskAssessmentComponent implements OnInit {
  activeItem!: CustomTabMenuItem;
  message!: Message;
  loading: boolean = false;
  nextIndex?: number;
  selectedProject?: Project;
  destroyRef = inject(DestroyRef)
  compartmentList: Compartment[] = [];
  isSetupValid: boolean = false;
  isProjectOwnershipValid: boolean = false;
  items: CustomTabMenuItem[] = [];
  compartments: string[] = [Constants.COMPARTMENTS.SURFACE_WATER.toLowerCase().replace(" ", ""), Constants.COMPARTMENTS.PLANT.toLowerCase()];
  disciplinePk: number = 0;
  modelsCollection: ModelLibraryCollection[] = [];
  columnDef: ColDef[] = [];
  selectedProjectStatus?: number;

  constructor(public ERAService: EnviromentalRiskAssessmentService,
    private selectedProjectApiService: SelectedProjectApiService,
    private dataSetService: DataSetApiService, private userLogicService: UserLogicService,
    private cdr: ChangeDetectorRef,
    private disciplineApiService: DisciplineApiService,
    private modelLibraryApiService: ModelLibraryApiService,
    private orderByPipe: OrderByPipe,
    private ERASetupColDef: ERASetupColDef,) { }

    async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.initCompartmentList();
    await this.getDiscipline();
    await this.initModels();
    this.initSubscribes();
  }

  onActiveIndexChange(index: number) {
    this.nextIndex = index;
    this.ERAService.setActiveIndexChanged(true, index);
  }

  async initCompartmentList(): Promise<void> {
    await firstValueFrom(this.dataSetService.getCompartments(Constants.CONST_COMPARTMENT).pipe(take(1))).then(((compartment: Compartment[]) => {
      this.compartmentList = compartment;
    }));
  }

  async getDiscipline(): Promise<void> {
    await firstValueFrom(this.disciplineApiService.getDisciplineByName(Constants.DISCIPLINE_IDENTITIES.ECOTOXICOLOGY).pipe(take(1))).then((discipline: Discipline) =>{
        this.disciplinePk = discipline?.disciplinePk ?? -1;
    });
  }

  async initModels() {
    const localCollection: ModelLibraryCollection[] = this.compartments.map(compartment => { return { name: compartment, library: [] }});
    let models: ModelLibrary[] = [];
    
    const aquaticModels = localCollection.find(x => x.name.toLowerCase() === Constants.COMPARTMENTS.SURFACE_WATER.replace(" ","").toLowerCase());
    const terrestrialModels = localCollection.find(x => x.name.toLowerCase() === Constants.COMPARTMENTS.PLANT.toLowerCase());

    await firstValueFrom(this.modelLibraryApiService.getModels()).then((library: ModelLibrary[]) => {
      models = library.filter(f => f.compartmentXModel?.length && f.compartmentXModel?.length >= 0 && f.disciplineXModel?.some(d => d == this.disciplinePk));
      if(aquaticModels) aquaticModels.library = this.orderByPipe.transform(models.filter(m => m.compartmentXModel?.includes(this.compartmentList?.find((x: Compartment) => x.compartment!.toLowerCase() === Constants.COMPARTMENTS.SURFACE_WATER.toLowerCase())?.endpointCompartmentPk!)), 'nameVersion');
      if(terrestrialModels) terrestrialModels.library = this.orderByPipe.transform(models.filter(m => m.compartmentXModel?.includes(this.compartmentList?.find(x => x.compartment!.toLowerCase() === Constants.COMPARTMENTS.PLANT.toLowerCase())?.endpointCompartmentPk!)), 'nameVersion');
    });

    this.modelsCollection = localCollection;
  }

  initSubscribes() {
    this.ERAService.activeItem.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(activeItem => {
      this.activeItem = activeItem;
    });

    this.selectedProjectApiService.selectedProject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((project?: Project) => {
      this.selectedProject = project;
      this.isProjectOwnershipValid = this.selectedProject ? this.userLogicService.verifyProjectOwnership(this.selectedProject?.createdBy) : true;
      this.initColumnDef();
      this.onActiveIndexChange(0);
    });

    this.selectedProjectApiService.selectedProjectStatus.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((status?: number) => {
      this.selectedProjectStatus = status;
    });

    this.ERAService.isLoading$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(isLoading => {
      this.loading = isLoading;
    });

    this.ERAService.success.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(success => {
      this.loading = false;
      if (success) {
        const nextItem = this.ERAService.items.find(i => i.index == this.nextIndex);
        if (nextItem)
          this.ERAService.setActiveItem(nextItem);
      }
    });    
  }

  initColumnDef() {
    const aquaticModels = this.modelsCollection.find(x => x.name.toLowerCase() === Constants.COMPARTMENTS.SURFACE_WATER.replace(" ","").toLowerCase())?.library;
    const terrestrialModels = this.modelsCollection.find(x => x.name.toLowerCase() === Constants.COMPARTMENTS.PLANT.toLowerCase())?.library;
    this.columnDef = this.ERASetupColDef.getColumnsDefinition(aquaticModels ?? [], terrestrialModels ?? [], this.isProjectOwnershipValid);
  }

  validateSetup(event: any) {
    const copyItems = [...this.ERAService.items];  
    copyItems[1].visible = event.isValidSetup;
    copyItems[2].visible = event.validSetupPlantModel;
    copyItems[3].visible = event.isValidAquaticModel;
    copyItems[4].visible = event.isValidSetup;
    this.ERAService.items = copyItems;
    this.isSetupValid = event.isValidSetup;
    this.cdr.detectChanges();
  }

  public getIsValidEraEndpoints(isValid: boolean): void {
    this.isSetupValid = isValid;
    this.cdr.detectChanges();
  }

  public getIsValidApplicationScheme(isValid: boolean): void {
    this.isSetupValid = isValid;
    this.cdr.detectChanges();
  }
}


