import { Injectable } from '@angular/core';
import { ButtonExpandGridRenderer } from '../../renderers/button-expand-grid/button-expand-grid.component';
import { DropdownListRenderer } from '../../renderers/dropdown-list/dropdown-list.component';
import { InputTextRenderer } from '../../renderers/input-text/input-text.component';
import { ImportDatasetRenderer } from '../../renderers/import-dataset/import-dataset.component';
import { EndpointDatasetLogicService } from './endpoint-dataset.logic.service';
import { StatusFlagRenderer } from '../../renderers/status-flag/status-flag.component';
import { DeleteButtonRenderer } from '../../renderers/delete-button/delete-button.component';
import { Constants } from '../../utils/constants';
import { Row } from './endpoint-dataset.model';
import { RowNode } from 'ag-grid-community';

@Injectable({
  providedIn: 'root',
})

export class EndpointDatasetColDefService {

  constructor(private readonly logicService: EndpointDatasetLogicService) {}

  public getColumnsDefinition(isProjectOwnershipValid: boolean){
    return [
      {
        colId: 'show',
        minWidth: 65,
        width: 65,
        cellRenderer: ButtonExpandGridRenderer,
        cellRendererParams: {
          context: this,
          dataComponent: this
        },
        type: 'rightAligned',
        editable: false
      },
      {
        headerName: 'Active Ingredient',
        field: 'moleculePk',
        minWidth: 363,
        type: 'select',
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          labelId: 'activeIngredient',
        },
        instance: this,
        filterable: true,
        headerCheckboxSelection: isProjectOwnershipValid,
        checkboxSelection: isProjectOwnershipValid,
        cellRenderer: DropdownListRenderer,
        cellRendererParams: {
          options: this.logicService.molecules,
          columnThatModifies: 'moleculePk',
          optionLabel: 'moleculeName',
          optionValue: 'moleculePk',
          disabled: !isProjectOwnershipValid,
          isRequired: true
        },
        editable: false,
      },
      {
        headerName: 'Data Set',
        field: 'description',
        minWidth: 563,
        cellRenderer: InputTextRenderer,
        cellRendererParams: {
          disabled: !isProjectOwnershipValid,
          isRequired: true
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          labelId: 'description',
        },
        editable: false,
        type: 'default',
        suppressToolPanel: true,
        suppressFiltersToolPanel: true,
        cellStyle: (params: { data: { automatic: any } }) => {
          return params.data.automatic ? { color: '#aaaaaa' } : '';
        },
      },
      {
        headerName: '',
        field: 'hasEndpoints',
        maxWidth: 40,
        cellRenderer: StatusFlagRenderer,
        cellRendererParams: {},
        editable: false,
        suppressColumnsToolPanel: true
      },
      {
        headerName: 'Import Dataset',
        colId: 'importDataset',
        type: 'select',
        minWidth: 253,
        suppressSizeToFit: true,
        suppressToolPanel: true,
        suppressFiltersToolPanel: true,
        editable: false,
        cellRenderer: ImportDatasetRenderer,
        cellRendererParams: {
          filterable: false,
          optionLabel: 'description',
          optionValue: 'dataSetPk',
          options: this.logicService.datasetsToImport,
          parent: this,
          selectedValue: 0,
          placeholder: 'Import Data Set',
          disabled: !isProjectOwnershipValid,
        },
        instance: this,
      },
      {
        colId: 'delete',
        width: 125,
        minWidth: 125,
        editable: false,
        cellRenderer: DeleteButtonRenderer,
        cellRendererParams: {
          disabled: !isProjectOwnershipValid
        },
        type: 'rightAligned'
      }
    ]
  }

  public getColumnsDefinitionSubstancePropertiesOPEX(isProjectOwnershipValid: boolean) {
    return [
      {
        colId: 'show',
        minWidth: 80,
        width: 80,
        cellRenderer: ButtonExpandGridRenderer,
        cellRendererParams: {
          context: this,
          dataComponent: this
        },
        type: 'rightAligned',
        editable: false
      },
      {
        headerCheckboxSelection: isProjectOwnershipValid,
        checkboxSelection: isProjectOwnershipValid,
        headerName: 'Calculator',
        field: 'calculatorGroup',
        width: 135,
        minWidth: 135,
        type: 'select',
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        filterable: true,
        cellRenderer: DropdownListRenderer,
        cellRendererParams: {
          options: Constants.CALCULATOR_GROUPS,
          columnThatModifies: 'calculatorGroup',
          optionLabel: 'name',
          optionValue: 'name',
          disabled: !isProjectOwnershipValid,
          isRequired: false,

        },
        editable: false,
      },
      {
        headerName: 'Active Ingredient',
        field: 'moleculePk',
        minWidth: 363,
        type: 'select',
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          labelId: 'activeIngredient',
        },
        instance: this,
        filterable: true,
        cellRenderer: DropdownListRenderer,
        cellRendererParams: {
          options: this.logicService.molecules,
          columnThatModifies: 'moleculePk',
          optionLabel: 'moleculeName',
          optionValue: 'moleculePk',
          disabled: !isProjectOwnershipValid,
          isRequired: true
        },
        editable: false,
      },
      {
        headerName: 'Data Set',
        field: 'description',
        width: 465,
        minWidth: 465,
        cellRenderer: InputTextRenderer,
        cellRendererParams: {
          disabled: !isProjectOwnershipValid,
          isRequired: true
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          labelId: 'description',
        },
        editable: false,
        type: 'default',
        suppressToolPanel: true,
        suppressFiltersToolPanel: true,
        cellStyle: (params: { data: { automatic: any } }) => {
          return params.data.automatic ? { color: '#aaaaaa' } : '';
        },
      },
      {
        headerName: '',
        field: 'hasEndpoints',
        maxWidth: 40,
        cellRenderer: StatusFlagRenderer,
        cellRendererParams: {},
        editable: false,
        suppressColumnsToolPanel: true
      },
      {
        headerName: 'Import Dataset',
        colId: 'importDataset',
        type: 'select',
        minWidth: 215,
        suppressSizeToFit: true,
        suppressToolPanel: true,
        suppressFiltersToolPanel: true,
        editable: false,
        cellRenderer: ImportDatasetRenderer,
        cellRendererParams: {
          filterable: false,
          optionLabel: 'description',
          optionValue: 'dataSetPk',
          options: this.logicService.datasetsToImport,
          parent: this,
          selectedValue: 0,
          placeholder: 'Import Data Set',
          disabled: !isProjectOwnershipValid,
        },
        instance: this,
      },
      {
        colId: 'delete',
        width: 125,
        minWidth: 125,
        editable: false,
        cellRenderer: DeleteButtonRenderer,
        cellRendererParams: {
          disabled: !isProjectOwnershipValid
        },
        type: 'rightAligned'
      }
    ]
  }

}
