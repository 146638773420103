import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
	selectedItem!: number;

	constructor(private http: HttpClient) { }

	getMenu(): Observable<any>
	{
		// const query = environment.BAMS_Api_URL + "api/menu";
		// return this.http.get(query)
		// .pipe(map((data: any) => data.Value));

		return of([
			{ label: 'Home', icon: 'pi pi-fw pi-home', routerLink: ['/home'] },
			{
				label: 'Administrator',
				icon: 'pi pi-fw pi-cog',
				items: [
					{
						label: 'Chemicals',
						items: [
							{ label: 'Active Ingredient', routerLink: ['//ECHO/ActiveIngredient'], showDDL: true, showActionButtons: true, headerText: 'Administrator / Active Ingredient' },
							{ label: 'Metabolite', routerLink: ['//ECHO/Metabolite'], showDDL: true, showActionButtons: true, headerText: 'Administrator / Metabolite' },
							{ label: 'Third-party and Scoping Formulations', routerLink: ['//ECHO/Formulation'], showDDL: true, showActionButtons: true, headerText: 'Administrator / Formulation' },
							{ label: 'Formulation Concentration', routerLink: ['//ECHO/FormulationConcentration'], showDDL: true, showActionButtons: true, headerText: 'Administrator / Formulation Concentration' }
						]
					},
					{
						label: 'Geography',
						items: [
							{ label: 'Country', routerLink: ['//ECHO/Country'], showDDL: true, showActionButtons: true, headerText: 'Administrator / Country' },
							{ label: 'Region', routerLink: ['//ECHO/Region'], showDDL: true, showActionButtons: true, headerText: 'Administrator / Region' },
							{ label: 'Agency', routerLink: ['//ECHO/Agency'], showDDL: true, showActionButtons: true, headerText: 'Administrator / Agency' }
						]
					},
					{
						label: 'Regulatory Models',
						items: [
							{ label: 'Model Library', routerLink: ['//ModelLibrary'], showDDL: true, showActionButtons: true, headerText: 'Administrator / Model Library' },
							{ label: 'Crop List', routerLink: ['//CropList'], showDDL: true, showActionButtons: true, headerText: 'Administrator / Crop List' },
							{ label: 'Crop Matching', routerLink: ['//CropMatching'], showDDL: true, showActionButtons: true, headerText: 'Administrator / Crop Matching' },
							{ label: 'Triggers for Ecotox RA', routerLink: ['//TriggersEcotox'], showDDL: true, showActionButtons: true, headerText: 'Administrator / Triggers for Ecotox RA' },
							{ label: 'Taxon and Endpoints Matching', routerLink: ['//TaxonEndpointsMatching'], showDDL: true, showActionButtons: true, headerText: 'Administrator / Taxon and Endpoints Matching' }

						]
					},
					{
						label: 'Access Control',
						items: [
							{ label: 'Users', routerLink: ['//ECHO/Users'], showDDL: true, showActionButtons: true, headerText: 'Administrator / Users' }
						]
					},
					{
						label: 'Other',
						items: [
							{ label: 'Value Builds', routerLink: ['//ECHO/DataFieldValue/BaMS'], showDDL: true, showActionButtons: true, headerText: 'Administrator / Value Builds' }
						]
					}
				],
			},
			{ label: 'Environmental Exposure Assessment', icon: 'pi pi-fw pi-globe', routerLink: ['/environmentalExposureAssessment'], showDDL: true, showActionButtons: true, headerText: 'Environmental Exposure Assessment' },
			{ label: 'Environmental Risk Assessment', icon: 'pi pi-fw pi-info-circle', routerLink: ['/environmentalRiskAssessment'], showDDL: true, showActionButtons: true, headerText: 'Environmental Risk Assessment' },
			{ label: 'Human Health Risk Assessment', icon: 'pi pi-fw pi-globe', routerLink: ['/humanHealthRiskAssessment'], showDDL: true, showActionButtons: true, headerText: 'Human Health Risk Assessment' },
			{ label: 'Inverse Modeling - Environmental Exposure Assessment', icon: 'pi pi-fw pi-globe', routerLink: ['/inverseEnvironmentalExposureAssessment'], showDDL: true, showActionButtons: true, headerText: 'Inverse Modeling - Environmental Exposure Assessment' },


		]);
	}
	setSelectedItem(pItem: any)  {
		this.selectedItem = pItem;
	}
	getSelectedItem() {
		return this.selectedItem;
	}
}
