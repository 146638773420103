import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Project } from 'src/app/shared/models/project';
import { EEAOutputProjectPelmoInputsUsedInCalculationColdef } from './eea-output-project-pelmo-inputs-used-in-calculation-coldef';
import { EEAOutputProjectInputsLogicService } from 'src/app/shared/services/out-put-project/eea-output-project-logic.service';
import { EEAOutputProjectInputsApiService } from 'src/app/shared/services/eea-output-project-inputs.api.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-eea-output-project-pelmo-run-inputs-inputs-use-in-calculation',
  templateUrl: './eea-output-project-pelmo-run-inputs-inputs-use-in-calculation.component.html',
  styleUrls: ['./eea-output-project-pelmo-run-inputs-inputs-use-in-calculation.component.css']
})
export class EeaOutputProjectPelmoRunInputsInputsUseInCalculationComponent implements OnInit, OnChanges {
  @Input() selectedProject?: Project;
  @Input() selectedModel?:string;
  columDef: any;
  loading: boolean = true;
  rowData: any;

  constructor(private columndefPelmo: EEAOutputProjectPelmoInputsUsedInCalculationColdef,
    private cdRef: ChangeDetectorRef,
    private readonly eeaOutputProjectInputsLogicService: EEAOutputProjectInputsLogicService,
    private readonly inputsUsedInCalculationsService: EEAOutputProjectInputsApiService
  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.columDef = this.columndefPelmo.getColumnsDefinition();
    if (this.selectedProject) {
      this.inputsUsedInCalculationsService.getRunInputsUseInCalculationByModel(this.selectedProject?.projectPk!,this.selectedModel!).subscribe(async result => {
        this.rowData = result.filter(item=> item.model == this.selectedModel);

        this.loading = false;
      });
      this.cdRef.detectChanges();
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.selectedProject) {
      const result = await firstValueFrom(this.inputsUsedInCalculationsService.getRunInputsUseInCalculationByModel(this.selectedProject?.projectPk!,this.selectedModel!));
      this.rowData = result;
      this.cdRef.detectChanges();
    }
    this.loading = false;
  }
}