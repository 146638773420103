export abstract class HHRAConstants {

    public static readonly EMPTY: string = '';
    public static readonly NOT_RELEVANT: string = 'Not relevant';
    public static readonly APPLICATION_METHOD: string = 'applicationMethod';
    public static readonly APPLICATION_EQUIPMENT: string = 'applicationEquipment';
    public static readonly BUFFER_STRIP: string = 'bufferStrip';
    public static readonly APPLICATION_CLOSED_CAB: string = 'applicationClosedCab';
    public static readonly UPWARD_SPRAYING: string = 'Upward spraying';
    public static readonly VEHICULE_MOUNTED: string = 'Vehicle-mounted';
    public static readonly DOWNWARD: string = 'downward';
    public static readonly UPWARD: string = 'upward';
    public static readonly DELETE: string = 'delete';

    public static readonly HUMAN_ASSESSMENTS = {
        HUMAN_HEALTH_RISK_ASSESSMENT: 'HHRA',
    };

    public static readonly HHRA_RELATED_FIELDS_INDEX = {
        CROP_TYPE: { index: 0, field: 'cropType' },
        INDOOR_OUTDOOR_APPLICATION: { index: 1, field: 'indoorOutdoorApplication' },
        APPLICATION_METHODS_SPRAY: { index: 2, field: 'applicationMethod' },
        APPLICATION_METHODS_GRANULES: { index: 3, field: 'applicationMethod' },
        APPLICATION_EQUIPMENT: { index: 4, field: 'applicationEquipment' },
        BUFFER_STRIP: { index: 5, field: 'bufferStrip' },
        SEASON: { index: 6, field: 'season' },
        DRIFT_REDUCTION: { index: 7, field: 'driftReduction' },
    };

    public static readonly HHRA_CROP_TYPES = [
        { index: 0, name: 'Field Crops' },
        { index: 1, name: 'Hops' },
        { index: 2, name: 'Viticulture' },
        { index: 3, name: 'Orchards' },
        { index: 4, name: 'Oil Fruits' },
        { index: 5, name: 'Cane fruit/High berries' },
        { index: 6, name: 'Low berries' },
        { index: 7, name: 'Low vegetables' },
        { index: 8, name: 'Low ornamentals' },
        { index: 9, name: 'High ornamentals' },
        { index: 10, name: 'Amenity grassland' },
        { index: 11, name: 'Agricultural grassland' },
        { index: 12, name: 'Bare arable land' },
        { index: 13, name: 'High vegetables' },
        { index: 14, name: 'Bare non arable land' },
        { index: 15, name: 'Strawberry indoor' },
        { index: 16, name: 'Strawberry outdoor' },
        { index: 17, name: 'Bare non arable land/railway track' },
    ];

    private static getHHRACropTypes(relations: number[]) {
        return this.HHRA_CROP_TYPES.filter(crop => relations.includes(crop.index));
    };

    public static readonly HHRA_CROP_ACTIVITIES = [
        { index: 0, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([12]) }], name: 'Contact with treated soil' },
        { index: 1, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([8, 9]) }], name: 'Cutting, sorting, bundling, carrying' },
        { index: 2, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([2]) }], name: 'Hand Harvesting' },
        { index: 3, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([0]) }], name: 'Hand Harvesting (only for sweetcorn)' },
        { index: 4, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([1]) }], name: 'Harvest' },
        { index: 5, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([7]) }], name: 'Harvest (Brassica only)' },
        { index: 6, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([0, 1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 13, 15, 16]) }], name: 'Inspection, Irrigation' },
        { index: 7, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([7]) }], name: 'Inspection, Irrigation (all)' },
        { index: 8, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([12]) }], name: 'Inspection without contact with treated soil' },
        { index: 9, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([7]) }], name: 'Maintenance (Brassica only)' },
        { index: 10, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([3]) }], name: 'Maintenance/thinning' },
        { index: 11, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([14, 17]) }], name: 'NA' },
        { index: 12, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([4, 6, 13, 16]) }], name: 'Reaching, picking' },
        { index: 13, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([7]) }], name: 'Reaching, picking (all except Brassica)' },
        { index: 14, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([7]) }], name: 'Removing bolting sugar beets' },
        { index: 15, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([3, 4, 5, 15]) }], name: 'Searching, reaching, picking' },
        { index: 16, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([10]) }], name: 'Turf harvesting, cutting and handling' },
    ];

    public static readonly HHRA_INDOOR_OUTDOOR_APPLICATION = [
        { index: 0, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([5, 6, 7, 8, 9, 13, 15]) }], name: 'Indoor' },
        { index: 1, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([5, 7, 8, 9, 13, 15]) }], name: 'Indoor, Dense' },
        { index: 2, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 17]) }], name: 'Outdoor' },
    ];

    private static getHHRAIndoorOutdoorApplications(relations: number[]) {
        return this.HHRA_INDOOR_OUTDOOR_APPLICATION.filter(application => relations.includes(application.index));
    }

    public static readonly HHRA_APPLICATION_METHODS_SPRAY = [
        { index: 0, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]) }], name: 'Downward spraying' },
        { index: 1, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([1, 2, 3, 4, 5, 9, 13, 15]) }], name: 'Upward spraying' },
    ];

    private static getHHRAApplicationMethodSpray(relations: number[]) {
        return this.HHRA_APPLICATION_METHODS_SPRAY.filter(application => relations.includes(application.index));
    }

    public static readonly HHRA_APPLICATION_METHODS_GRANULES = [
        { index: 0, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.INDOOR_OUTDOOR_APPLICATION, relations: this.getHHRAIndoorOutdoorApplications([2]) }], name: 'Broadcast' },
        { index: 1, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.INDOOR_OUTDOOR_APPLICATION, relations: this.getHHRAIndoorOutdoorApplications([2]) }], name: 'In furrow' },
        { index: 2, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.INDOOR_OUTDOOR_APPLICATION, relations: this.getHHRAIndoorOutdoorApplications([2]) }], name: 'Manual' },
        { index: 3, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.INDOOR_OUTDOOR_APPLICATION, relations: this.getHHRAIndoorOutdoorApplications([0, 1]) }], name: 'Application of granules' },
    ];

    private static getHHRAApplicationMethodGranules(relations: number[]) {
        return this.HHRA_APPLICATION_METHODS_GRANULES.filter(application => relations.includes(application.index));
    }

    public static readonly HHRA_APPLICATION_EQUIPMENT_SPRAY = [
        { index: 0, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 13, 14, 15, 16]) }, { field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.INDOOR_OUTDOOR_APPLICATION, relations: this.getHHRAIndoorOutdoorApplications([0, 1, 2]) }, { field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.APPLICATION_METHODS_SPRAY, relations: this.getHHRAApplicationMethodSpray([0, 1]) }], name: 'Manual-Hand held' },
        { index: 1, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 13, 14, 15, 16]) }, { field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.INDOOR_OUTDOOR_APPLICATION, relations: this.getHHRAIndoorOutdoorApplications([0, 1, 2]) }, { field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.APPLICATION_METHODS_SPRAY, relations: this.getHHRAApplicationMethodSpray([0, 1]) }], name: 'Manual-Knapsack' },
        { index: 2, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([0, 1, 2, 3, 4, 7, 8, 9, 10, 11, 12, 13, 14, 16, 17]) }, { field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.INDOOR_OUTDOOR_APPLICATION, relations: this.getHHRAIndoorOutdoorApplications([2]) }, { field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.APPLICATION_METHODS_SPRAY, relations: this.getHHRAApplicationMethodSpray([0, 1]) }], name: 'Vehicle-mounted' },
        { index: 3, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([5, 15]) }, { field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.INDOOR_OUTDOOR_APPLICATION, relations: this.getHHRAIndoorOutdoorApplications([0]) }, { field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.APPLICATION_METHODS_SPRAY, relations: this.getHHRAApplicationMethodSpray([1]) }], name: 'Manual-Trolley' },
    ];

    public static readonly HHRA_APPLICATION_EQUIPMENT_GRANULES = [
        { index: 0, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]) }, { field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.INDOOR_OUTDOOR_APPLICATION, relations: this.getHHRAIndoorOutdoorApplications([0, 2]) }, { field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.APPLICATION_METHODS_GRANULES, relations: this.getHHRAApplicationMethodGranules([2, 3]) }], name: 'Manual' },
        { index: 1, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 17]) }, { field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.INDOOR_OUTDOOR_APPLICATION, relations: this.getHHRAIndoorOutdoorApplications([2]) }, { field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.APPLICATION_METHODS_GRANULES, relations: this.getHHRAApplicationMethodGranules([0, 1]) }], name: 'Vehicle-mounted' },
    ];

    public static readonly HHRA_BUFFER_STRIPS = [
        { index: 0, name: '2-3' },
        { index: 1, name: '5' },
        { index: 2, name: '10' },
    ];

    public static readonly HHRA_SEASONS = [
        { index: 0, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]) }, { field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.APPLICATION_METHODS_SPRAY, relations: this.getHHRAApplicationMethodSpray([0, 1]) }, { field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.APPLICATION_METHODS_GRANULES, relations: this.getHHRAApplicationMethodGranules([0, 1, 2, 3]) }], name: 'not relevant' },
        { index: 1, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([3, 4, 5]) }, { field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.APPLICATION_METHODS_SPRAY, relations: this.getHHRAApplicationMethodSpray([1]) }], name: 'early (without leaves)' },
        { index: 2, relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.CROP_TYPE, relations: this.getHHRACropTypes([3, 4, 5]) }, { field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.APPLICATION_METHODS_SPRAY, relations: this.getHHRAApplicationMethodSpray([1]) }], name: 'late (dense foliage)' },
    ];

    public static readonly HHRA_DRIFT_REDUCTION = [
        { index: 0, name: '0%' },
        { index: 1, name: '50%' },
    ];

    public static readonly HHRA_PPES_GLOVES_GRANULES = [
        { value: false, name: 'None' },
        { value: true, name: 'Chemical resistant gloves' },
    ];

    public static readonly HHRA_PPES_BODY = [
        { index: 0, name: 'Potential exposure' },
        { index: 1, name: 'Certified protective coverall' },
    ];

    public static readonly HHRA_PPES_RPE = [
        { index: 0, name: 'None' },
        { index: 1, name: 'FP1, P1 and similar' },
        { index: 2, name: 'FP2, P2 and similar' },

    ];

    public static readonly HHRA_PPES_GLOVES_SOLUBLES = [
        { value: true, name: 'Yes' },
        { value: false, name: 'No' },
    ];

    public static readonly HHRA_PPE_SCENARIOS_NAMES: {
        SCENARIO1: "Potential exposure",
        SCENARIO2: "Standard workwear",
        SCENARIO3: "Workwear & gloves for M/L",
        SCENARIO4: "Workwear & gloves for M/L & A"
    };

    public static readonly HHRA_PPES_CLOTHING = [
        { index: 0, name: 'Potential exposure' },
        { index: 1, name: 'Work wear - arms, body and legs covered' },
    ];

    public static readonly HHRA_PPES_HEAD_RESPIRATORY = [
        { index: 0, name: 'None' },
        { index: 1, name: 'Hood' },
        { index: 2, name: 'Hood and face shield' },
        { index: 3, name: 'FP1, P1 and similar' },
        { index: 4, name: 'FP2,P2 and similar' },
    ];

    public static readonly HHRA_PPES_WATER_SOLUBLE_BAG = [
        { value: true, name: 'Yes' },
        { value: false, name: 'No' },
    ];

    private static getHHRAApplicationEquipmentSpray(relations: number[]) {
        return this.HHRA_APPLICATION_EQUIPMENT_SPRAY.filter(equipment => relations.includes(equipment.index));
    }

    public static readonly HHRA_CLOSED_CAB = [
        { value: 'Not relevant', relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.APPLICATION_METHODS_SPRAY, relations: this.getHHRAApplicationMethodSpray([0,1]) }, { field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.APPLICATION_EQUIPMENT, relations: this.getHHRAApplicationEquipmentSpray([0,1,2,3]) }], name: 'Not relevant' },
        { value: 'Yes', relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.APPLICATION_METHODS_SPRAY, relations: this.getHHRAApplicationMethodSpray([1]) }, { field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.APPLICATION_EQUIPMENT, relations: this.getHHRAApplicationEquipmentSpray([2]) }], name: 'Yes' },
        { value: 'No', relatedFields: [{ field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.APPLICATION_METHODS_SPRAY, relations: this.getHHRAApplicationMethodSpray([1]) }, { field: HHRAConstants.HHRA_RELATED_FIELDS_INDEX.APPLICATION_EQUIPMENT, relations: this.getHHRAApplicationEquipmentSpray([2]) }], name: 'No' },
    ];

    public static readonly HHRA_USA_RELATED_FIELDS_INDEX = {
        WORKER_ACTIVITY: { index: 0, field: 'workerActivity' },
        INDOOR_OUTDOOR_APPLICATION: { index: 1, field: 'indoorOutdoorApplication' },
        APPLICATION_METHODS_SPRAY: { index: 2, field: 'applicationMethod' },
        APPLICATION_METHODS_GRANULES: { index: 3, field: 'applicationMethod' },
        APPLICATION_EQUIPMENT: { index: 4, field: 'applicationEquipment' },
        BUFFER_STRIP: { index: 5, field: 'bufferStrip' },
        SEASON: { index: 6, field: 'season' },
        DRIFT_REDUCTION: { index: 7, field: 'driftReduction' },
    };

    public static readonly HHRA_USA_WORKER_ACTIVITY = [
        { index: 0, name: 'Applicator' },
        { index: 1, name: 'Flagger' },
        { index: 2, name: 'L/A' },
        { index: 3, name: 'M/L' },
        { index: 4, name: 'M/L/A' },
    ];

    public static readonly HHRA_USA_FORMULATION = [
        { index: 0, name: 'Child-resistant Packaging' },
        { index: 1, name: 'Dry Flowable' },
        { index: 2, name: 'Dust' },
        { index: 3, name: 'Fertilizer, dry bulk, impregnated' },
        { index: 4, name: 'Gel/Paste' },
        { index: 5, name: 'Granule' },
        { index: 6, name: 'Impregnated Material' },
        { index: 7, name: 'Liquid' },
        { index: 8, name: 'Microencapsulate' },
        { index: 9, name: 'Paint/stain' },
        { index: 10, name: 'Pallete/tablet' },
        { index: 11, name: 'Pressurized Liquid' },
        { index: 12, name: 'Spray (all starting formulations)' },
        { index: 13, name: 'Water-soluble Packet' },
        { index: 14, name: 'Wattable Powder' },
    ];

    public static readonly HHRA_USA_APPLICATION_EQUIPMENT = [
        { index: 0, name: 'Aerial' },
        { index: 1, name: 'Aerosol Can' },
        { index: 2, name: 'Airblast' },
        { index: 3, name: 'Airless Sprayer' },
        { index: 4, name: 'Back rubber' },
        { index: 5, name: 'Backpack' },
        { index: 6, name: 'Bag tow' },
        { index: 7, name: 'Belly grinder' },
        { index: 8, name: 'Boom Sprayer (boat)' },
        { index: 9, name: 'Brush/roller' },
        { index: 10, name: 'Bulb duster' },
        { index: 11, name: 'Chemigation' },
        { index: 12, name: 'Collar' },
        { index: 13, name: 'Dip (manual)' },
        { index: 14, name: 'Dip (swin vat)' },
        { index: 15, name: 'Dust bag' },
        { index: 16, name: 'Ear tag' },
        { index: 17, name: 'Feed-through' },
        { index: 18, name: 'Groundboom' },
        { index: 19, name: 'Hand disperser' },
        { index: 20, name: 'Handheld/Portable Fogger/Mister' },
        { index: 21, name: 'Impregnation' },
        { index: 22, name: 'Injection equipment' },
        { index: 23, name: 'Manually-pressurized Handwand' },
        { index: 24, name: 'Mechanically-pressurized Handgun' },
        { index: 25, name: 'Metered Drip System' },
        { index: 26, name: 'Misting system' },
        { index: 27, name: 'Plunger duster' },
        { index: 28, name: 'Pour-in/on' },
        { index: 29, name: 'Rotary spreader' },
        { index: 30, name: 'Shaker can' },
        { index: 31, name: 'Shampoo' },
        { index: 32, name: 'Solid Spreader (boat)' },
        { index: 33, name: 'Sponge' },
        { index: 34, name: 'Spoon' },
        { index: 35, name: 'Spot-on' },
        { index: 36, name: 'Stationary/Automatic Fogger/Mister (with re-entry restriction)' },
        { index: 37, name: 'Stationary/Automatic Fogger/Mister (without re-entry restriction)' },
        { index: 38, name: 'Submerged Hose' },
        { index: 39, name: 'Total-release Fogger' },
        { index: 40, name: 'Tractor-drawn Spreader' },
        { index: 41, name: 'Trap/bait station, refillable' },
        { index: 42, name: 'Trap/bait station, single-use' },
        { index: 43, name: 'Trigger-spray bottle' },
        { index: 44, name: 'Truck-mounted Fogger/Mister' },
        { index: 45, name: 'Wipe/Towelette' },
    ];


    public static readonly HHRA_RATE_UOM = [
        { index: 0, name: 'lb ai/acre' },
        { index: 1, name: 'lb ai/acre-foot' },
        { index: 2, name: 'lb ai/ton' },
        { index: 3, name: 'lb ai/gallon solution' },
        { index: 4, name: 'lb ai/animal' },
        { index: 5, name: 'lb ai/cfs' },
        { index: 6, name: 'lb ai/ft3' },
        { index: 7, name: 'lb ai/tree' },
        { index: 8, name: 'lb ai/square foot' },
        { index: 9, name: 'lb ai/(mound/nest)' },
        { index: 10, name: 'lb ai/station' },
        { index: 11, name: 'lb ai/lb feed' },
        { index: 12, name: 'lb ai/foot' },
        { index: 13, name: 'lb ai/can' },
        { index: 14, name: 'lb ai/bottle' },
        { index: 15, name: 'lb ai/collar' },
        { index: 16, name: 'lb ai/ear tag' },
    ];

    public static readonly HHRA_USA_DERMAL_UOM = [
        { index: 0, name: 'SL/No G' },
        { index: 1, name: 'SL/G' },
        { index: 2, name: 'DL/G' },
        { index: 3, name: 'SL/G/CRH' },
        { index: 4, name: 'DL/G/CRH' },
        { index: 5, name: 'EC/No G' },
        { index: 6, name: 'EC/G' },
    ];

    public static readonly HHRA_USA_INHALATION_UOM = [
        { index: 0, name: 'No-R' },
        { index: 1, name: 'PF10 R' },
        { index: 2, name: 'PF50 R' },
        { index: 3, name: 'EC/No R' },
        { index: 4, name: 'EC/PF10 R' },
    ];
}