<div *ngIf="showControls && isProjectOwnershipValid" style="float: inherit;margin: 4px;">
  <app-button
   style="width: 150px;"
   [hidden]="isLoading"
   [iconPos]="'left'"
   [ngStyle]="{backgroundColor:'bluegray-300'}" label="Use existing Data Set" [size]="'small'"
   [outlined]="true"
   [severity]="'secondary'"
   (onClickEvent)="selectedProject && useExistingDS(selectedProject,environmentalAssessment)"
  ></app-button>
</div>

<app-grid #grid
  *ngIf="!isLoading"
  [rowSelection]="'multiple'"
  [domLayout]="'autoHeight'"
  [columnDefs]="columnDefs"
  [rowData]="rowData"
  [rowHeight]="45"
  [useAddAction]="showControls && isProjectOwnershipValid"
  [rowMultiSelectWithClick]="true"
  [paginationPageSize]="25"
  [pagination]="false"
  [suppressRowClickSelection]="true"
  (dropDownSelectionChangeEvent)="onDropdownChange($event)"
  (deleteEvent)="onDelete($event)"
  (buttonExpandParams)="onButtonExpandClick($event)"
  (addEvent)="onAdd()"
  (onBlurInputText)="onBlurInputText($event)"
  (onCopyDatasetEmit)="onCopyDataset($event)"
  (onSelectedRowEvent)="onSelectedRow($event)"
  (onRowSelectedEmit)="onSelectionChanged($event)"
  ></app-grid>

<app-use-endpoint-dataset #appDialog></app-use-endpoint-dataset>

<app-loading *ngIf="isLoading" class="flex align-items-center justify-content-center"></app-loading>

<p-blockUI [blocked]="blockUi">
  <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
</p-blockUI>

<app-era-ecotox-import-popup
  [parameters]="eraEcotoxImportPopupParameters"
  [selectedProject]="selectedProject"
  (endpointsToImport)="onImportEchoEra($event)"
></app-era-ecotox-import-popup>
