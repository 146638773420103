<app-selected-project></app-selected-project>
<div class="isProjectOwnershipValid">
    <p [hidden]="isProjectOwnershipValid" >You can not modify or run someone's project</p>
</div>
<div class="pt-1 pr-5 pb-5 pl-5">
    <app-wizard class="surface-200" [items]="EEALogicService.items" [message]="message" [loading]="loading"
        [activeItem]="activeItem" [selectedProject]="selectedProject" [selectedProjectStatus]="selectedProjectStatus" [isSetupValid]="isSetupValid"
        (activeIndexChangeEmitter)="onActiveIndexChange($event)"></app-wizard>
    <div class="shadow-8 surface-0 p-3">
        <ng-container *ngIf="activeItem?.index == 0">
            <app-eea-setup [menuService]="EEALogicService" [modelsCollection]="modelsCollection" [disciplinePk]="disciplinePk" [columnDef]="columnDef" (isSetupValidEvent)="validateSetup($event)"></app-eea-setup>
        </ng-container>

        <ng-container *ngIf="activeItem?.index == 1">
            <app-eea-endpoints  [selectedProject]="selectedProject" [menuService]="EEALogicService" (isValid)="getIsValidEndpoint($event)"></app-eea-endpoints>
        </ng-container>

        <ng-container *ngIf="activeItem?.index == 2">
            <app-eea-gap [pwcFlag]="pwcFlag"[selectedProject]="selectedProject" [menuService]="EEALogicService" (isValid)="getIsValidApplicationScheme($event)"></app-eea-gap>
        </ng-container>

        <ng-container *ngIf="activeItem?.index == 3">
            <app-eea-run-project [selectedProject]="selectedProject" [menuService]="EEALogicService" [selectedProjectStatus]="selectedProjectStatus" [environmentalAssessment]="environmentalAssessment" (updateEEAMenuLoadingStatus)="onUpdateLoadingStatus($event)" (showLoadingForOutput)="onShowLoadingForOutput($event)"></app-eea-run-project>
        </ng-container>

        <ng-container *ngIf="activeItem?.index == 4">
            <app-eea-output [selectedProject]="selectedProject" [menuService]="EEALogicService" [selectedProjectStatus]="selectedProjectStatus"></app-eea-output>
        </ng-container>
    </div>
</div>
