<div class="grid" *ngIf="!isLoading">
  <div class="col-12 p-3 mt-3">
    <app-grid #grid
      [domLayout]="'autoHeight'"
      [columnDefs]="columnDefs"
      [rowMultiSelectWithClick]="true"
      [rowSelection]="'multiple'"
      [suppressRowClickSelection]="true"
      [style]="style"
      [rowData]="rowData"
      [useAddAction]="isProjectOwnershipValid"
      [useCustomNewRow]="true"
      (deleteEvent)="saveData($event)"
      (saveEvent)="saveData($event)"
      (refreshEvent)="getData()"
      (dropDownSelectionChangeEvent)="dropDownSelectionChangeEvent($event)"
      (addEvent)="newRowAdded($event)"
      (onBlurInputText)="onBlurInputText($event)"
      (onSelectedRowEvent)="onSelectedRow($event)"
      (onRowSelectedEmit)="onSelectionChanged($event)"
    ></app-grid>
  </div>
</div>
<app-loading class="mt-2 flex align-items-center justify-content-center" *ngIf="isLoading"></app-loading>
