import { Injectable } from '@angular/core';
import { CompoundPkAndName } from 'src/app/shared/models/compound';

@Injectable({
  providedIn: 'root',
})

export class EEAEndpointsFormationFractionColDef {

  public getColumnsDefinition(precursors: CompoundPkAndName[], isProjectOwnershipValid: boolean): any {
    return [
      {
        headerName: 'Precursor',
        field: 'compoundPk',
        width: 194,
        cellRenderer: 'dropdownlistRenderer',
        cellRendererParams: {
          options: precursors,
          optionValue: 'compoundPk',
          optionLabel: 'compoundName',
          filterable: true,
          disabled: !isProjectOwnershipValid,
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: false,
        sortable: false,
        editable: false
      },
      {
        headerName: 'Formation Fraction',
        field: 'formationFraction',
        width: 142,
        cellRenderer: 'inputNumberRenderer',
        cellRendererParams: {
          field: 'formationFraction',
          min: 0,
          max: 1,
          disabled: !isProjectOwnershipValid,
        },
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: false,
        sortable: false,
        editable: false,
      },
      {
        width: 125,
        minWidth: 125,
        maxWidth: 125,
        editable: false,
        colId: 'action',
        cellRenderer: 'deleteButtonRenderer',
        cellRendererParams: {
          disabled: !isProjectOwnershipValid,
        }
      }
    ];
  }
}
