<div *ngIf="afterSearch && rowData.length !== 0" class="grid">
    <div class="col-3">
        <div class="grid">
            <p-table class="col-12" styleClass="p-datatable-striped" [value]="headerLabels" [resizableColumns]="true" columnResizeMode="expand">
                <ng-template pTemplate="header">
                    <tr>
                        <th [ngClass]="'header_column'">Parameters</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-paramsLabel>
                    <tr>
                        <td>{{ paramsLabel }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="col-9">
        <div class="grid">
            <p-table class="col-12" styleClass="p-datatable-striped" [value]="[rowData]" [resizableColumns]="true" columnResizeMode="expand">
                <ng-template pTemplate="header" *ngFor="let val of rowData">
                    <tr>
                        <th [ngClass]="{'group_endpoints_color': val.style !== 0}" *ngFor="let val of rowData" style="min-width: 100px;">
                            {{val.substanceName}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body">
                    <tr [ngClass]="isProjectOwnershipValid? '': 'disabled'" [style.pointer-events]="isProjectOwnershipValid? 'auto': 'none'">
                        <td *ngFor="let val of rowData" [pEditableColumn]="val.molecularWeight" pEditableColumnField="molecularWeight" [pEditableColumnDisabled]="false">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber class="input" [inputStyle]="{'min-width':'85px'}" [(ngModel)]="val.molecularWeight" mode="decimal" [maxFractionDigits]="3" inputId="molecularWeight"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ setExponentialNumber(val.molecularWeight)}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                    </tr>
                    <tr [ngClass]="isProjectOwnershipValid? '': 'disabled'" [style.pointer-events]="isProjectOwnershipValid? 'auto': 'none'">
                        <td *ngFor="let val of rowData" [pEditableColumn]="val.solubilityInWater"
                            pEditableColumnField="solubilityInWater" [pEditableColumnDisabled]="false">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input type="text" pInputText style="width: 85px" [(ngModel)]="val.solubilityInWater" inputId="solubilityInWater" (blur)="onBlur('solubilityInWater', val)" />
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ validateRegExp(val.solubilityInWater)}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                    </tr>
                    <tr [ngClass]="isProjectOwnershipValid? '': 'disabled'" [style.pointer-events]="isProjectOwnershipValid? 'auto': 'none'">
                        <td *ngFor="let val of rowData" [pEditableColumn]="val.saturatedVapourPressure" pEditableColumnField="saturatedVapourPressure" [pEditableColumnDisabled]="false">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input type="text" pInputText style="width: 85px" [(ngModel)]="val.saturatedVapourPressure" inputId="saturatedVapourPressure" (blur)="onBlur('saturatedVapourPressure', val)" />
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ validateRegExp(val.saturatedVapourPressure) }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                    </tr>
                    <tr [ngClass]="isProjectOwnershipValid? '': 'disabled'" [style.pointer-events]="isProjectOwnershipValid? 'auto': 'none'">
                        <td *ngFor="let val of rowData" [pEditableColumn]="val.kfoc" pEditableColumnField="kfoc" [pEditableColumnDisabled]="false">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber class="input" [inputStyle]="{'min-width':'85px'}" [(ngModel)]="val.kfoc" mode="decimal" [maxFractionDigits]="18" inputId="kfoc"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ setExponentialNumber(val.kfoc) }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                    </tr>
                    <tr [ngClass]="isProjectOwnershipValid? '': 'disabled'" [style.pointer-events]="isProjectOwnershipValid? 'auto': 'none'">
                        <td *ngFor="let val of rowData" [pEditableColumn]="val.kom" pEditableColumnField="kom" [pEditableColumnDisabled]="false">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber class="input" [inputStyle]="{'min-width':'85px'}" [(ngModel)]="val.kom" mode="decimal" [maxFractionDigits]="18" inputId="kom"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ setExponentialNumber(val.kom)}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                    </tr>
                    <tr [ngClass]="isProjectOwnershipValid? '': 'disabled'" [style.pointer-events]="isProjectOwnershipValid? 'auto': 'none'">
                        <td *ngFor="let val of rowData" [pEditableColumn]="val.exponent" pEditableColumnField="exponent" [pEditableColumnDisabled]="false">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber class="input" [inputStyle]="{'min-width':'85px'}" [(ngModel)]="val.exponent" mode="decimal" [maxFractionDigits]="3" inputId="exponent"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ val.exponent }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                    </tr>
                    <tr [ngClass]="isProjectOwnershipValid? '': 'disabled'" [style.pointer-events]="isProjectOwnershipValid? 'auto': 'none'">
                        <td *ngFor="let val of rowData" [pEditableColumn]="val.halfLifeInSoil" pEditableColumnField="halfLifeInSoil" [pEditableColumnDisabled]="false">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber class="input" [inputStyle]="{'min-width':'85px'}" [(ngModel)]="val.halfLifeInSoil" mode="decimal" [maxFractionDigits]="6" inputId="halfLifeInSoil"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ val.halfLifeInSoil }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                    </tr>
                    <tr [ngClass]="isProjectOwnershipValid? '': 'disabled'" [style.pointer-events]="isProjectOwnershipValid? 'auto': 'none'">
                        <td *ngFor="let val of rowData" [pEditableColumn]="val.measuredAtSoil" pEditableColumnField="measuredAtSoil" [pEditableColumnDisabled]="false">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber class="input" [inputStyle]="{'min-width':'85px'}" [(ngModel)]="val.measuredAtSoil" mode="decimal" [maxFractionDigits]="2" inputId="measuredAtSoil"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ val.measuredAtSoil }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
<div *ngIf="afterSearch && rowData.length == 0" class="flex justify-content-center flex-wrap">
    <h4>No data to display</h4>
</div>
<app-loading class="flex align-items-center justify-content-center" *ngIf="isLoading"></app-loading>
