import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Project } from 'src/app/shared/models/project';
import { Constants } from 'src/app/shared/utils/constants';
import { Subscription } from 'rxjs';
import { RunApiService } from 'src/app/shared/services/run.api.service';

@Component({
  selector: 'app-eea-output-project-pwc',
  templateUrl: './eea-output-project-pwc.component.html',
  styleUrls: ['./eea-output-project-pwc.component.css']
})
export class EeaOutputProjectPwcComponent implements OnInit {
  @Input() selectedProject?: Project;
  @Input() selectedProjectStatus?: number;
  @Input() selectedProjectRunsSaved?: boolean;
  @Input() selectedModel?: string;

  disabledInputs: boolean = true;
  disabledInputsSubscription: Subscription | undefined;  
  disabledSummary = true;
  activeIndex = -1;
  
  constructor(public runApiService: RunApiService) { }
  
  ngOnInit(): void {
    this.disabledSummary = !(this.selectedProject?.status == 2);
    if (this.selectedProject) {
      try {
        this.disabledInputsSubscription = this.runApiService.runsHaveBeenSaved(this.selectedProject.projectPk).subscribe((value: boolean) => {
          this.disabledInputs = !value;
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
    
  ngOnDestroy(): void {
    if (this.disabledInputsSubscription) {
      this.disabledInputsSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes[Constants.SELECTED_PROJECT_STATUS]) {
      this.disabledSummary = !(this.selectedProjectStatus == 2);
    }
    if (changes[Constants.SELECTED_PROJECT_RUNS_SAVED]) {
      this.disabledInputs = !this.selectedProjectRunsSaved;
    }
  }
}
