import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EEAOutputProjectPelmoInputsUsedInCalculationColdef {

  constructor() { }


  private columnDefs = [
    {
      headerName: 'General',
      instance: this,
      children: [
        {
          headerName: 'Run ID',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'runId',
          minWidth: 120,
          width: 120,
          type: 'default',
          filter: 'agSetColumnFilter',
          pinned: 'left',
        },
        {
          headerName: 'Model',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'model',
          width: 110,
          minWidth: 100,
          type: 'default',
          filter: 'agSetColumnFilter',
          pinned: 'left',
        },
      ],
    },
    {
      headerName: 'Application Pattern (GAP)',
      instance: this,
      children: [
        {
          headerName: 'Location',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'location',
          width: 120,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Application Scheme',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'applicationSchemeName',
          width: 300,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Crop',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'crop', 
          width: 160,
          filter: 'agSetColumnFilter',
        },
        {
        headerName: 'Number Of \n Applications ',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'applicationsNumber',
          width: 120,
          filter: 'agSetColumnFilter',
          instance: this,
        },
        {
          headerName: 'Interval Between \n Application (d)',
          editable: false,
          wrapText: false, 
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'applicationInterval',
          width: 140,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
        {
          headerName: 'Rate \n (g/ha)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'rate',
          width: 100,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
        {
          headerName: 'BBCH \n (Earliest)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'BBCHEarliest',
          width: 100,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
      ],
    },
    {
      headerName: 'Endpoints',
      instance: this,
      children: [
        {
          headerName: 'Endpoint Data Set',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'endpointDasasetName',
          width: 200,
          type: 'default',
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Active Ingredient',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'activeIngredient',
          width: 150,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Substance Name',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          cellRenderer: 'agGroupCellRenderer',
          field: 'substanceName',
          width: 200,
          filter: 'agSetColumnFilter',
        },

        {
          headerName: 'Molecular \n Weight (g/mol)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'molecularWeight',
          width: 135,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Saturated Vapour \n Pressure (Pa)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'saturatedVapourPressure',
          width: 135,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Solubility \n In Water (mg/L)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'solubilityInWater',
          width: 128,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Kfoc (L/Kg)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'kfoc',
          width: 110,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Freundlich Sorption \n Exponent In Soil (-)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'freundlichSorptionExponentInSoil',
          width: 155,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Desorption Rate \n Kd (1/d)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'desorptionRateKd',
          width: 130,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Fraction Of Non-eq.-sites \n To Eq.-sites (f_ne)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'fractionOfNonEqSitesToEqSites',
          width: 180,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Half-life \n In Soil (d)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'halfLifeInSoil',
          width: 105,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Coefficient For \n Uptake By Plant (TSCF) (-)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'coefficientForUptakeByPlant',
          width: 180,
          filter: 'agSetColumnFilter',
        },
      ],
    },
  ];
  
  getColumnsDefinition(): any {
    return this.columnDefs;
  }
}
