import { ActiveIngredientApiService } from '../../services/echo/active-ingredient.api.service';
import { BehaviorSubject, lastValueFrom, map, take } from 'rxjs';
import { DataSet } from '../../models/echo/data-set';
import { EndpointDatasetGridTransaction, Row } from './endpoint-dataset.model';
import { EndpointDatasetService } from './endpoint-dataset.service';
import { ImportDataSet, ImportDatasetDataToCopy } from '../../models/echo/import-data-set';
import { Injectable } from '@angular/core';
import { MoleculePkAndName } from '../../models/echo/molecule';
import { Project } from '../../models/project';
import { ProjectApiService } from '../../services/project.api.service';
import { ProjectXCompoundXModel } from '../../models/project-x-compound-x-model';
import { EndpointsParameterConfiguration } from 'src/app/environmental-exposure-assessment/eea-endpoints/shared/eea-endpoints-parameters/eea-endpoints-parameters.config';
import { Model, ModelByCompartment } from '../../models/ModelByCompartment';
import { Constants } from '../../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class EndpointDatasetLogicService {

  private selectedDataSet = new BehaviorSubject<DataSet | undefined>(undefined);

  public datasetsToImport: DataSet[] = [];
  public molecules: MoleculePkAndName[] = [];
  public projectPk?: number;
  public projectXCompoundXModel: ProjectXCompoundXModel[] = [];
  public selectedDataSet$ = this.selectedDataSet.asObservable();

  constructor(
    private readonly activeIngredientApiService: ActiveIngredientApiService,
    private readonly apiService: EndpointDatasetService,
    private readonly projectApiService: ProjectApiService
  ) { }

  public updateSelectedDataSet(newValue: DataSet | undefined) {
    this.selectedDataSet.next(newValue);
  }

  public async setMoleculesFromProjectModels(projectXCompoundsXModels: ProjectXCompoundXModel[]): Promise<void> {
    const moleculePks: number[] = this.getProjectMoleculePks(projectXCompoundsXModels);
    await this.setMolecules(moleculePks);
  }

  public getEndpointsByModelsSelected(): string[] {
    const selectedModelsSet: Set<string> = new Set(this.projectXCompoundXModel.map(p => p.ModelName!));
    const endpoints: string[] = [];

    EndpointsParameterConfiguration.MODELS_BY_COMPARTMENT_ENDPOINTS.flatMap((compartment: ModelByCompartment) =>
      compartment.models.flatMap((model) =>
        selectedModelsSet.has(model.name) ? model.endpoints.map(endpoint => endpoint.dataValueName) : []
      )).forEach(endpoint => endpoints.push(endpoint));

    return [...new Set(endpoints)];
  }

  public getDataToCopy(source: string): ImportDatasetDataToCopy[] {
    switch (source) {
      case Constants.ENVIRONMENTAL_ASSESSMENTS.ENVIRONMENTAL_EXPOSURE_ASSESSMENT:
      case Constants.ENVIRONMENTAL_ASSESSMENTS.HUMAN_HEALTH_RISK_ASSESSMENT:
        return this.SetDataToCopy();
    }
    return [];
  }

  private SetDataToCopy(): ImportDatasetDataToCopy[] {
    const dataToCopy: ImportDatasetDataToCopy[] = [];

    this.projectXCompoundXModel.forEach(projectXCompoundXModel => {
      this.processProjectXCompoundXModel(projectXCompoundXModel, dataToCopy);
    });

    return [...new Set(dataToCopy)];
  }

  private processProjectXCompoundXModel(projectXCompoundXModel: ProjectXCompoundXModel, dataToCopy: ImportDatasetDataToCopy[]) {
    const { MetabolitePk: metabolitePk, CompartmentPk: compartmentPk, ModelName: modelName } = projectXCompoundXModel;

    EndpointsParameterConfiguration.MODELS_BY_COMPARTMENT_ENDPOINTS.forEach(modelByCompartment => {
      this.processModelByCompartment(modelByCompartment, modelName!, metabolitePk, compartmentPk, dataToCopy);
    });
  }

  private processModelByCompartment(modelByCompartment: ModelByCompartment, ModelName: string, MetabolitePk: number | undefined, CompartmentPk: number | undefined, dataToCopy: ImportDatasetDataToCopy[]) {
    const compartmentName = modelByCompartment.compartment;

    modelByCompartment.models.forEach(model => {
      if (model.name == ModelName) {
        this.processModel(model, compartmentName, MetabolitePk, CompartmentPk, dataToCopy);
      }
    });
  }

  private processModel(model: Model, compartmentName: string, MetabolitePk: number | undefined, CompartmentPk: number | undefined, dataToCopy: ImportDatasetDataToCopy[]) {
    if (dataToCopy.some(e => e.compartment.compartmentName == compartmentName && e.metabolitePk == MetabolitePk)) {
      const index = dataToCopy.findIndex(e => e.compartment.compartmentName == compartmentName && e.metabolitePk == MetabolitePk);
      dataToCopy[index].endpointsToCopy = [...new Set([...dataToCopy[index].endpointsToCopy, ...model.endpoints.map(endpoint => endpoint.dataValueName)])];
    } else {
      const data: ImportDatasetDataToCopy = {
        metabolitePk: MetabolitePk,
        compartment: { compartmentPk: CompartmentPk!, compartmentName: compartmentName },
        endpointsToCopy: model.endpoints.map(endpoint => endpoint.dataValueName)
      }

      dataToCopy.push(data);
    }
  }

  public setProjectPk(projectPk: number): void {
    this.projectPk = projectPk;
  }

  public getTransactionOccurrences(rowData: DataSet[]): Record<string, number> {
    let occurrences = rowData.reduce((acumulator: Record<string, number>, rowData) => {
      const key = `${rowData.moleculePk}-${rowData.description?.toLocaleLowerCase()}`;
      acumulator[key] = (acumulator[key] || 0) + 1;
      return acumulator;
    }, {} as Record<string, number>);
    return occurrences;
  }

  public duplicateDataInRecord(transactions: Record<string, number>): boolean {
    for (let transaction in transactions) {
      if (transactions[transaction] > 1) {
        return true;
      }
    }
    return false;
  }

  private async setMolecules(moleculesPks: number[]): Promise<void> {
    const source$ = this.activeIngredientApiService.getBamsMoleculePkAndNameByPks(moleculesPks).pipe(take(1));
    const molecules = await lastValueFrom(source$);
    this.molecules = molecules!.sort((a, b) => (String(a.moleculeName).localeCompare(String(b.moleculeName))));
  }

  private getProjectMoleculePks(projectXCompoundsXModels: ProjectXCompoundXModel[]): number[] {
    const moleculePksSet: Set<number> = new Set();
    projectXCompoundsXModels?.forEach((projectXCompoundXModel) => {
      moleculePksSet.add(projectXCompoundXModel.MoleculePk!);
    });
    return Array.from(moleculePksSet).map(Number);
  }

  public transformRowGridToDataset(row: Row, source: string, projectPk: number): DataSet {
    let dataset: DataSet = {
      dataSetPk: row.dataSetPk ?? -1,
      description: row.description,
      dataSetsToImport: [],
      hasEndpoints: false,
      moleculePk: row.moleculePk,
      source: source,
      projectPk: projectPk,
      activeIngredient: '',
      substanceType: 'Active',
      calculatorGroup: row.calculatorGroup,
      useInProject: row.useInProject
    };
    return dataset;
  }

  public async save(dataset: EndpointDatasetGridTransaction[]): Promise<void> {
    const source$ = this.apiService.save(dataset).pipe(take(1));
    return await lastValueFrom(source$);
  }

  public async getDatasets(projectPk: number, source: string): Promise<DataSet[]> {
    const source$ = this.apiService.getDataSetsByProject(projectPk, source).pipe(take(1));
    return await lastValueFrom(source$);
  }

  public deleteDatasetWithEndpoints(datasetPk: number): Promise<boolean> {
    const source$ = this.apiService.deleteDataSetWithEndPointsByPK(datasetPk).pipe(take(1));
    return lastValueFrom(source$);
  }

  public async getDatasetByProjectpkMoleculePkAndName(description: string, moleculePk: number, projectPk: number): Promise<DataSet> {
    const source$ = this.apiService.getDatasetByProjectpkMoleculePkAndName(description, projectPk, moleculePk).pipe(take(1));
    return await lastValueFrom(source$);
  }

  public async existsDatasetByName(datasetName: string, moleculePk: number, datasetPk: number): Promise<number> {
    const source$ = this.apiService.existsDataset(datasetName, moleculePk, datasetPk).pipe(take(1));
    return await lastValueFrom(source$);
  }

  public async existsDatasetByPkWithFk(projectPk: number, datasetPk: number): Promise<number> {
    const source$ = this.apiService.existsDatasetWithFk(datasetPk,projectPk).pipe(take(1));
    return await lastValueFrom(source$);
  }

  public async getDataseByMoleculesPks(moleculesPks: number[]): Promise<void> {
    const source$ = this.apiService.getDatasetPkAndDescriptionByMoleculesPks(moleculesPks).pipe(take(1));
    const datasetsToImport = await lastValueFrom(source$);
    const distinctDatasets = this.distinctByDescription(datasetsToImport as { description: string | undefined }[]);
    this.datasetsToImport = distinctDatasets.sort((a, b) => (String(a.description).localeCompare(String(b.description))));
  }


  public async importDataSetInfo(datasetToCopy: ImportDataSet): Promise<void> {
    const source$ = this.apiService.importDataSetInfo(datasetToCopy).pipe(take(1));
    return await lastValueFrom(source$);
  }

  public async getProjectByPk(projectPk: number): Promise<Project> {
    const source$ = this.projectApiService.getProjectsByPks([projectPk]).pipe(
      map((projects: Project[]) => projects[0]),
      take(1)
    );
    return await lastValueFrom(source$);
  }

  public distinctByDescription<T extends { description: string | undefined }>(items: T[]): T[] {
      const uniqueDescriptions = new Set<string>();
      return items.filter(item => {
        if (item.description !== undefined && !uniqueDescriptions.has(item.description)) {
          uniqueDescriptions.add(item.description);
          return true;
        }
        return false;
      });
    }
}
