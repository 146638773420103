import { Component,EventEmitter,OnInit,Output} from '@angular/core';
import { Project } from '../../models/project';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { DataSet } from '../../models/echo/data-set';
import { Molecule } from '../../models/echo/molecule';
import { Chemical } from '../../models/trex/trex-inputs';
import { UseEndpointDatasetLogicService } from './use-endpoint-dataset-logic-service';
import { EndpointDatasetComponent } from '../endpoint-dataset/endpoint-dataset.component';

@Component({
  selector: 'app-use-endpoint-dataset',
  templateUrl: './use-endpoint-dataset.component.html',
  styleUrls: ['./use-endpoint-dataset.component.css'],
})
export class UseEndpointDatasetComponent implements OnInit {

  @Output() onClickSaveButton = new EventEmitter<any>();

  columnDefs: any = [];
  rowData: any[] = [];
  visible: boolean = false;
  projectPk?: number;
  selectedProject?: Project;
  isLoading: boolean = false;
  filteredDatasets: DataSet[] = [];
  selectedMolecule?: Molecule;
  activeIngredients: Chemical[] = [];
  selectedDataSet?: DataSet[];
  dataSetListAdded: DataSet[] = [];
  selectedDataSetAdded?: DataSet[];
  removedDataSet: DataSet[] = [];
  source: string = '';

    constructor(
    private useDataSetLogic: UseEndpointDatasetLogicService,
    private datasetModule: EndpointDatasetComponent
  ) { }

  ngOnInit(): void {
    this.filteredDatasets = [];
  }

  onSelectionChanged({ originalEvent, value }: DropdownChangeEvent) {
    if (originalEvent && value) {
      this.selectedProject = value;
      return;
    }
    this.selectedProject = undefined;
  }

  showDialog(visiblePopUp: boolean, project?: Project, source?: string) {
    this.visible = visiblePopUp;
    this.projectPk = project?.projectPk;
    this.source = source!;
    this.getMolecule(project);
  }

  close() {
    this.visible = false;
    this.selectedMolecule = undefined;
    this.dataSetListAdded = [];
    this.filteredDatasets = [];
  }
  closeDialog() {
    this.visible = false;
    this.selectedMolecule = undefined;
  }

  async getMolecule(project: Project | undefined) {
    await this.useDataSetLogic
      .getMoleculeByMoleculePks(
        project?.projectXCompoundXModel?.flatMap((x) => x.MoleculePk!) ?? []
      )
      .then((active: Molecule[]) => {
        this.activeIngredients = active.map((x: Molecule) => {
          const chemical: Chemical = {
            moleculePk: x.moleculePk,
            chemicalName: x.moleculeName,
          };
          return chemical;
        });
      });
  }

  async selectionChange({ originalEvent, value }: DropdownChangeEvent) {
    this.selectedMolecule = value;
    if (value) {
      await this.getExistingDatasets();
      await this.getDataSetByMoleculeExcludeDSProject(value).then();
    }
  }

  getPk(dataset: any) {
    const dataSetsSelected: DataSet[] = dataset.value;
    if (dataSetsSelected.length > 0) {
      {
        this.selectedDataSetAdded = dataSetsSelected;
      }
    } else {
      this.dataSetListAdded = [];
    }
  }

  async setDataSetToProject() {
    try {
      if (this.dataSetListAdded.length > 0) {
        this.dataSetListAdded = this.useDataSetLogic.distinctByDescription(
          this.dataSetListAdded as { description: string | undefined }[]
        );
        this.dataSetListAdded.forEach((dataset: DataSet) => {
          dataset.projectPk = this.projectPk!;
        });

        await this.useDataSetLogic.save(this.dataSetListAdded);

      }
      if(this.removedDataSet.length > 0){
        await this.deleteDataSet(this.removedDataSet);
      }
      this.refresh();
    } catch (error) {
      console.error(error);
    }

  }

  refresh() {
    this.datasetModule.setRowData();
    this.visible = false;
  }

  async getExistingDatasets() {
    const dataSetInProject = await this.useDataSetLogic.getDataSetByProject(
      this.projectPk!,
      this.source
    );
    this.dataSetListAdded = dataSetInProject.filter(
      (x) => x.originalProject !== this.projectPk
    );
    this.selectedDataSetAdded = dataSetInProject.filter(
      (x) => x.originalProject !== this.projectPk
    );
  }

  getSelectedItems(dataset: any) {
    const dataSetsSelected: DataSet[] = dataset.value;
    if (dataSetsSelected.length > 0) {
      this.dataSetListAdded = this.dataSetListAdded
        .concat(dataSetsSelected)
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.dataSetPk === value.dataSetPk)
        );
    }
  }

  async deleteDataSet(datasets: DataSet[]) {
    if (datasets.length > 0) {
      try {
        await this.useDataSetLogic.removeDataSetByProjectAndPK(
          this.projectPk!,
          datasets.map((x) => x.dataSetPk!)
        );
        this.getExistingDatasets();
        this.removedDataSet = [];
      } catch (error) {
        console.error(error);
      }
    }
  }

  async getDataSetByMoleculeExcludeDSProject(
    moleculePk: number
  ): Promise<void> {
    this.isLoading = true;
    try {
      const [dataset$, dataSetToAssign, projectDS] = await Promise.all([
        this.useDataSetLogic.getDataSetByMoleculesPks(
          [moleculePk],
          this.source
        ),
        this.useDataSetLogic.getDatasets(
          this.projectPk!,
          this.source,
          moleculePk
        ),
        this.useDataSetLogic.getDataSetByProject(this.projectPk!, this.source),
      ]);

      const distinctDatasets = this.useDataSetLogic
        .distinctByDescription(
          dataset$ as {
            originalProject: number | undefined;
            dataSetPk: number | undefined;
            description: string | undefined;
          }[]
        )
        .sort((a, b) =>
          String(a.description).localeCompare(String(b.description))
        );

      const tempData = distinctDatasets
        .filter(
          (dataset) =>
            !projectDS.some((item) => item.dataSetPk === dataset.dataSetPk)
        )
        .filter((x) => x.originalProject !== this.projectPk);

      this.filteredDatasets = tempData;
      this.dataSetListAdded = projectDS.filter(
        (x) => x.originalProject !== this.projectPk
      );
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  async removeDataSetByProjectAndPk(event: any) {
    var dataSetDeleted: DataSet = event.option;
    if (dataSetDeleted) {
      try {
        this.removedDataSet.push(dataSetDeleted);
        this.dataSetListAdded.splice(
          this.dataSetListAdded.indexOf(dataSetDeleted),
          1
        );
        this.filteredDatasets.push(dataSetDeleted);
        this.filteredDatasets = this.filteredDatasets.sort((a, b) =>
          String(a.description).localeCompare(String(b.description))
        );
      } catch (error) {
        console.error(error);
      }
    }
  }

  addDataSetToList(event: any) {
    const dataSetAdded: DataSet[] = event.option;
    if (dataSetAdded.length > 0) {
      const uniqueDataSets = new Set(
        this.dataSetListAdded.concat(dataSetAdded)
      );
      this.dataSetListAdded = Array.from(uniqueDataSets);

      this.filteredDatasets = this.filteredDatasets.filter(
        (dataset) => !uniqueDataSets.has(dataset)
      );
      this.filteredDatasets = this.filteredDatasets.sort((a, b) =>
        String(a.description).localeCompare(String(b.description))
      );
    }
  }
}
