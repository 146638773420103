<div class="grid" *ngIf="!isLoading" style="margin-top: 3px;">
    <div class="col-12 p-3 mt-3">
        <app-grid #grid
          [domLayout]="'autoHeight'"
          [columnDefs]="columnDefs"
          [style]="style"
          [rowSelection]="'multiple'"
          [rowData]="rowData"
          [useAddAction]="isProjectOwnershipValid"
          [isMasterDetail]="true"
          [detailColumnDefs]="detailsColumnDef"
          [useDetailAddButton]="false"
          [detailAutoRowHeigth]="true"
          [suppressRowClickSelection]="true"
          [useDeleteAction]="isProjectOwnershipValid"
          [rowMultiSelectWithClick]="true"
          (onBlurInputText)="onBlurInputText($event)"
          (addEvent)="onAdd()"
          (deleteEvent)="deleteRow($event)"
          (dropDownSelectionChangeEvent)="onSelectionChanged($event)"
          (listBoxSelectionChangeEvent)="geographiesListBoxSelectionChanged($event)"
          (onFirstDataRenderedEvent)="onFirstDataRendered($event)"
          (saveEvent)="saveData($event)"
          (refreshEvent)="getData()"
          (onRowExpanded)="onRowExpanded($event)"
          (onSelectedRowEvent)="onSelectedRow($event)"
          (onRowSelectedEmit)="onSelectionChanged($event)"
        ></app-grid>
    </div>
</div>
<app-loading class="mt-2 flex align-items-center justify-content-center" *ngIf="isLoading"></app-loading>
