import { CropListService } from "src/app/administrator/regulatory-models/crop-list/crop-list.service";
import { ActiveIngredientRate } from "src/app/shared/models/active-ingredient-rate";
import { AppSchemePkXScenarioPath, ApplicationSchemeCropState, ApplicationSchemeXActiveIngredientRate } from "src/app/shared/models/application-scheme";
import { CropList } from "src/app/shared/models/crop-list";
import { Endpoint } from "src/app/shared/models/endpoint";
import { Gap } from "src/app/shared/models/gap";
import { Project } from "src/app/shared/models/project";
import { ProjectXCompoundXModel } from "src/app/shared/models/project-x-compound-x-model";
import { ProjectXModel } from "src/app/shared/models/project-x-model";
import { MacroGWInputs } from "src/app/shared/models/run/macro-gw-inputs";
import { PearlInputs } from "src/app/shared/models/run/pearl-inputs";
import { PelmoInputs } from "src/app/shared/models/run/pelmo-inputs";
import { SoilInputs } from "src/app/shared/models/run/soil-inputs";
import { Run } from "src/app/shared/models/run/run";
import { RunModel } from "src/app/shared/models/run/run-model";
import { Step1n2Inputs } from "src/app/shared/models/run/step1n2-inputs";
import { SwashInputs } from "src/app/shared/models/run/swash-inputs";
import { UKInputs } from "src/app/shared/models/run/uk-inputs";
import { Constants } from "src/app/shared/utils/constants";
import { Utils } from "src/app/shared/utils/utils";
import { PWCInputs } from "src/app/shared/models/run/pwc-inputs";
import { Chemical } from "src/app/shared/models/pwc/chemical";
import { EEAGapLogicService } from "../../eea-gap/eea-gap.logic.service";
import { EEAGapApiService } from "../../eea-gap/eea-gap.api.service";
import { Applications } from "src/app/shared/models/pwc/applications";
import { ApplicationMethod } from "src/app/shared/models/pwc/application-method";
import { Catalog } from "src/app/shared/models/echo/catalog";
import { FractionTransformedXEndpoint } from "src/app/shared/models/run/fraction-transformed-x-endpoint";


export class RunModelsFactory {
    static cropListService: CropListService
    static gapLogicApi: EEAGapApiService
    static gapLogicService: EEAGapLogicService
    static geographiesList: Catalog[] = [];

    static createRunModels(runs: Run[], selectedProject: Project | undefined, userId: string, cropListService: CropListService, gapLogicApi: EEAGapApiService, gapLogicService: EEAGapLogicService, overwritePreviousRuns: boolean = false, geographiesList?: Catalog[]): RunModel {
        this.cropListService = cropListService;
        this.gapLogicService = gapLogicService;
        this.geographiesList = geographiesList || [];
        this.gapLogicApi = gapLogicApi;
        let runGroupedByModels = this.groupRunsByModel(runs);
        let runModels = this.creationRunModelsOrchestrator(runGroupedByModels, selectedProject, userId);
        runModels.overwritePreviousRuns = overwritePreviousRuns;
        return runModels;
    }

    static creationRunModelsOrchestrator(runGroupedByModels: { [key: string]: Run[]; }, selectedProject: Project | undefined, userId: string): RunModel {
        let runModels: RunModel = {};
        runModels.projectXModel = [];
        Object.entries(runGroupedByModels).forEach(async ([ModelName, runs]) => {
            if (runs[0] && runs[0]?.model?.modelPk && this.includeModelToBeRun(runs[0]?.model?.name)) {
                runModels.projectXModel?.push(this.createProjectXModelObject(runs, selectedProject, userId))
            }

            switch (ModelName) {
                case Constants.MODELS.STEP_1_2: {
                    runModels.step1n2Inputs = this.createStep1n2Object(runs, selectedProject);
                    break;
                }
                case Constants.MODELS.SWASH: {
                    runModels.swash = this.createSwashObject(runs, selectedProject);
                    break;
                }
                case Constants.MODELS.MACRO_GW: {
                    runModels.macroGW = this.createMacroGWObject(runs, selectedProject);
                    break;
                }
                case Constants.MODELS.PELMO: {
                    runModels.pelmo = this.createPelmoObject(runs, selectedProject);
                    break;
                }
                case Constants.MODELS.PEARL: {
                    runModels.pearl = this.createPearlObject(runs, selectedProject);
                    break;
                }
                case Constants.MODELS.UK: {
                    runModels.ukTier = await this.createUKObject(runs, selectedProject);
                    break;
                }
                case Constants.MODELS.GERMAN_SOIL: {
                    runModels.germanSoil = await this.createSoilObject(runs, selectedProject);
                    break;
                }
                case Constants.MODELS.EU_SOIL: {
                    runModels.euSoil = await this.createSoilObject(runs, selectedProject);
                    break;
                }
                case Constants.MODELS.NEU_SOIL: {
                    runModels.neuSoil = await this.createSoilObject(runs, selectedProject);
                    break;
                }
                case Constants.MODELS.PWC: {
                    this.createPWCObject(runs).then((pwcInputs: PWCInputs[]) => {
                        runModels.pwc == pwcInputs;
                    });
                    break;
                }
                default: {
                    break;
                }
            }
        });
        return runModels;
    }

    static createPelmoObject(runs: Run[], selectedProject: Project | undefined): PelmoInputs[] {
        var pelmoInputs: PelmoInputs[] = [];
        runs.forEach(run => {
            pelmoInputs.push({
                isParent: true,
                runID: run.id,
                activeSubstance: run.substanceName,
                substanceName: run.substanceName!,
                applicationScheme: run.applicationSchemeData?.name,
                crop: run.cropInterceptionName,
                formulation: selectedProject?.formulation,
                pathway: run.dataSet?.description,
                degradationDT50: run.halfLifeInSoil,
                firstDayLong: Utils.formatIsoDateToCustom(run.applicationSchemeData.firstDate),
                lastDayLong: Utils.formatIsoDateToCustom(run.applicationSchemeData.endDate),
                repeatInterval: run.applicationSchemeData?.repeatInterval ?? 0,
                koc: run.kfoc,
                numberOfApplications: run.applicationSchemeData.numberOfApplications,
                season: run.applicationSchemeData?.season,
                location: run.applicationSchemeData?.location,
                locationCode: Constants.locationCodes[run.applicationSchemeData?.location],
                applicationDayOfYearString: [""],
                applicationType: run.applicationSchemeData?.applicationMethod,
                compound: run.substanceName,
                exponent: run.exponent,
                dateType: run.applicationSchemeData.dateType,
                plantUptake: run.coefficientForUptakeByPlant ? run.coefficientForUptakeByPlant : 0,
                rainfallFile: "",
                sorptionValue: run.kfoc,
                molecularWeight: run.molecularWeight,
                vapourPressure: run.saturatedVapourPressure,
                DT50Sediment: run.halfLifeinSediment,
                DT50Water: run.halfLifeInWater,
                waterSolubility: run.solubilityInWater,
                soilDepthIncorporated: run.soilDepthIncorporated,
                gaps: this.getGapsByRun(run),
                model: run.model?.name!,
                soilDepth: run.applicationSchemeData.soilDepth,
                upperSoilDepth: 0,
                cropEvent: run.applicationSchemeData.cropEvent,
                vaporPressure: run.saturatedVapourPressure!,
                solubility: run.solubilityInWater!,
                fne: run.fne!,
                metabolites: this.getPelmoMetabolitesByRun(run, selectedProject),
                applicationDateType: run.applicationSchemeData.dateType,
                letter: "PA"
            } as PelmoInputs);
            pelmoInputs[pelmoInputs.length - 1].metabolites?.unshift(JSON.parse(JSON.stringify(pelmoInputs[pelmoInputs.length - 1])))
        });
        return pelmoInputs;
    }

    static getPelmoMetabolitesByRun(run: Run, selectedProject: Project | undefined): PelmoInputs[] {
        var PelmoInputsArray: PelmoInputs[] = [];
        run.metabolites.forEach((metabolite: any) => {
            PelmoInputsArray.push({
                isParent: false,
                runID: run.id,
                activeSubstance: run.substanceName,
                substanceName: metabolite.substanceName!,
                applicationScheme: run.applicationSchemeData?.name,
                crop: run.cropInterceptionName,
                formulation: selectedProject?.formulation,
                pathway: run.dataSet?.description,
                degradationDT50: metabolite.halfLifeInSoil,
                firstDayLong: Utils.formatIsoDateToCustom(run.applicationSchemeData.firstDate),
                lastDayLong: Utils.formatIsoDateToCustom(run.applicationSchemeData.endDate),
                repeatInterval: run.applicationSchemeData?.repeatInterval ?? 0,
                koc: metabolite.kfoc,
                numberOfApplications: run.applicationSchemeData.numberOfApplications,
                season: run.applicationSchemeData.season,
                location: run.applicationSchemeData?.location,
                locationCode: Constants.locationCodes[run.applicationSchemeData?.location],
                applicationDayOfYearString: [""],
                applicationType: run.applicationSchemeData?.applicationMethod,
                compound: metabolite.substanceName,
                exponent: metabolite.exponent,
                dateType: run.applicationSchemeData.dateType,
                plantUptake: run.coefficientForUptakeByPlant ? run.coefficientForUptakeByPlant : 0,
                rainfallFile: "",
                sorptionValue: metabolite.kfoc,
                molecularWeight: metabolite.molecularWeight,
                vapourPressure: metabolite.saturatedVapourPressure,
                DT50Sediment: metabolite.halfLifeinSediment,
                DT50Water: metabolite.halfLifeInWater,
                waterSolubility: metabolite.solubilityInWater,
                soilDepthIncorporated: run.applicationSchemeData.soilDepth,
                gaps: this.getGapsByRun(run),
                model: run.model?.name!,
                soilDepth: run.applicationSchemeData.soilDepth,
                upperSoilDepth: 0,
                cropEvent: run.applicationSchemeData.cropEvent,
                vaporPressure: metabolite.saturatedVapourPressure!,
                solubility: metabolite.solubilityInWater!,
                fne: metabolite.fne!,
                metabolites: [],
                precursors: metabolite.precursors,
                applicationDateType: run.applicationSchemeData.dateType,
                letter: metabolite.pelmoLetter,
            } as PelmoInputs);
        });
        return PelmoInputsArray;
    }

    static createPearlObject(runs: Run[], selectedProject: Project | undefined): PearlInputs[] {
        var pearlInputs: PearlInputs[] = [];
        runs.forEach(run => {
            pearlInputs.push({
                runID: run.id,
                substanceName: run.substanceName,
                activeSubstance: run.substanceName,
                applicationMethod: run.applicationSchemeData?.applicationMethod,
                applicationRate: run.applicationSchemeData.applicationSchemeXActiveIngredientRate?.find((ai: ApplicationSchemeXActiveIngredientRate) => ai.moleculePk === run.activeIngredientPk)?.rate,
                applicationScheme: run.applicationSchemeData?.name,
                coefficientForUptakeByPlant: run.coefficientForUptakeByPlant ? run.coefficientForUptakeByPlant : 0,
                crop: run.cropInterceptionName,
                cropEvent: run.applicationSchemeData.cropEvent,
                dateType: run.applicationSchemeData.dateType,
                desorptionRate: run.kdes,
                degradationDT50: run.halfLifeInSoil,
                exponent: run.exponent,
                formulation: selectedProject?.formulation,
                fractionNonEquilibrium: run.fne,
                gaps: this.getGapsByRun(run),
                kfoc: run.kfoc,
                location: run.applicationSchemeData?.location,
                metabolites: this.getPearlMetabolitesByRun(run, selectedProject),
                molecularWeight: run.molecularWeight,
                numberOfApplications: run.applicationSchemeData?.numberOfApplications,
                pathway: run.dataSet?.description,
                repeatInterval: run.applicationSchemeData?.repeatInterval ?? 0,
                waterSolubility: run.solubilityInWater,
                vapourPressure: run.saturatedVapourPressure,
                soilDepthIncorporated: run.applicationSchemeData.soilDepth,
                fractionInSoil: undefined,
            } as PearlInputs);
            pearlInputs[pearlInputs.length - 1].metabolites?.unshift(JSON.parse(JSON.stringify(pearlInputs[pearlInputs.length - 1])))
        });
        return pearlInputs;
    }

    static getPearlMetabolitesByRun(run: Run, selectedProject: Project | undefined): PearlInputs[] {
        var pearlInputs: PearlInputs[] = [];
        run.metabolites.forEach((metabolite: any) => {
            pearlInputs.push({
                activeSubstance: run.substanceName,
                substanceName: metabolite.substanceName,
                applicationMethod: run.applicationSchemeData?.applicationMethod,
                applicationScheme: run.applicationSchemeData?.name,
                coefficientForUptakeByPlant: metabolite.coefficientForUptakeByPlant ? run.coefficientForUptakeByPlant : 0,
                crop: run.cropInterceptionName,
                cropEvent: run.applicationSchemeData.cropEvent,
                dateType: run.applicationSchemeData.dateType,
                desorptionRate: metabolite.kdes,
                degradationDT50: metabolite.halfLifeInSoil,
                exponent: metabolite.exponent,
                formulation: selectedProject?.formulation,
                fractionNonEquilibrium: metabolite.fne,
                gaps: this.getGapsByRun(run),
                kfoc: metabolite.kfoc,
                location: run.applicationSchemeData?.location,
                metabolites: [],
                molecularWeight: metabolite.molecularWeight,
                numberOfApplications: run.applicationSchemeData?.numberOfApplications,
                pathway: run.dataSet?.description,
                repeatInterval: run.applicationSchemeData?.repeatInterval ?? 0,
                runID: run.id,
                waterSolubility: metabolite.solubilityInWater,
                vapourPressure: metabolite.saturatedVapourPressure,
                soilDepthIncorporated: run.applicationSchemeData.soilDepth,
                fractionInSoil: this.getFormationFractionByKey(metabolite.precursors, Constants.FIELD_NAMES.FRACTION_IN_SOIL),
            } as PearlInputs);
        });
        return pearlInputs;
    }

    static createMacroGWObject(runs: Run[], selectedProject: Project | undefined): MacroGWInputs[] {
        let macroGWInputs: MacroGWInputs[] = [];
        runs.forEach(run => {
            macroGWInputs.push({
                runID: run.id,
                isParent: true,
                repeatInterval: run.applicationSchemeData?.repeatInterval ?? 0,
                substanceName: run.substanceName,
                activeSubstance: run.substanceName,
                applicationDayOfYearString: [""],
                applicationScheme: run.applicationSchemeData?.name,
                applicationType: run.applicationSchemeData?.applicationMethod,
                compound: run.substanceName,
                crop: run.cropInterceptionName,
                degradationDT50: run.halfLifeInSoil,
                exponent: run.exponent,
                firstDayLong: Utils.formatIsoDateToCustom(run.applicationSchemeData.firstDate),
                formulation: selectedProject?.formulation,
                koc: run.kfoc,
                sorption: run.kfoc,
                lastDayLong: Utils.formatIsoDateToCustom(run.applicationSchemeData.endDate),
                metabolites: this.getMacroGWMetabolitesByRun(run, selectedProject),
                molecularWeight: run.molecularWeight,
                model: run.model?.name,
                numberOfApplications: run.applicationSchemeData.numberOfApplications,
                pathway: run.dataSet?.description,
                plantUptake: run.coefficientForUptakeByPlant ?? 0,
                rainfallFile: "",
                season: run.applicationSchemeData.season,
                sorptionValue: run.kfoc,
                soilDepthIncorporated: run.applicationSchemeData.soilDepth,
                vapourPressure: run.saturatedVapourPressure,
                waterSolubility: run.solubilityInWater,
                gaps: this.getMacroGapsByRun(run)

            } as MacroGWInputs);
            macroGWInputs[macroGWInputs.length - 1].metabolites?.unshift(JSON.parse(JSON.stringify(macroGWInputs[macroGWInputs.length - 1])))
        });
        return macroGWInputs;
    }

    static getMacroGWMetabolitesByRun(run: Run, selectedProject: Project | undefined): MacroGWInputs[] {
        var macroGWInputsArray: MacroGWInputs[] = [];
        run.metabolites.forEach((metabolite: Endpoint) => {
            macroGWInputsArray.push({
                isParent: false,
                repeatInterval: run.applicationSchemeData?.repeatInterval ?? 0,
                substanceName: metabolite.substanceName,
                activeSubstance: run.substanceName,
                applicationDayOfYearString: [""],
                applicationScheme: run.applicationSchemeData?.name,
                applicationType: run.applicationSchemeData?.applicationMethod,
                compound: metabolite.substanceName,
                crop: run.globalCropName,
                cropPk: run.globalCropPk,
                degradationDT50: metabolite.halfLifeInSoil,
                exponent: metabolite.exponent,
                firstDayLong: Utils.formatIsoDateToCustom(run.applicationSchemeData.firstDate),
                formulation: selectedProject?.formulation,
                koc: metabolite.kfoc,
                lastDayLong: Utils.formatIsoDateToCustom(run.applicationSchemeData.endDate),
                metabolites: [],
                molecularWeight: metabolite.molecularWeight,
                model: run.model?.name,
                numberOfApplications: run.applicationSchemeData.numberOfApplications,
                pathway: run.dataSet?.description,
                plantUptake: run.coefficientForUptakeByPlant ?? 0,
                rainfallFile: "",
                season: run.applicationSchemeData.season,
                sorptionValue: metabolite.kfoc,
                soilDepthIncorporated: run.applicationSchemeData.soilDepth,
                vapourPressure: run.saturatedVapourPressure,
                waterSolubility: metabolite.solubilityInWater,
                precursors: metabolite.precursors,
                gaps: []
            } as MacroGWInputs);
        });
        return macroGWInputsArray;
    }

    static getMacroGapsByRun(run: Run): Gap[] {
        let gap: Gap = {
            activeIngredientRates: [] = [],
            gapPk: 0,
            applicationSchemePk: 0,
            cropInterception: 0
        };
        gap.applicationDate = Utils.formatIsoDateToCustom(run.applicationSchemeData.applicationSchemeXApplicationWindow[0]?.firstDate);
        gap.cropGrowthStageInit = run.bbchEarliest;
        gap.cropGrowthStageFinal = run.bbchEarliest;
        gap.applicationIntervalMinPk = run.applicationIntervalMinPk;
        gap.dateType = Constants.DATE_TYPE_VALUES.ABSOLUTE;
        gap.cropInterception = run.applicationSchemeData.cropInterception;
        gap.bbchRange = run.bbchRange;
        let ais: ActiveIngredientRate[] = []
        run.applicationSchemeData.applicationSchemeXActiveIngredientRate.filter((ai: ApplicationSchemeXActiveIngredientRate) => run.activeIngredientPk == ai.moleculePk).forEach((AI: ApplicationSchemeXActiveIngredientRate) => {
            ais.push({
                activeIngredientRateXGapPk: 0,
                activeIngredientPk: AI.moleculePk,
                max: AI.rate,
                activeIngredientName: AI.substanceName,
            });
        });
        gap.activeIngredientRates = ais;
        let gaps: Gap[] = [gap];
        let interval = 0;
        for (let i = 1; i < run.applicationSchemeData.numberOfApplications; i++) {
            let otherGap: Gap = JSON.parse(JSON.stringify(gap))
            interval += run.applicationSchemeData.applicationInterval;
            otherGap.applicationDate = Utils.addDaysToDate(gap.applicationDate, interval);
            gaps.push(otherGap);
        }
        return gaps;
    }



    static includeModelToBeRun(model: string): boolean {
        let validModels = Object.values(Constants.MODELS).filter((modelName: string) => modelName !== Constants.MODELS.SWAN);
        return validModels.includes(model);
    }

    static createProjectXModelObject(runs: Run[], selectedProject: Project | undefined, userId: string): ProjectXModel {
        var project: ProjectXModel = {
            projectPk: selectedProject?.projectPk,
            modelPk: runs[0]?.model?.modelPk,
            active: true,
            status: 1,
            lastOutputFolder: runs[0]?.id?.split("_")[0],
            runBy: userId,
        }
        return project;
    }

    static createSwashObject(runs: Run[], selectedProject: Project | undefined): SwashInputs[] {
        let swashInputs: SwashInputs[] = [];
        let runSwanModel = selectedProject!.projectXCompoundXModel?.some((x: ProjectXCompoundXModel) => x.ModelName === Constants.MODELS.SWAN);
        runs.forEach(run => {
            swashInputs.push({
                runID: run.id,
                activeSubstance: run.substanceName?.replace(/ /g, "_").replace(/,/g, "-"),
                applicationScheme: run.applicationSchemeData?.name,
                cropName: run.cropDriftInterceptionName,
                cropPk: run.globalCropPk,
                formulation: selectedProject?.formulation,
                pathway: run.dataSet?.description,
                degradationDT50: run.halfLifeInSoil,
                firstDayLong: Utils.formatIsoDateToCustom(run.applicationSchemeData.firstDate),
                lastDayLong: Utils.formatIsoDateToCustom(run.applicationSchemeData.endDate),
                dosages: this.returnsListValuesRepeatedByNumOfApplications(run.applicationSchemeData.numberOfApplications, run.applicationSchemeData?.applicationSchemeXActiveIngredientRate?.find((x: ApplicationSchemeXActiveIngredientRate) => x.moleculePk === run.activeIngredientPk)?.rate),
                interval: run.applicationSchemeData?.applicationInterval,
                kfoc: run.kfoc,
                numberOfApplications: run.applicationSchemeData.numberOfApplications,
                scenario: run.applicationSchemeData.location,
                waterType: run.applicationSchemeData.waterType,
                season: run.applicationSchemeData.season,
                applicationDayOfYearString: [""],
                applicationType: run.applicationSchemeData.applicationMethod,
                compound: "",
                exponent: run.exponent,
                intervals: this.returnsListValuesRepeatedByNumOfApplications(run.applicationSchemeData.numberOfApplications, run.applicationSchemeData?.applicationInterval),
                metabolite: "",
                plantUptake: run.coefficientForUptakeByPlant ?? 0,
                rainfallFile: "",
                sorptionValue: 0,
                fullScenario: run.applicationSchemeData.fullScenario,
                molecularMassActive: run.molecularWeight,
                vapourPressure: run.saturatedVapourPressure,
                DT50Sediment: run.halfLifeinSediment,
                DT50Water: run.halfLifeInWater,
                waterSolubility: run.solubilityInWater,
                cropDriftInterceptionPk: run.applicationSchemeData.cropDriftInterceptionPk,
                soilDepthIncorporated: run.applicationSchemeData.soilDepth,
                chemicalApplicationMethod: run.applicationSchemeData.chemicalApplicationMethod,
                nozzleDriftReduction10m: run.applicationSchemeData.nozzleDriftReduction10m ?? 0,
                nozzleDriftReduction20m: run.applicationSchemeData.nozzleDriftReduction20m ?? 0,
                fractionInSurfaceWater: undefined,
                fractionInSediment: undefined,
                fractionInSoil: undefined,
                extraFractionInSurfaceWater: undefined,
                extraFractionInSediment: undefined,
                extraFractionInSoil: undefined,
                runSwanModel,
                metabolites: this.getSwashMetabolitesByRun(run, selectedProject).sort((a, b) => (a.compound < b.compound ? -1 : 1)),
                bbchEarliest: run.applicationSchemeData?.bbchEarliest,
            } as SwashInputs);
        });
        return swashInputs;
    }

    static getSwashMetabolitesByRun(run: any, selectedProject: Project | undefined): SwashInputs[] {
        var swashInputsArray: SwashInputs[] = [];
        run.metabolites.forEach((metabolite: any) => {
            swashInputsArray.push({
                runID: run.id,
                activeSubstance: run.substanceName.replace(/ /g, "_").replace(/,/g, "-"),
                applicationScheme: run.applicationSchemeData?.name,
                cropName: run.cropDriftInterceptionName,
                cropPk: run.globalCropPk,
                formulation: selectedProject?.formulation,
                pathway: run.dataSet?.description,
                degradationDT50: metabolite.halfLifeInSoil,
                firstDayLong: Utils.formatIsoDateToCustom(run.applicationSchemeData.firstDate),
                lastDayLong: Utils.formatIsoDateToCustom(run.applicationSchemeData.endDate),
                dosages: this.returnsListValuesRepeatedByNumOfApplications(run.applicationSchemeData.numberOfApplications, run.applicationSchemeData?.applicationSchemeXActiveIngredientRate?.find((x: ApplicationSchemeXActiveIngredientRate) => x.moleculePk === run.activeIngredientPk)?.rate),
                interval: run.applicationSchemeData?.applicationInterval,
                kfoc: metabolite.kfoc,
                numberOfApplications: run.applicationSchemeData.numberOfApplications,
                scenario: run.applicationSchemeData.location,
                waterType: run.applicationSchemeData.waterType,
                season: run.applicationSchemeData.season,
                applicationDayOfYearString: [""],
                applicationType: run.applicationSchemeData.applicationMethod,
                compound: metabolite.substanceName.replace(/ /g, "_").replace(/,/g, "-"),
                exponent: metabolite.exponent,
                intervals: this.returnsListValuesRepeatedByNumOfApplications(run.applicationSchemeData.numberOfApplications, run.applicationSchemeData?.applicationInterval),
                plantUptake: metabolite.coefficientForUptakeByPlant ?? 0,
                metabolite: "",
                rainfallFile: "",
                sorptionValue: 0,
                fullScenario: run.applicationSchemeData.fullScenario,
                molecularMassActive: metabolite.molecularWeight,
                vapourPressure: metabolite.saturatedVapourPressure,
                DT50Sediment: metabolite.halfLifeinSediment,
                DT50Water: metabolite.halfLifeInWater,
                waterSolubility: metabolite.solubilityInWater,
                cropDriftInterceptionPk: run.applicationSchemeData.cropDriftInterceptionPk,
                soilDepthIncorporated: run.applicationSchemeData.soilDepth,
                chemicalApplicationMethod: run.applicationSchemeData.chemicalApplicationMethod,
                nozzleDriftReduction10m: run.applicationSchemeData.nozzleDriftReduction10m ?? 0,
                nozzleDriftReduction20m: run.applicationSchemeData.nozzleDriftReduction20m ?? 0,
                fractionInSurfaceWater: this.getFormationFraction(metabolite.precursors, Constants.FIELD_NAMES.FRACTION_IN_SURFACE_WATER),
                fractionInSediment: this.getFormationFraction(metabolite.precursors, Constants.FIELD_NAMES.FRACTION_IN_SEDIMENT),
                fractionInSoil: this.getFormationFraction(metabolite.precursors, Constants.FIELD_NAMES.FRACTION_IN_SOIL),
                extraFractionInSurfaceWater: { formationFraction: 0, precursor: "" },
                extraFractionInSediment: { formationFraction: 0, precursor: "" },
                extraFractionInSoil: { formationFraction: 0, precursor: "" },
                runSwanModel: run.runSwanModel,
                metabolites: [],
            } as SwashInputs);
        });
        return swashInputsArray;
    }

    static getFormationFraction(precursors: any[], fractiopnName: string) {
        if (!precursors)
            return { formationFraction: 0, precursor: "" }

        var dataValue = precursors.find(obj => obj.precursorType === fractiopnName);
        if (dataValue !== undefined) {
            return {
                formationFraction: dataValue.formationFraction,
                precursor: dataValue.substanceName
            }
        }
        else { return { formationFraction: 0, precursor: "" } };
    }
    static createSoilObject(runs: Run[], selectedProject: Project | undefined): SoilInputs[] {
        var soilObjectArray: SoilInputs[] = [];
        runs.forEach(run => {
            var soilObjectInputs: SoilInputs = {
                jobID: run.id,
                formulation: selectedProject?.formulation,
                crop: run.cropInterceptionName,
                applicationScheme: run.applicationSchemeData?.name,
                applicationRate: run.applicationSchemeData?.applicationSchemeXActiveIngredientRate?.find((x: ApplicationSchemeXActiveIngredientRate) => x.moleculePk === run.activeIngredientPk)?.rate,
                isParent: true,
                activeSubstance: run.substanceName,
                metabolite: run.substanceName,
                pathway: run.dataSet?.description,
                molecularWeight: run.molecularWeight ?? 0,
                parentMolecularWeight: run.molecularWeight ?? 0,
                maximumOccurance: run.maximumOccurrenceInSoil ?? 0,
                koc: run.kfoc ?? 0,
                degT50: run.halfLifeInSoil ?? 0,
                degT90: this.calculateDegT90(run.k1) ?? 0,
                k1: run.k1 ?? 0,
                k2: run.k2 ?? 0,
                alpha: run.alpha ?? 0,
                beta: run.beta ?? 0,
                tb: run.tb ?? 0,
                g: run.g ?? 0,
                models: run.kineticModel ?? "",
                cropInterception: run.applicationSchemeData.cropInterception ?? 0,
                tillage: run.applicationSchemeData.tillage,
                gaps: this.getGapsByRun(run),
                numApplication: run.applicationSchemeData.numberOfApplications ?? 0,
                applicationInterval: run.applicationSchemeData.applicationInterval ?? 0,
                metabolites: this.getSoilMetabolitesByRun(run, selectedProject),
                bbch: run.applicationSchemeData.bbchEarliest ?? "",
                initialSoilDepth: run.applicationSchemeData.soilDepth ?? 0,
                plateauSoilDepth: run.applicationSchemeData.plateauSoilDepth ?? 0,
                simulationYear: run.applicationSchemeData.simulationYear ?? 0,
                model: run.model?.name,
                bbchRange: run.bbchRange ?? "",
            };
            soilObjectArray.push(soilObjectInputs);
            soilObjectArray[soilObjectArray.length - 1].metabolites?.unshift(JSON.parse(JSON.stringify(soilObjectArray[soilObjectArray.length - 1])))
        });
        return soilObjectArray;
    }

    static getSoilMetabolitesByRun(run: Run, selectedProject: Project | undefined): SoilInputs[] {
        var soilObjectArray: SoilInputs[] = [];
        run.metabolites.forEach((metabolite: any) => {
            var soilMetaboliteInputs: SoilInputs = {
                jobID: run.id,
                formulation: selectedProject?.formulation,
                crop: run.cropInterceptionName,
                applicationScheme: run.applicationSchemeData?.name,
                applicationRate: run.applicationSchemeData?.applicationSchemeXActiveIngredientRate?.find((x: ApplicationSchemeXActiveIngredientRate) => x.moleculePk === run.activeIngredientPk)?.rate,
                isParent: false,
                activeSubstance: run.substanceName,
                metabolite: metabolite.substanceName,
                pathway: run.dataSet?.description,
                molecularWeight: metabolite.molecularWeight ?? 0,
                parentMolecularWeight: run.molecularWeight ?? 0,
                maximumOccurance: metabolite.maximumOccurrenceInSoil ?? 0,
                koc: metabolite.kfoc ?? 0,
                degT50: metabolite.halfLifeInSoil ?? 0,
                degT90: this.calculateDegT90(metabolite.k1) ?? 0,
                k1: metabolite.k1 ?? 0,
                k2: metabolite.k2 ?? 0,
                alpha: metabolite.alpha ?? 0,
                beta: metabolite.beta ?? 0,
                tb: metabolite.tb ?? 0,
                g: metabolite.g ?? 0,
                models: metabolite.kineticModel ?? "",
                cropInterception: run.applicationSchemeData.cropInterception ?? 0,
                numApplication: run.applicationSchemeData.numberOfApplications ?? 0,
                applicationInterval: run.applicationSchemeData.applicationInterval ?? 0,
                tillage: run.applicationSchemeData.tillage,
                bbch: run.applicationSchemeData.bbchEarliest ?? "",
                initialSoilDepth: run.applicationSchemeData.soilDepth ?? 0,
                plateauSoilDepth: run.applicationSchemeData.plateauSoilDepth ?? 0,
                simulationYear: run.applicationSchemeData.simulationYear ?? 0,
                gaps: this.getGapsByRun(run),
                model: run.model?.name,
                bbchRange: run.bbchRange ?? ""
            };
            soilObjectArray.push(soilMetaboliteInputs);
        });
        return soilObjectArray;
    }

    static calculateK1(degT50: number | undefined): number {
        if (degT50 === undefined) degT50 = 0;
        return Math.log(2) / degT50;
    }

    static calculateK2(degT50: number | undefined, degT90: number | undefined): number {
        if (degT50 === undefined) degT50 = 0;
        if (degT90 === undefined) degT90 = 0;
        return Math.log(10) / (degT90 - degT50);
    }

    static calculateG(degT90: number | undefined, degT50: number | undefined): number {
        if (degT90 === undefined) degT90 = 0;
        if (degT50 === undefined) degT50 = 0;
        return Math.log(degT90 / degT50) / Math.log(2);
    }

    static calculateTb(degT90: number | undefined, degT50: number | undefined): number {
        if (degT90 === undefined) degT90 = 0;
        if (degT50 === undefined) degT50 = 0;
        return (degT90 - degT50) / Math.log(2);
    }

    static calculateDegT50(k: number | undefined, tb: number | undefined): number {
        if (k === undefined) k = 0;
        if (tb === undefined) tb = 0;
        return k * tb;
    }

    static calculateDegT90(k1: number | undefined): number | undefined {
        if (k1 === undefined) {
            return undefined;
        }
        return Math.log(10) / k1;
    }

    static calculateAlpha(degT50: number | undefined, degT90: number | undefined): number {
        if (degT50 === undefined) degT50 = 0;
        if (degT90 === undefined) degT90 = 0;
        return (degT90 - degT50) / (Math.log(10) - Math.log(2));
    }

    static calculateBeta(alpha: number | undefined, degT50: number | undefined): number {
        if (alpha === undefined) alpha = 0;
        if (degT50 === undefined) degT50 = 0;
        return alpha * degT50;
    }

    static createStep1n2Object(runs: Run[], selectedProject: Project | undefined) {
        var step1n2InputsArray: Step1n2Inputs[] = [];
        runs.forEach(run => {
            var step1n2inputs: Step1n2Inputs = {
                runID: run.id,
                activeSubstance: run.substanceName,
                applicationInterval: run.applicationSchemeData?.applicationInterval,
                applicationRate: run.applicationSchemeData?.applicationSchemeXActiveIngredientRate?.find((x: ApplicationSchemeXActiveIngredientRate) => x.moleculePk === run.activeIngredientPk)?.rate,
                applicationScheme: run.applicationSchemeData?.name,
                applicationSchemePk: run.applicationSchemeData?.applicationSchemePk,
                crop: run.globalCropName,
                cropCoverage: run.applicationSchemeData?.cropCoverage,
                cropInterception: Utils.convertToRange(run.applicationSchemeData?.cropInterception),
                distanceToWaterBody: run.distanceToWater,
                DT50: run.halfLifeInSedimentWaterSystem,
                DT50Sediment: run.halfLifeinSediment,
                DT50Soil: run.halfLifeInSoil,
                DT50Water: run.halfLifeInWater,
                formulation: selectedProject?.formulation,
                isParent: true,
                kocCompound: run.kfoc,
                location: run.regionAndSeason?.name,
                maxConcSoil: run.maximumOccurrenceInSoil,
                maxConcWaterSed: run.maximumOccurrenceInWaterSediment,
                metabolite: run.substanceName,
                molecularMassActive: run.molecularWeight,
                numApplications: run.applicationSchemeData?.numberOfApplications,
                pathway: run.dataSet?.description,
                waterSolubility: run.solubilityInWater,
                sprayDriftPercentage_Single: (run.drift && run.drift.length > 0) ? run.drift[0] : undefined,
                sprayDriftPercentage: (run.drift && run.drift.length > 0) ? run.drift[run.applicationSchemeData?.numberOfApplications - 1] : undefined,
                earliestBbch: run.applicationSchemeData?.bbchEarliest,
                latestBbch: run.applicationSchemeData?.bbchEarliest,
                metabolites: this.getStep1n2MetabolitesByRun(run, selectedProject),
                bbchRange: run.bbchRange,
                dataSetPk: run.dataSet?.dataSetPk,
            }
            step1n2InputsArray.push(step1n2inputs)
        });
        return step1n2InputsArray;
    }

    static getStep1n2MetabolitesByRun(run: any, selectedProject: Project | undefined): Step1n2Inputs[] {
        var step1n2InputsArray: Step1n2Inputs[] = [];
        run.metabolites.forEach((metabolite: any) => {
            var step1n2inputs: Step1n2Inputs = {
                runID: run.id,
                activeSubstance: run.substanceName,
                applicationInterval: run.applicationSchemeData?.applicationInterval,
                applicationRate: run.applicationSchemeData?.applicationSchemeXActiveIngredientRate?.find((x: ApplicationSchemeXActiveIngredientRate) => x.moleculePk === run.activeIngredientPk)?.rate,
                applicationScheme: run.applicationSchemeData?.name,
                applicationSchemePk: run.applicationSchemeData?.applicationSchemePk,
                crop: run.globalCropName,
                cropCoverage: run.applicationSchemeData?.cropCoverage,
                cropInterception: Utils.convertToRange(run.applicationSchemeData?.cropInterception),
                distanceToWaterBody: run.distanceToWater,
                DT50: metabolite.halfLifeInSedimentWaterSystem,
                DT50Sediment: metabolite.halfLifeinSediment,
                DT50Soil: metabolite.halfLifeInSoil,
                DT50SoilParent: run.halfLifeInSoil,
                DT50Water: metabolite.halfLifeInWater,
                formulation: selectedProject?.formulation,
                isParent: false,
                kocCompound: metabolite.kfoc,
                maxConcSoil: metabolite.maximumOccurrenceInSoil,
                maxConcWaterSed: metabolite.maximumOccurrenceInWaterSediment,
                metabolite: metabolite.substanceName,
                molecularMassActive: metabolite.molecularWeight,
                molecularMassCalc: metabolite.molecularWeight,
                numApplications: run.applicationSchemeData?.numberOfApplications,
                pathway: run.dataSet?.description,
                kocParentCompound: run.kfoc,
                waterSolubility: metabolite.solubilityInWater,
                sprayDriftPercentage_Single: (run.drift && run.drift.length > 0) ? run.drift[0] : undefined,
                sprayDriftPercentage: (run.drift && run.drift.length > 0) ? run.drift[run.applicationSchemeData?.numberOfApplications - 1] : undefined,
                location: run.regionAndSeason?.name,
                earliestBbch: run.applicationSchemeData?.bbchEarliest,
                latestBbch: run.applicationSchemeData?.bbchEarliest,
                bbchRange: run.bbchRange,
                dataSetPk: run.dataSet?.dataSetPk,
            }
            step1n2InputsArray.push(step1n2inputs);
        });
        return step1n2InputsArray;
    }


    static async createUKObject(runs: Run[], selectedProject: Project | undefined): Promise<UKInputs[]> {
        var UKInputsArray: UKInputs[] = [];
        return new Promise<UKInputs[]>((resolve, reject) => {
            this.cropListService.getAllCropsList().subscribe((crops: CropList[]) => {
                runs.forEach(run => {
                    var ukInputs: UKInputs = {
                        runID: run.id,
                        activeSubstance: run.substanceName,
                        applicationInterval: run.applicationSchemeData?.applicationInterval,
                        appRate: run.applicationSchemeData?.applicationSchemeXActiveIngredientRate?.find((x: ApplicationSchemeXActiveIngredientRate) => x.moleculePk === run.activeIngredientPk)?.rate,
                        applicationScheme: run.applicationSchemeData?.name,
                        modelApplicationMethod: run.applicationSchemeData?.cropSprayPk,
                        cropDriftGroup: crops.find(x => x.CropListPk == run.applicationSchemeData?.cropSprayPk)?.CropName,
                        cropDrain: crops.find(x => x.CropListPk == run.applicationSchemeData?.cropInterceptionPk)?.CropName,
                        firstApplicationDate: Utils.formatIsoDateToCustom(run.applicationSchemeData.applicationSchemeXApplicationWindow[0].firstDate),
                        cropCoverage: run.applicationSchemeData?.cropCoverage,
                        cropInterception: run.applicationSchemeData?.cropInterception,
                        soilDT50: run.halfLifeInSoil,
                        waterDT50: run.halfLifeInWater,
                        sedimentDT50: run.halfLifeinSediment,
                        BBCH: run.applicationSchemeData?.bbchEarliest,
                        formulation: selectedProject?.formulation,
                        isParent: true,
                        kfoc: run.kfoc,
                        maxSediment: run.maximumOccurrenceInSediment,
                        maxWater: run.maximumOccurrenceInWater,
                        metabolite: run.substanceName,
                        applicationNumber: run.applicationSchemeData?.numberOfApplications,
                        pathway: run.dataSet?.description,
                        metabolites: this.getMetabolitesByUKRun(run, selectedProject, crops),
                        molecularWeight: run.molecularWeight,
                        parentMolecularWeight: run.molecularWeight,
                        maxSoil: run.maximumOccurrenceInSoil
                    }
                    UKInputsArray.push(ukInputs)
                });
                resolve(UKInputsArray);
            })
        })
    }

    static getMetabolitesByUKRun(run: Run, selectedProject: Project | undefined, crops: CropList[]): UKInputs[] {
        var UKInputsArray: UKInputs[] = [];
        run.metabolites.forEach((metabolite: any) => {
            UKInputsArray.push({
                appRate: run.applicationSchemeData?.applicationSchemeXActiveIngredientRate?.find((x: ApplicationSchemeXActiveIngredientRate) => x.moleculePk === run.activeIngredientPk)?.rate,
                isParent: false,
                parentMolecularWeight: run.molecularWeight,
                applicationNumber: run.applicationSchemeData?.numberOfApplications,
                repeatInterval: run.applicationSchemeData?.applicationInterval ?? 0,
                substanceName: metabolite.substanceName,
                metabolite: metabolite.substanceName,
                cropDriftGroup: crops.find(x => x.CropListPk == run.applicationSchemeData?.cropSprayPk)?.CropName,
                cropDrain: crops.find(x => x.CropListPk == run.applicationSchemeData?.cropInterceptionPk)?.CropName,
                activeSubstance: run.substanceName,
                applicationDayOfYearString: [""],
                applicationScheme: run.applicationSchemeData?.name,
                applicationType: run.applicationSchemeData?.applicationMethod,
                compound: metabolite.substanceName,
                crop: run.globalCropName,
                cropPk: run.globalCropPk,
                degradationDT50: metabolite.halfLifeInSoil,
                exponent: metabolite.exponent,
                firstDayLong: Utils.formatIsoDateToCustom(run.applicationSchemeData.firstDate),
                formulation: selectedProject?.formulation,
                koc: metabolite.kfoc,
                lastDayLong: Utils.formatIsoDateToCustom(run.applicationSchemeData.endDate),
                metabolites: [],
                molecularWeight: metabolite.molecularWeight,
                cropInterception: run.applicationSchemeData?.cropInterception,
                model: run.model?.name,
                numberOfApplications: run.applicationSchemeData.numberOfApplications,
                pathway: run.dataSet?.description,
                plantUptake: run.coefficientForUptakeByPlant ?? 0,
                rainfallFile: "",
                season: run.applicationSchemeData.season,
                sorptionValue: metabolite.kfoc,
                soilDepthIncorporated: run.applicationSchemeData.soilDepth,
                vapourPressure: run.saturatedVapourPressure,
                waterSolubility: metabolite.solubilityInWater,
                precursors: metabolite.precursors,
                soilDT50: metabolite.halfLifeInSoil,
                waterDT50: metabolite.halfLifeInWater,
                sedimentDT50: metabolite.halfLifeinSediment,
                kfoc: metabolite.kfoc,
                maxSediment: metabolite.maximumOccurrenceInSediment,
                maxWater: metabolite.maximumOccurrenceInWater,
                maxSoil: metabolite.maximumOccurrenceInSoil,
                gaps: []
            } as MacroGWInputs);
        });
        return UKInputsArray;
    }

    static groupRunsByModel(runs: Run[]): { [key: string]: Run[] } {
        return runs.reduce((group: { [key: string]: Run[] }, item) => {
            if (!group[item.model!.name]) {
                group[item.model!.name] = [];
            }
            group[item.model!.name].push(item);
            return group;
        }, {});
    }

    static returnsListValuesRepeatedByNumOfApplications(numOfApplications: number, value: number): string[] {
        let intervals: string[] = [];
        for (let i = 0; i < numOfApplications; i++) {
            intervals.push(String(value));
        }
        return intervals;
    }

    static getGapsByRun(run: Run): Gap[] {
        let gap: Gap = {
            activeIngredientRates: [] = [],
            gapPk: 0,
            applicationSchemePk: 0,
            cropInterception: 0
        };
        gap.applicationDate = Utils.formatIsoDateToCustom(run.applicationSchemeData.firstDate);
        gap.cropGrowthStageInit = undefined;
        gap.applicationIntervalMinPk = run.applicationIntervalMinPk;
        gap.dateType = run.applicationSchemeData.dateType;
        gap.cropInterception = run.applicationSchemeData.cropInterception;
        gap.minBBCH = run.applicationSchemeData.bbchEarliest;
        gap.bbchRange = run.bbchRange;
        gap.daysSince = run.applicationSchemeData.daysSince;
        gap.rate = run.applicationSchemeData.applicationSchemeXActiveIngredientRate?.find((ai: ApplicationSchemeXActiveIngredientRate) => ai.moleculePk === run.activeIngredientPk)?.rate;
        let ais: ActiveIngredientRate[] = []
        run.applicationSchemeData.applicationSchemeXActiveIngredientRate.filter((ai: ApplicationSchemeXActiveIngredientRate) => run.activeIngredientPk == ai.moleculePk).forEach((AI: ApplicationSchemeXActiveIngredientRate) => {
            if (AI.moleculePk === run.activeIngredientPk) {
                ais.push({
                    activeIngredientRateXGapPk: 0,
                    activeIngredientPk: AI.moleculePk,
                    max: AI.rate,
                    activeIngredientName: AI.substanceName,
                });
            }
        });
        gap.activeIngredientRates = ais;
        let gaps: Gap[] = [gap];
        let interval = 0;
        for (let i = 1; i < run.applicationSchemeData.numberOfApplications; i++) {
            let otherGap: Gap = JSON.parse(JSON.stringify(gap))
            interval += run.applicationSchemeData.applicationInterval;
            otherGap.applicationDate = Utils.addDaysToDate(gap.applicationDate, interval);
            gaps.push(otherGap);
        }
        return gaps;
    }

    static async createPWCObject(pwcData: any[]): Promise<PWCInputs[]> {
        let pwcInputs: PWCInputs[] = [];
        let daugther: Chemical;
        let grandDaughter: Chemical;
        let cropStage: ApplicationSchemeCropState[] = this.setApplicationSchemeCropState(pwcData, this.geographiesList);
        await this.getPWCScenarioFiles(cropStage, pwcData).then(results => {

            let scenarioPaths: AppSchemePkXScenarioPath[] = results;
            if (scenarioPaths) {
                pwcData.forEach(run => {
                    if (run.model.name === Constants.MODELS.PWC) {
                        var input: PWCInputs = {
                            runID: Utils.getSaveString(run.id),
                            applications: this.pwcCreateApplicationsObject(run),
                            simulationType: this.gapLogicService.pwcGetSimulationType(run?.applicationSchemeData.simulationType),
                            parent: this.gapLogicService.pwcCreateChemicalObject(run),
                            daugther: run.metabolites.length > 0 ? this.gapLogicService.pwcCreateChemicalObject(run.metabolites[0]) : daugther,
                            granddaughter: run.metabolites.length > 1 ? this.gapLogicService.pwcCreateChemicalObject(run.metabolites[1]) : grandDaughter,
                            scenarioPath: Utils.getSaveString(scenarioPaths.find(x => x.applicationSchemePK === run?.applicationSchemeData.applicationSchemePk)?.scenarioString ?? "")
                        }

                        pwcInputs.push(input);
                    }
                });
            }
        });
        return pwcInputs;
    }

    static getPWCScenarioFiles(applicationSchemeCropsStates: ApplicationSchemeCropState[], pwcData: any[]): Promise<AppSchemePkXScenarioPath[]> {
        return new Promise<AppSchemePkXScenarioPath[]>((resolve, reject) => {
            applicationSchemeCropsStates.filter((a: { applicationSchemePk: any; }) => {
                pwcData.some((r: { model: { name: string; }; applicationSchemePk: any; }) => r.model.name == 'PWC' && r.applicationSchemePk == a.applicationSchemePk);
            });
            this.gapLogicApi.getScenarioFilesPaths(applicationSchemeCropsStates).subscribe({
                next: (results: AppSchemePkXScenarioPath[]) => {
                    resolve(results);
                },
                error: (error: Error) => {
                    reject(error);
                }
            });
        });
    }

    static pwcCreateApplicationsObject(run: any): Applications {
        const applicationMethods: ApplicationMethod[] = [];
        if (run?.applicationSchemeData?.applicationSchemeXApplication)
            run?.applicationSchemeData?.applicationSchemeXApplication.forEach((application: any) => {
                applicationMethods.push(this.gapLogicService.pwcCreateApplicationMethodObject(application));
            });
        const applications: Applications = {
            numberOfApplications: Utils.getSaveNumber(run?.applicationSchemeData?.applicationSchemeXApplication[0].application_number),
            absolute: run?.applicationSchemeData.dateType == 'Absolute' ? true : false,
            areRelativeTo: run?.applicationSchemeData.cropEvent == 'Emerge' ? 1 : run?.applicationSchemeData.cropEvent == 'Maturity' ? 2 : 3,
            specifyYears: Utils.getSaveBoolean(run?.applicationSchemeData.specifyYears),
            applicationOccurEvery: Utils.getSaveNumber(run?.applicationSchemeData.application_occurrance),
            applicationOccurFromYear: Utils.getSaveNumber(run?.applicationSchemeData.application_ocurrance_from_year),
            applicationOccurToYear: Utils.getSaveNumber(run?.applicationSchemeData.application_ocurrance_to_year),
            windowDays: Utils.getSaveNumber(run?.applicationSchemeData.apply_pesticide_over_a_time_windows),
            stepDays: Utils.getSaveNumber(run?.applicationSchemeData.apply_pesticide_over_a_time_windows_steps),
            applicationMethods: applicationMethods
        }
        return applications;
    }

    static setApplicationSchemeCropState(data: any, geographiesList: Catalog[]): ApplicationSchemeCropState[] {
        let result: ApplicationSchemeCropState[] = [];
        data?.forEach((x: any) => {
            let applicationSchemeCropState: ApplicationSchemeCropState = {
                applicationSchemePk: x.applicationSchemeData.applicationSchemePk,
                crop: x?.globalCropName,
                state: geographiesList.find(reg => reg.key === x?.applicationSchemeData?.regionPk)?.name ?? ''
            };
            result.push(applicationSchemeCropState);
        });
        return result;
    }

    static getFormationFractionByKey(precursors: FractionTransformedXEndpoint[], fractionName: string) {
        if (!precursors)
            return [{ formationFraction: 0, precursor: "" }];

        let precursorsFiltered = precursors?.filter(precursor => precursor.precursorType === fractionName)?.map((x: FractionTransformedXEndpoint) => ({
            formationFraction: x.formationFraction,
            precursor: x.substanceName
        }));

        return precursorsFiltered?.length > 0 ? precursorsFiltered : [{ formationFraction: 0, precursor: "" }];
    }
}