import { Component, DestroyRef, Input, inject } from '@angular/core';
import { Message } from 'primeng/api';
import { EndpointDatasetLogicService } from 'src/app/shared/components/endpoint-dataset/endpoint-dataset.logic.service';
import { Compartment } from 'src/app/shared/models/echo/compartment';
import { CustomTabMenuItem } from 'src/app/shared/models/custom-tab-menu-item';
import { Project } from 'src/app/shared/models/project';
import { DataSetApiService } from 'src/app/shared/services/echo/data-set.api.service';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';
import { HHRALogicService } from '../../human-health-risk-assessment/human-health-risk-assessment.logic.service';
import { HHRAEndpointsLogicService } from '../../human-health-risk-assessments-endpoints/human-health-risk-assessment-endpoints/human-health-risk-assessment-endpoints.logic.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Constants } from 'src/app/shared/utils/constants';

@Component({
  selector: 'app-human-health-risk-assessment-endpoints',
  templateUrl: './human-health-risk-assessment-endpoints.component.html',
  styleUrls: ['./human-health-risk-assessment-endpoints.component.css']
})
export class HumanHealthRiskAssessmentEndpointsComponent {
  @Input() selectedProject?: Project;
  @Input() menuService!: TabMenuLogicService;
  @Input() isInverseModeling: boolean = false;

  activeItem!: any;
  compartmentList: Compartment[] = [];
  destroyRef = inject(DestroyRef)
  afterSearch: boolean = false;
  message!: Message;
  loading: boolean = false;
  loadingWizard: boolean = false;
  nextIndex?: number;
  tabsToDisplay: CustomTabMenuItem[] = [];
  isProjectOwnershipValid: boolean = false;
  isOpex:boolean=false;
  isExpoTox:boolean=false;
  constructor(private datasetService: DataSetApiService,
    private HHRALogicService: HHRALogicService,
    public HHRAEndpointsLogicService: HHRAEndpointsLogicService,
    private readonly endpointDataSetLogicService: EndpointDatasetLogicService,
    private userLogicService: UserLogicService) { }
    ngOnInit(): void {
      let isInit = true;
      if(this.selectedProject?.geography==Constants.GEOGRAPHYS.EUROPE_UNION){
        this.isOpex = true;
      }
      if(this.selectedProject?.geography==Constants.GEOGRAPHYS.USA){
        this.isExpoTox = true;
      }
      this.setLoadingState(true);

      this.isProjectOwnershipValid = this.userLogicService.verifyProjectOwnership(this.selectedProject?.createdBy);

      this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.menuService.setSuccess(true));
      if (!this.isInverseModeling) {
        this.HHRALogicService.usedModelsSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((usedModels: string[]) => {
          this.tabsToDisplay = this.HHRAEndpointsLogicService.items.filter(item => usedModels.includes(item.label));
        });
      }

      this.HHRAEndpointsLogicService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.HHRAEndpointsLogicService.setSuccess(true));
      this.HHRAEndpointsLogicService.activeItem.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(activeItem => this.activeItem = activeItem);
      this.HHRAEndpointsLogicService.success.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(success => {
        this.loadingWizard = false;
        if (success) {
          const nextItem = this.HHRAEndpointsLogicService.items.find(i => i.index == this.nextIndex);
          if (nextItem && !isInit)
            this.HHRAEndpointsLogicService.setActiveItem(nextItem);
        }
        isInit = false;
      });
      this.setLoadingState(false);
      this.getCompartments();
    }

    onActiveIndexChange(index: number) {
      this.nextIndex = index;
      this.HHRAEndpointsLogicService.setActiveIndexChanged(true, index);
    }

    getCompartments() {
      this.datasetService.getCompartments(Constants.CONST_COMPARTMENT).subscribe((compartment => {
        this.compartmentList = compartment;
        this.setLoadingState(false);
      }));
    }

    setLoadingState(pState: boolean): void {
      this.loading = pState;
      this.afterSearch = !pState;
    }

    ngOnDestroy() {
      this.endpointDataSetLogicService.updateSelectedDataSet(undefined);
    }
}
