import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MsalModule, MsalRedirectComponent, MsalGuard, MsalInterceptor, } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { Constants } from './shared/utils/constants';
import { environment } from 'src/environments/environment';
import { AddHeaderInterceptor } from './shared/utils/add-header-interceptor';
import { LayoutModule } from './layout/layout.module';
import { EnvironmentalExposureAssessmentModule } from './environmental-exposure-assessment/environmental-exposure-assessment.module';
import { SharedModule } from './shared/shared.module';
import { AdministratorModule } from './administrator/administrator.module';
import { InverseModelingEnvironmentalExposureAssesmentModule } from './inverse-modeling-environmental-exposure-assesment/inverse-modeling-environmental-exposure-assesment.module';
import { EnvironmentalRiskAssessmentModule } from './environmental-risk-assessment/environmental-risk-assessment.module';
import { HumanHealthRiskAssessmentModule } from './human-health-risk-assessments/human-health-risk-assessment.module';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    SharedModule,
    AdministratorModule,
    LayoutModule,
    EnvironmentalExposureAssessmentModule,
    InverseModelingEnvironmentalExposureAssesmentModule,
    EnvironmentalRiskAssessmentModule,
    HumanHealthRiskAssessmentModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: Constants.CLIENT_ID,
          authority: `https://login.microsoftonline.com/${Constants.TENANT_ID}`,
          redirectUri: environment.redirectUri,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: isIE,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: [`api://${Constants.CLIENT_ID}/user_access`],
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [`${Constants.GRAPH_ENDPOINT}/me`, ['user.read']],
          [
            environment.BAMS_Api_URL,
            [`api://${Constants.CLIENT_ID}/user_access`],
          ],
          [`${Constants.GRAPH_ENDPOINT}/users`, ['user.read.all']],
          [
            environment.ECHO_Api_URL,
            [`api://${Constants.ECHO_CLIENT_ID}/user_access`],
          ],
          [
            environment.GAP_Api_URL,
            [`api://${Constants.GAP_CLIENT_ID}/user_access`],
          ],
        ]),
      }
    ),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },
    MsalGuard,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
