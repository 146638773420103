import Swal, { SweetAlertResult } from "sweetalert2";
import { Compartment } from "../models/compartment";
import { Constants } from "./constants";
import * as moment from "moment";

export abstract class Utils {

  static sortObject(pArray: any, pColumn: string): any {
    return pArray.sort(function (a: any, b: any) {
      if (a[pColumn]?.toLowerCase() < b[pColumn]?.toLowerCase()) {
        return -1;
      }
      if (a[pColumn]?.toLowerCase() > b[pColumn]?.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }
  static convertDate(pParams: any): string {
    if (pParams.value) {
      const date = new Date(pParams.value);
      const day = date.getDate();
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    }
    return '';
  }

  static convertDateWithoutYear(pParams: any): string {
    if (pParams.value) {
      const date = new Date(pParams.value);
      const day: string = date.getDate().toString().length === 1 ? `0${date.getDate()}` : date.getDate().toString();
      const monthNames = Constants.MONTH_SHORT_NAMES;
      const month = monthNames[date.getMonth()]
      return `${day}-${month}`;
    }
    return '';
  }

  static formatDashedStringDate(dashedDate: string | null | undefined): string {
    if (dashedDate) {
      let nextDay = this.getNextDay(dashedDate.substring(0, 10));
      const dateObj = new Date(nextDay);
      const monthNames = Constants.MONTH_SHORT_NAMES;
      const outputDate = dateObj.getDate() + '-' + monthNames[dateObj.getMonth()] + '-' + dateObj.getFullYear();

      return outputDate;
    }
    return "";
  }

  static getEndWindowTakingIntoAccountApplicationIntervals(pOriginalEndWindow: string, pRow: any): string {
    let daysToAdd: number = 0
    pRow.children?.forEach((value: any, index: any) => {
      if (index > 0 && value.activeIngredient === pRow.activeIngredient)
        daysToAdd += parseInt(value.minApplicationInterval)
    });
    return this.addDaysToDate(pOriginalEndWindow, daysToAdd)
  }


  static isEmptyValue(pValue: any) {
    return pValue === null || pValue === undefined || pValue === '';
  }

  static addDaysToDate(inputDate: string, numberOfDays: number): string {
    const date = new Date(inputDate);
    date.setDate(date.getDate() + numberOfDays);
    const formattedDate = date.toISOString().slice(0, 19);
    return formattedDate;
  }

  static convertDateToString = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toISOString().split('.')[0];
  };

  static getNextDay(dateString: string): string {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 2);
    if (date.getMonth() === 0 && date.getDate() === 1) {
      date.setFullYear(date.getFullYear() + 1);
    }

    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }



  static convertNumberToExponential(pValue: any) {
    var shouldConvertToExponential = (Number(pValue) < 1 && Number(pValue) > 0) && (pValue.toString().split(".")[1]?.length >= 3)
    return shouldConvertToExponential ? Number(pValue).toExponential() : pValue;
  }

  static formatToExponential(pValue: any) {
    var hasDecimals = pValue?.toString().split(".")[1]?.length >= 3;
    var isZeroInterval = (Number(pValue) < 1 && Number(pValue) > 0);
    var shouldConvertToExponential = isZeroInterval && hasDecimals;
    return shouldConvertToExponential ? Number(Number(pValue).toFixed(4)).toExponential() : hasDecimals ? Number(pValue).toFixed(4) : pValue;
  }

  static getKomValue(pValue: number): number {
    if (pValue)
      return pValue / 1.724;
    return 0;
  }

  static getDegT90Value(pKfoc: number) {
    if (pKfoc === undefined || pKfoc == null) return undefined;
    return this.formatToExponential(this.calculateDegT90(pKfoc));
  }

  static calculateDegT90(k1: number | undefined): number | undefined {
    if (k1 === undefined) {
      return undefined;
    }
    return Math.log(10) / k1;
  }

  static getDateFormatToSaveData(): string {
    const estDate = this.getEasternTimeDate();
    const strDate = this.getServerStringDate(estDate);
    return strDate;
  }

  static getEasternTimeDate(): Date {
    const date = new Date();
    date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000); // (IST)
    const offset = -300; // Timezone offset for EST in minutes.
    const estDate = new Date(date.getTime() + offset * 60 * 1000);
    return estDate;
  }

  static getServerStringDate(date: Date): string {
    const datetime =
      date.getFullYear() +
      '-' +
      (date.getMonth() < 10 ? '0' : '') +
      date.getMonth() +
      '-' +
      (date.getDate() < 10 ? '0' : '') +
      date.getDate() +
      'T' +
      (date.getHours() < 10 ? '0' : '') +
      date.getHours() +
      ':' +
      (date.getMinutes() < 10 ? '0' : '') +
      date.getMinutes() +
      ':' +
      (date.getSeconds() < 10 ? '0' : '') +
      date.getSeconds();
    return datetime;
  }

  static formatFilterValues(pParams: any, items: any[], compareValue: any, valueToShow: any): string {
    let filterName = items.find((x: any) => x[compareValue] === pParams.value);
    return filterName ? filterName[valueToShow] : pParams.value ? pParams.value : '(Blanks)';
  }

  static percentageFormat(pParams: any) {
    return pParams.value ? `${pParams.value}  %` : '';
  }

  static getDayOfYear(pDate: Date): number {
    return moment(pDate).dayOfYear();
  }

  static getFirstMatchBetweenTwoArrays(array1: any, array2: any) {
    for (const element of array1) {
      if (array2.includes(element)) {
        return element;
      }
    }
    return null;
  }

  static CheckValueInRanges(rangesIput: string, valueToCheck: number): number {
    if (rangesIput == '')
      return valueToCheck;

    const ranges = rangesIput.split('-');
    const minRange: number = Number(ranges[0]);
    const maxRange: number = Number(ranges[1]);
    let value: number = valueToCheck;

    if (valueToCheck < minRange)
      value = minRange;

    if (valueToCheck > maxRange)
      value = maxRange;

    return value;
  }

  static createCompartments(compartments: any | undefined): Compartment[] {
    if (!compartments) {
      return [];
    }
    return compartments.map((x: any) => ({
      compartment: x.compartment,
      name: x.compartment,
      pk: x.endpointCompartmentPk
    }));
  }

  static convertToRange(number: number): number {
    if (number === 0) return 0.00;
    const exponent = Math.floor(Math.log10(Math.abs(number))) + 1;
    if (!isFinite(exponent)) return 0.00;
    return number / Math.pow(10, exponent);
  }

  static getFormatKomValue(pKfoc: number) {
    if (pKfoc === undefined || pKfoc == null) return undefined;
    return this.formatToExponential(this.getKomValue(pKfoc));
  }

  static getFormatKfocValue(kom: number) {
    if (kom === undefined || kom == null) return undefined;
    return this.formatKfoc(this.getKfocValue(kom));
  }

  static formatKfoc(value: any) {
    var hasDecimals = value?.toString().split(".")[1]?.length >= 3;
    var isZeroInterval = (Number(value) < 1 && Number(value) > 0);
    var shouldConvertToExponential = isZeroInterval && hasDecimals;
    return shouldConvertToExponential ? Number(Number(value).toFixed(4)).toExponential() : hasDecimals ? Number(value).toFixed(4) : value;
  }

  static getKfocValue(value: number): number {
    if (value)
      return value * 1.724;
    return 0;
  }

  static formatIsoDateToCustom(isoDate: string): string {
    const months = Constants.MONTH_SHORT_NAMES;

    // Parse the ISO date
    const date = new Date(isoDate);

    // Extract day, month, and year
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Format: dd-MMM-yyyy
    return `${day}-${month}-${year}`;
  }

  static getSaveString(value: string): string {
    let result: string = '';
    if (value)
      result = String(value);
    return result;
  }

  static getSaveNumber(value: string): number {
    let result: number = 0;
    if (!isNaN(result) && value)
      result = Number(value);
    return result;
  }

  static getSaveBoolean(value: string): boolean {
    let result: boolean = false;
    if (value)
      result = value == 'true' ? true : false;

    return result;
  }

  public static showErrorMessage(title: string, text: string): void {
    Swal.fire({
      title: title,
      text: text,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'Ok',
      icon: 'error'
    });
  }

  public static showSuccessMessage(text: string): void {
    Swal.fire({
      text: text,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'Ok',
    });
  }

  public static showConfirmationMessage(text: string): Promise<SweetAlertResult> {
    return Swal.fire({
      text: text,
      showCancelButton: true,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });
  }

  public static showErrorMessageHtml(title: string, html: string): void {
    Swal.fire({
      title: title,
      html: html,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'Ok',
      icon: 'error'
    });
  }

  public static GetEndDateByApplication(pStartDate: string, pApplicationInterval: number, pNumberOfApplications: number): string {
    if (!pApplicationInterval || !pNumberOfApplications)
      return 'undefined';
    return moment(pStartDate).add((30 + ((pNumberOfApplications - 1) * pApplicationInterval)), 'day').format();
  }
}
