import { Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { Project } from 'src/app/shared/models/project';
import { Constants } from 'src/app/shared/utils/constants';
import { EEAGapApiService } from '../eea-gap.api.service';
import { catchError, forkJoin, take } from 'rxjs';
import { Compartment } from 'src/app/shared/models/echo/compartment';
import { EEAGapLogicService } from '../eea-gap.logic.service';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { EEAGAPMenuLogicService } from '../eea-gap/eea-gap-menu.logic.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApplicationDateSelection } from 'src/app/shared/models/application-date';
import { ApplicationDateApiService } from 'src/app/shared/services/application-date.api.service';
import { EEAApplicationDialogComponent } from '../shared/eea-application-dialog/eea-application-dialog.component';
import { ApplicationSchemeXApplicationWindow } from 'src/app/shared/models/application-scheme';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { CropList } from 'src/app/shared/models/crop-list';
import { CropListMatching } from 'src/app/shared/models/crop-list-matching';
import { RowNode, RowSelectedEvent, SelectionChangedEvent } from 'ag-grid-community';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { ApplicationSchemeGap } from '../../eea-output/eea-output-project/eea-output-interfaces/eea-out-put-gap-interface';
import { Utils } from 'src/app/shared/utils/utils';
import { GapApplicationSchemeLogicService } from 'src/app/shared/services/gap-application-scheme.logic.service';
import { SelectableRow } from 'primeng/table';

@Component({
  selector: 'app-eea-gap-non-core-compartment',
  templateUrl: './eea-gap-non-core-compartment.component.html',
  styleUrls: ['./eea-gap-non-core-compartment.component.css']
})

export class EeaGapNonCoreCompartmentComponent implements OnInit, OnChanges {
  @Input() selectedProject?: Project;
  @Input() isInverseModeling: boolean = false;
  @Input() menuService!: TabMenuLogicService;
  @ViewChild('grid') grid!: GridComponent;
  @Input() isProjectOwnershipValid: boolean = false;

  @Output() public isValid = new EventEmitter<boolean>();

  @ViewChild('appDialog') applicationDialog!: EEAApplicationDialogComponent;
  compartmentList: Compartment[] = [];
  columsDef: any;
  loading: boolean = false;
  rowData: any;
  compartmentPk: number = 0;
  molecules: any[] = [];
  isCore: boolean = false;
  compartment: string = '';
  datafieldOptions: any = {};
  fixedColumns: any[] = ['deleteButton', 'appWindow', 'expandButton'];
  geographiesList: any[] = [];
  rowHeight: number = 45;
  destroyRef = inject(DestroyRef);
  geographies: Catalog[] = [];
  selectedApplicationWindowRow?: RowNode;
  applicationDatesByCrop: any[] = [];
  cropList: CropList[] = [];
  cropListMatchings: CropListMatching[] = [];
  selectedModels: string[] = [];
  detailsColumsDef: any;
  applicationScheme: ApplicationSchemeGap = {} as ApplicationSchemeGap;

  constructor(private gapApiService: EEAGapApiService,
    private gapLogicService: EEAGapLogicService,
    public EEAGAPMenuLogicService: EEAGAPMenuLogicService,
    private applicationDateApiService: ApplicationDateApiService,
    public datepipe: DatePipe,
    private gapApplicationSchemeLogicService: GapApplicationSchemeLogicService) {
    this.loading = this.gapLogicService.loading;
  }

  ngOnInit(): void {
    this.EEAGAPMenuLogicService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (!this.grid) return;
      this.saveData(this.grid.transactionList);
    });

    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (!this.grid) return;
      this.saveData(this.grid.transactionList);
    });

    this.setLoadingState(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedProject'].currentValue.projectPk) {
      this.fillInitValues(changes);
    }
  }

  public fillInitValues(changes: SimpleChanges): void {
    let promises = [
      this.gapLogicService.getCompartments(),
      this.gapLogicService.getDataFieldValueByListName(['Application Method', 'Model Chemical Application Method']),
      this.gapLogicService.getRegionCountry(changes['selectedProject'].currentValue)
    ]
    Promise.all(promises)
      .then((results) => {
        this.compartmentList = results[0];
        this.datafieldOptions = results[1];
        this.geographiesList = results[2];
        this.getApplicationSchemesByProjectAndCompartment(changes['selectedProject'].currentValue.projectPk, this.getCoreCompartment());
      })
  }

  getCoreCompartment() {
    this.compartmentPk = Number(this.compartmentList?.filter((x: any) => x.compartment == this.compartment)?.map(x => x.endpointCompartmentPk).flat());
    return this.compartmentPk;
  }

  setLoadingState(pState: boolean): void {
    this.loading = pState;
  }

  getData() {
    this.getApplicationSchemesByProjectAndCompartment(this.selectedProject?.projectPk!, this.compartmentPk);
  }

  getApplicationSchemesByProjectAndCompartment(projectPk: number, compartmentPk: number) {
    if (projectPk != 0) {
      this.loading = true;
      forkJoin({
        applicationSchemes: this.gapApiService.getApplicationSchemesByProjectAndCompartment(projectPk, compartmentPk)
      })
        .pipe(
          catchError((error) => {
            console.error(error);
            this.loading = false;
            return [];
          })
        )
        .subscribe({
          next: ({ applicationSchemes }) => {
            if (!this.selectedProject) return;
            this.gapLogicService.addActiveIngredientsRatesToGrid(applicationSchemes, this.getColDefFilteredBySelectedModels(), this.isInverseModeling, this.selectedProject, this.isProjectOwnershipValid, "").then(() => {
              this.rowData = this.gapLogicService.rowData;
              this.columsDef = this.gapLogicService.columsDef;
              this.setLoadingState(false);
            });
          },
        });
    }
  }

  getColDefFilteredBySelectedModels() { }

  saveData(dataTransaction: any[]) {
    this.gapLogicService.createTransactionsForRates(dataTransaction, this.grid, this.isInverseModeling);
    if (dataTransaction.length == 0) {
      this.EEAGAPMenuLogicService.setSuccess(true);
      this.menuService.setSuccess(true);
      return;
    }

    if (!this.selectedProject) return;
    this.gapLogicService.setParametersToNewRows(dataTransaction, this.compartmentPk, this.isInverseModeling, this.selectedProject?.projectPk, this.isCore, this.compartment, this.cropListMatchings, this.selectedProject?.geography);
    this.gapLogicService.setActiveIngredientsRate(dataTransaction, this.isCore, this.compartmentList, this.isInverseModeling, this.compartment);
    this.gapLogicService.setApplicationWindow(dataTransaction, this.compartment);
    this.gapApiService.save(dataTransaction).pipe(take(1)).subscribe({
      next: () => {

        this.grid.afterSaveSuccess();
        this.EEAGAPMenuLogicService.setSuccess(true);
        this.menuService.setSuccess(true);
      },
      error: (err: any) => {
        console.warn(err);
        this.EEAGAPMenuLogicService.setSuccess(false);
        this.menuService.setSuccess(false);
      }
    });
  }

  onModelOptionsChanged(models: string[]) {
    this.selectedModels = models;
    if (this.grid) {
      const columns: any[] = this.gapLogicService.getDisplayedColumnsByModel(models, this.selectedProject?.geography!);
      const displayedColumns = this.grid?.gridColumnApi.getColumns();
      const fixedColumns = models.includes(Constants.MODELS.SWASH) || models.includes(Constants.MODELS.SWAN) || models.includes(Constants.MODELS.MACRO_GW) || models.includes(Constants.MODELS.PEARL) || models.includes(Constants.MODELS.PELMO) ? ['deleteButton', 'appWindow', 'expandButton'] : ['deleteButton'];
      let columnsToShow: string[] = [...Constants.GAP_CORE_FIELDS, ...new Set(columns), ...this.gapLogicService.getFixedColumns(displayedColumns, fixedColumns)];
      if (this.isInverseModeling) columnsToShow = [...columnsToShow, ...Constants.GAP_CORE_INVERSE_MODELING_FIELDS];
      columnsToShow = this.removeColumnsByModel(models, columnsToShow);
      displayedColumns.forEach((column: any) => {
        this.grid?.gridColumnApi.setColumnVisible(column.colId, columnsToShow.includes(column.getColDef().field));
        const conditions = [Constants.GAP_INVERSE_MODELING_FIELD_NAMES.MIN_RATE, Constants.GAP_INVERSE_MODELING_FIELD_NAMES.MAX_RATE, Constants.GAP_INVERSE_MODELING_FIELD_NAMES.INCREMENT_RATE];
        if (this.isInverseModeling && conditions.some(x => column.colId.includes(x))) {
          this.grid?.gridColumnApi.setColumnVisible(column.colId, true);
        }
      });
      this.grid?.gridApi.refreshCells();
    }
  }

  removeColumnsByModel(models: string[], columnsToShow: string[]) {
    let columns = this.removeUKColumns(models, columnsToShow);
    columns = this.removeVariableRatesColumns(models, columnsToShow);
    return columns;
  }

  removeUKColumns(models: string[], columnsToShow: string[]) {
    return (JSON.stringify(models) === JSON.stringify(new Array(Constants.MODELS.UK))) ? columnsToShow.filter((x: string) => x !== Constants.GAP_FIELD_NAMES.GEOGRAPHIES) : columnsToShow;
  }

  removeVariableRatesColumns(models: string[], columnsToShow: string[]) {
    return columnsToShow.filter((x: string) => x !== Constants.GAP_FIELD_NAMES.HAS_VARIABLE_RATES && x !== 'expandButton');
  }

  deleteRow(event: any) {
    if (event != undefined) {
      this.gapApiService
        .save(event)
        .pipe(take(1))
        .subscribe(() => { });
    }
    if (this.grid.gridApi.getDisplayedRowCount() === 1) {
      this.grid.gridApi.redrawRows();
    }
  }

  geographiesListBoxSelectionChanged(params: any) {
    if (!this.selectedProject) return;
    let oldValue: any = params.data['geographies'];
    let values = params.selectedValues.map((x: any) => { return { geographyPk: x.key } })
    params.data.applicationSchemeXGeography = values;
    this.grid.CreateTransaction(params.id, params.id, oldValue, params.data);
  }

  onDropDownSelectionChanged(event: any) {
    event.row.geographies = event.row.applicationSchemeXGeography?.length > 0 ? this.geographiesList.filter((x: any) => event.row.applicationSchemeXGeography.map((y: any) => y.geographyPk).includes(x.key)) : [];
    if (event.field === Constants.GAP_FIELD_NAMES.INTERCEPTION_PK || event.field === Constants.GAP_FIELD_NAMES.BBCH)
      this.gapLogicService.setCropInterceptionByCropAndBBCH(event.row, this.compartment);
    if (event.field === Constants.GAP_FIELD_NAMES.DATE_TYPE)
      this.grid.gridApi.redrawRows();
    this.grid.gridApi.refreshCells();
  }

  onClickViewButton(event: any) { }

  getScenariosWithoutCropListPk() { }

  onSaveApplicationDates(selectedAppDates: ApplicationDateSelection) { }

  getCropCoverageObject(pNumber: number) { }

  setCropInterceptionStep1And2(pRow: any) { }

  onFirstDataRendered(params: any): void { }

  createTransactionForApplicationDates(selectedAppDates: ApplicationDateSelection): void {
    let dates = selectedAppDates.selectedRows.map((x: any) => {
      let object: ApplicationSchemeXApplicationWindow = {
        applicationDateSurfaceWaterPk: x.data.applicationDateSurfaceWaterPk,
        firstDate: x.data.beginWindow,
        endDate: x.data.endWindow
      }
      return object;
    })
    let parentRowNode = this.grid.gridApi.getRowNode(selectedAppDates.parentRowNode.id);
    let oldvalue = parentRowNode.data.applicationSchemeXApplicationWindow;
    parentRowNode.data.applicationSchemeXApplicationWindow = dates;
    this.grid.CreateTransaction(parentRowNode.id, parentRowNode.id, oldvalue, parentRowNode.data);
  }

  onCalendarSelectionChanged(event: any): void {
    let parentRowNode = this.grid.gridApi.getRowNode(event.id);
    let appDate: ApplicationSchemeXApplicationWindow = {
      firstDate: new Date(this.datepipe.transform(event.value, Constants.SERVICE_DATE_FORMAT) + 'Z'),
    }
    parentRowNode.data.applicationSchemeXApplicationWindow = [appDate];
    let oldvalue = event.row[event.field];
    event.row[event.field] = this.datepipe.transform(event.value, Constants.SERVICE_DATE_FORMAT);
    this.grid.CreateTransaction(event.id, event.id, oldvalue, event.row);
  }

  getCropValueMatchings(node: RowNode): number | undefined {
    let matching = this.cropListMatchings?.find((x: any) => x.geography == this.selectedProject?.geography && x.compartment == this.compartment && x.gapCrops.includes(node?.data?.cropPk));
    return matching?.appDateCrops.length! > 0 ? matching?.appDateCrops[0]! : undefined;
  }

  validateCoreCropPK(node: RowNode, compartment: string, selectedGeography: string, cropMatchings: CropListMatching[]): boolean {
    let coreMatchingCrop: number[] = [];
    let validateRowCrops: boolean[] = [];

    if (node?.data?.cropAppDatePk) {
      coreMatchingCrop = cropMatchings?.filter((x: CropListMatching) => (x.compartment === compartment) && (x.geography === selectedGeography) && (x.appDateCrops.includes(node?.data?.cropAppDatePk)))?.flatMap(x => x.gapCrops)!;
      validateRowCrops.push(!coreMatchingCrop?.includes(node?.data?.cropPk) ? true : false);
    }
    if (node?.data?.cropDriftInterceptionPk) {
      coreMatchingCrop = cropMatchings?.filter((x: CropListMatching) => (x.compartment === compartment) && (x.geography === selectedGeography) && (x.driftInterceptionCrops.includes(node?.data?.cropDriftInterceptionPk)))?.flatMap(x => x.gapCrops)!;
      validateRowCrops.push(!coreMatchingCrop?.includes(node?.data?.cropPk) ? true : false);
    }
    if (node?.data?.cropInterceptionPk) {
      coreMatchingCrop = cropMatchings?.filter((x: CropListMatching) => (x.compartment === compartment) && (x.geography === selectedGeography) && (x.interceptionCrops.includes(node?.data?.cropInterceptionPk)))?.flatMap(x => x.gapCrops)!;
      validateRowCrops.push(!coreMatchingCrop?.includes(node?.data?.cropPk) ? true : false);
    }
    if (node?.data?.cropSprayPk) {
      coreMatchingCrop = cropMatchings?.filter((x: CropListMatching) => (x.compartment === compartment) && (x.geography === selectedGeography) && (x.sprayCrops.includes(node?.data?.cropSprayPk)))?.flatMap(x => x.gapCrops)!;
      validateRowCrops.push(!coreMatchingCrop?.includes(node?.data?.cropPk) ? true : false);
    }
    return validateRowCrops.some((x: boolean) => x == true);
  }

  onButtonExpandClick(event: any) {
    this.gapLogicService.toggleDetailGrid(event, this.grid);
  }

  newRowAdded(newItems: any[]) {
    this.grid.setLastRowSelected('useInProject');
    this.refreshSelectedValues();
    this.enableControls(false);
  }

  public onBlurInputText({ row }: { row: any }): void {
    this.checkIfApplicationSchemeIsValid(row.name, row.applicationSchemePk);
  }

  private async checkIfApplicationSchemeIsValid(applicationSchemeName: string | undefined, applicationSchemePk: number | undefined): Promise<void> {
    const invalidRows = this.rowData.some((row: any) => row.name == undefined || row.name == '');
    const transactionOcurrences = this.gapApplicationSchemeLogicService.getTransactionOccurrences(this.rowData);
    const duplicateDatasetsInTransaction = this.gapApplicationSchemeLogicService.duplicateDataInRecord(transactionOcurrences);
    applicationSchemePk = applicationSchemePk ?? -1

    if (applicationSchemeName != undefined && applicationSchemeName != '') {
      if (duplicateDatasetsInTransaction)
        Utils.showErrorMessage('The Application Scheme name already exists.', 'Please use another name');
    }

    if (invalidRows || duplicateDatasetsInTransaction)
      this.enableControls(false);
    else
      this.enableControls(true);
  }

  private enableControls(enable: boolean): void {
    this.isValid.emit(enable);
    this.grid.useAddAction = enable;
    this.grid.columnDefs.find((c: any) => c.colId == 'delete').cellRendererParams.disabled = !enable;
    this.grid.gridApi.setColumnDefs(this.columsDef);
  }

  public refreshSelectedValuesTimeout(): void {
    setTimeout(() => {
      this.refreshSelectedValues();
    }, 75);
  }

  public refreshSelectedValues(): void {
    if(! this.grid ) return;
    this.grid.refreshSelectedValues('useInProject');
  }

  public onSelectedRow (event: RowSelectedEvent): void {
    if( !this.grid ) return;
    this.createTransactionForSelectedRow( event.node );
  }

  public onSelectionChanged ( event: SelectionChangedEvent ){
    if( event.source !== 'uiSelectAll' || !this.grid ) return;
    this.grid.gridApi.forEachNode((node: any) => {
     this.createTransactionForSelectedRow(node);
    });
  }

  private createTransactionForSelectedRow( node: any ): void {
    const { id, data } = node;
    data.useInProject = node.isSelected();
    this.grid.CreateTransaction(id, id, data.useInProject, data);
  }
}
