import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoint } from '../../models/endpoint';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RunEndpointFilter } from '../../models/echo/run-endpoint-filter';

@Injectable({
  providedIn: 'root'
})
export class EndpointApiService {
  constructor(private http: HttpClient) {}

  getRunEndpoints(pFilters: RunEndpointFilter): Observable<Endpoint[]> {
    const body = JSON.stringify(pFilters);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `${environment.ECHO_Api_URL}api/runEndpoints`;
    return this.http
      .put(query, body, { headers })
      .pipe(map((data: any) => data.Value));
  }
}
