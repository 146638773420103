import { Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, inject} from '@angular/core';
import { Constants } from '../../utils/constants';
import { DataSet } from '../../models/echo/data-set';
import { EEAEndpointsByCompartmentLogicService } from 'src/app/environmental-exposure-assessment/eea-endpoints/eea-endpoints-by-compartment/eea-endpoints-by-compartment.logic.service';
import { EndpointDatasetColDefService } from './endpoint-dataset.coldef';
import { EndpointDatasetGridTransaction, EndpointDatasetParamsData } from './endpoint-dataset.model';
import { EndpointDatasetLogicService } from './endpoint-dataset.logic.service';
import { GridComponent } from '../grid/grid.component';
import { ImportDataSet } from '../../models/echo/import-data-set';
import { Project } from '../../models/project';
import { ProjectXCompoundXModel } from '../../models/project-x-compound-x-model';
import { TabMenuLogicService } from '../../services/tab-menu.logic.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Utils } from '../../utils/utils';
import { UseEndpointDatasetComponent } from '../use-endpoint-dataset/use-endpoint-dataset.component';
import Swal from 'sweetalert2';
import { EraEcotoxImportPopupParameters } from 'src/app/environmental-risk-assessment/era-endpoints/era-ecotox-import-popup/era-ecotox-import-popup.interface';
import { pipe, take } from 'rxjs';
import { RowSelectedEvent, SelectionChangedEvent } from 'ag-grid-community';


@Component({
  selector: 'app-endpoint-dataset',
  templateUrl: './endpoint-dataset.component.html',
  styleUrls: ['./endpoint-dataset.component.css'],
})
export class EndpointDatasetComponent implements OnInit, OnChanges {
  @Input() public environmentalAssessment!: string;
  @Input() public isProjectOwnershipValid: boolean = false;
  @Input() public menuService!: TabMenuLogicService;
  @Input() public selectedProject?: Project;
  @Input() public subTabMenuService!: TabMenuLogicService;
  @Input() public savedEraDatasetPk?: number;
  @Input() public isSubstanceProperties?: boolean = false;
  @Output() public datasetData = new EventEmitter<DataSet>();
  @Output() public isValid = new EventEmitter<boolean>();
  @Output() public showDetail = new EventEmitter<boolean>();
  @ViewChild('grid') private grid!: GridComponent;
  @ViewChild('appDialog') useExistingDSDialog!: UseEndpointDatasetComponent;
  public blockUi: boolean = false;
  public columnDefs: any = [];
  public isLoading: boolean = false;
  public projectPk: number = 0;
  public rowData: DataSet[] = [];
  public showControls: boolean = true;
  public eraEcotoxImportPopupParameters?: EraEcotoxImportPopupParameters;

  private destroyRef = inject(DestroyRef);
  private projectXCompoundXModel: ProjectXCompoundXModel[] = [];
  private saveRef?: string;
  private selectedDataSetObservable: DataSet | undefined;

  constructor(
    private readonly columnDefsService: EndpointDatasetColDefService,
    private readonly endpointByCompartmentLogicService: EEAEndpointsByCompartmentLogicService,
    private readonly logicService: EndpointDatasetLogicService,

  ) { }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['savedEraDatasetPk'])
      await this.updateStatusFlagWhenEraDestroy();
  }

  public async ngOnInit(): Promise<void> {
    setTimeout(async () => { //TODO Temporal Solution the system is getting the info before the previous save is completed
      this.isLoading = true;
      this.destructureSelectedProject();
      this.setProjectXCompoundXModelLogicService();
      this.logicService.setProjectPk(this.projectPk);
      this.initSubscribes();
      await this.setRowData();
      await this.logicService.setMoleculesFromProjectModels(this.projectXCompoundXModel);
      await this.logicService.getDataseByMoleculesPks(this.getProjectMoleculePks());
      this.setColumnDef();
      this.getSelectedDataSetObservable();
      this.setSelectedDataSetObservable();
      this.refreshSelectedValuesTimeout();
      this.isLoading = false;
    }, 700);
  }

  private destructureSelectedProject(): void {
    const { projectXCompoundXModel, projectPk } = this.selectedProject!;
    this.projectPk = projectPk;
    this.projectXCompoundXModel = projectXCompoundXModel!;
  }

  private setProjectXCompoundXModelLogicService(): void {
    this.logicService.projectXCompoundXModel = this.projectXCompoundXModel;
  }

  private initSubscribes(): void {
    this.menuService.activeIndexChanged
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.saveRef = this.menuService.saveRef;
        this.prepareToSave();
      });

    this.subTabMenuService.activeIndexChanged
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.saveRef = this.subTabMenuService.saveRef;
        this.prepareToSave();
      });
  }

  public async setRowData(): Promise<void> {
    this.rowData = await this.logicService.getDatasets(
      this.projectPk,
      this.environmentalAssessment
    );

    if( !this.grid ) return;

    this.refreshSelectedValuesTimeout();
  }

  private getProjectMoleculePks(): number[] {
    return Array.from(
      new Set(this.projectXCompoundXModel.map((p) => p.MoleculePk!))
    );
  }

  private setColumnDef(): void {
    if (this.isSubstanceProperties) {
      this.columnDefs =
        this.columnDefsService.getColumnsDefinitionSubstancePropertiesOPEX(
          this.isProjectOwnershipValid
        );
    } else {
      this.columnDefs = this.columnDefsService.getColumnsDefinition(
        this.isProjectOwnershipValid
      );
    }
  }

  private async prepareToSave(): Promise<void> {
    let transactionList = this.grid?.transactionList as unknown as EndpointDatasetGridTransaction[];

    if (transactionList === undefined) {
      this.menuAndSubMenuSetSuccess(true);
      return;
    }

    transactionList.forEach((transaction) => {
      const dataset: DataSet = this.logicService.transformRowGridToDataset(
        transaction.row,
        this.environmentalAssessment,
        this.projectPk
      );
      transaction.row = dataset;
    });
    if (transactionList.length > 0) {
      await this.save(transactionList);
    }
  }

  private async save(dataTransaction: EndpointDatasetGridTransaction[]): Promise<void> {
    await this.logicService.save(dataTransaction)
    this.saveRef === Constants.WIZARD_MENU_REF.Master ? this.menuService.setSuccess(true) : this.subTabMenuService.setSuccess(true);
    await this.refreshImportsDatasets();
  }

  private menuAndSubMenuSetSuccess(success: boolean): void {
    this.menuService.setSuccess(success);
    this.subTabMenuService.setSuccess(success);
  }

  public onAdd(): void {
    this.grid.setLastRowSelected('useInProject');
    this.refreshSelectedValues();
    this.enableControls(false);
  }

  public onDropdownChange({ row }: { row: EndpointDatasetParamsData }): void {
    this.checkIfTheDatasetIsValid(row.description, row.moleculePk, row.dataSetPk);
  }

  public onBlurInputText({ row }: { row: EndpointDatasetParamsData }): void {
    this.checkIfTheDatasetIsValid(row.description, row.moleculePk, row.dataSetPk);
  }

  public async onDelete( dataTransaction: EndpointDatasetGridTransaction[] ): Promise<void> {
    const deletedRow = dataTransaction.find(
      (row) => row.transactionType === Constants.TRANSACTION_TYPE.DELETE
    );
    const datasetPk: number = deletedRow?.row?.dataSetPk ?? 0;
    const isExpanded: boolean | undefined = deletedRow?.row.isExpanded;
    const hasLinkToOtherProjects = await this.logicService.existsDatasetByPkWithFk(
      this.selectedProject?.projectPk!,
      datasetPk
    );

    const deleteDataset = async () => {
      if (datasetPk > 0) await this.deleteSavedDataset(datasetPk, isExpanded);
      else if (deletedRow != undefined)
        this.rowData = this.rowData.filter(
          (r) =>
            r.description !== deletedRow.row.description &&
            r.moleculePk !== deletedRow.row.moleculePk
        );
      else this.rowData.splice(0, 1);

      if (isExpanded) {
        this.disableImportDataset(false);
        this.showControls = true;
      }
    };

    if (hasLinkToOtherProjects > 0) {
      const result = await Swal.fire({
        title: 'This dataset is being used in other projects',
        text: 'If you delete it, it will be deleted from the other projects as well. Do you still want to proceed?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      });

      if (result.isConfirmed) {
        await deleteDataset();
      } else {
        Swal.fire('Cancelled', 'Your dataset is safe :)', 'error');
        await this.setRowData();
      }
    } else {
      await deleteDataset();
    }

    this.refreshSelectedValues();

  }

  public async onButtonExpandClick({data} : {data: EndpointDatasetParamsData}, isExpanded?: boolean): Promise<void> {
    if(isExpanded != undefined && isExpanded)
    data.isExpanded = true;

    if(data.isExpanded){
      this.updateSelectedDataSetObservable(data);
      this.disableImportDataset(true);
      this.showControls = false;
      this.rowData = this.rowData.filter( (r) => r.moleculePk == data.moleculePk && r.description == data.description && r.dataSetPk == data.dataSetPk);
      await this.expandDataset(data);
      this.refreshSelectedValuesTimeout();
    }
    else {
      this.updateSelectedDataSetObservable(undefined);
      this.showControls = true;
      await this.collapseDataset(data);
      this.disableImportDataset(false);
    }
  }

  private async expandDataset(data: EndpointDatasetParamsData): Promise<void> {
    let transactionList = this.grid ?.transactionList as unknown as EndpointDatasetGridTransaction[];

    if ( transactionList != undefined && transactionList.some((t) => t.row.modified) )
      await this.saveExpandedModifiedDataset(data);
    else this.emmitExpandedDataset(data as DataSet);

  }

  private async collapseDataset( data: EndpointDatasetParamsData ): Promise<void> {
    this.isLoading = true;
    if (data.modified != undefined && data.modified) {
      this.saveCollapsedModifiedDataset();
    } else {
      this.emitCollapsedDataset();
    }
  }

  private async saveCollapsedModifiedDataset(): Promise<void> {
    this.showDetail.emit(false);
    await this.prepareToSave();
    this.getDatasetsAndSaveEndpoints();
  }

  private async saveExpandedModifiedDataset( data: EndpointDatasetParamsData ): Promise<void> {
    await this.prepareToSave();
    data.modified = false;
    const dataset = await this.logicService.getDatasetByProjectpkMoleculePkAndName( data.description!, data.moleculePk!, this.projectPk );
    dataset.isExpanded = true;
    this.rowData = [dataset];
    this.emmitExpandedDataset(dataset);
    this.grid.transactionList = [];
    await this.refreshImportsDatasets();
  }

  private async emitCollapsedDataset(): Promise<void> {
    this.getDatasetsAndSaveEndpoints();
    this.showDetail.emit(false);
  }

  private emmitExpandedDataset(dataset: DataSet): void {
    dataset.activeIngredient = this.logicService.molecules.find((m) => m.moleculePk == dataset.moleculePk )!.moleculeName;
    this.updateSelectedDataSetObservable(dataset);
    this.datasetData.emit(dataset);
    this.showDetail.emit(true);
  }

  private async getDatasetsAndSaveEndpoints(): Promise<void> {
    await this.setRowData();
    this.isLoading = false;
    if(this.environmentalAssessment == Constants.ENVIRONMENTAL_ASSESSMENTS.ENVIRONMENTAL_EXPOSURE_ASSESSMENT ||
       this.environmentalAssessment == Constants.ENVIRONMENTAL_ASSESSMENTS.HUMAN_HEALTH_RISK_ASSESSMENT) {
         this.endpointByCompartmentLogicService.runSaveObservable();
        }
    await this.refreshImportsDatasets();
    this.refreshSelectedValuesTimeout();
  }

  private async deleteSavedDataset( datasetPk: number, isExpanded: boolean | undefined ): Promise<void> {
    this.blockUi = true;
    await this.logicService.deleteDatasetWithEndpoints(datasetPk);
    this.rowData = this.rowData.filter((r) => r.dataSetPk != datasetPk);
    if (isExpanded != undefined && isExpanded) {
      this.showDetail.emit(false);
      await this.setRowData();
    }
    this.blockUi = false;
    await this.refreshImportsDatasets();
  }

  private enableControls(enable: boolean): void {
    this.isValid.emit(enable);
    this.showControls = enable;
    this.grid.useAddAction = enable;
    this.grid.columnDefs.find( (c: any) => c.colId == 'show' ).cellRendererParams.disabled = !enable;
    this.grid.columnDefs.find( (c: any) => c.colId == 'importDataset' ).cellRendererParams.disabled = !enable;
    this.grid.columnDefs.find( (c: any) => c.colId == 'delete' ).cellRendererParams.disabled = !enable;
    this.grid.gridApi.setColumnDefs(this.columnDefs);
  }

  private async checkIfTheDatasetIsValid(datasetName: string | undefined, moleculePk: number | undefined, datasetPk: number | undefined): Promise<void> {
    const invalidRows = this.rowData.some((row) =>
      row.description == undefined ||
      row.description == '' ||
      row.moleculePk == -1 ||
      row.moleculePk == undefined);

    const transactionOcurrences = this.logicService.getTransactionOccurrences(this.rowData);
    const duplicateDatasetsInTransaction = this.logicService.duplicateDataInRecord(transactionOcurrences);
    datasetPk = datasetPk ?? -1;

    let existsDatasetProjectPk: number = -1;

    if (datasetName != undefined && datasetName != '' && moleculePk != undefined) {
      this.blockUi = true;
      existsDatasetProjectPk = await this.logicService.existsDatasetByName(datasetName, moleculePk, datasetPk);
      this.blockUi = false;

      if (duplicateDatasetsInTransaction)
        Utils.showErrorMessage(
          'The Dataset name already exists on selected active ingredient.',
          'Please use another name'
        );
      else if (existsDatasetProjectPk > 0)
        this.showExistingDatasetMessage(existsDatasetProjectPk);
    }
    let groupLimitExceeded = false;

    if (this.isSubstanceProperties) {
      const groupCounts = this.rowData.reduce((acc: any, row: any) => {
        acc[row.calculatorGroup] = (acc[row.calculatorGroup] || 0) + 1;
        return acc;
      }, {});
      groupLimitExceeded = Object.values(groupCounts).some((count: any) => {
        return count > 4;
      });
      if (groupLimitExceeded) {
        Utils.showErrorMessage(
          'A group cannot have more than 4 items.',
          'Please adjust the groups.'
        );
      }
    }

    if ( invalidRows || existsDatasetProjectPk > 0 || duplicateDatasetsInTransaction || groupLimitExceeded )
      this.enableControls(false);
    else {
      this.enableControls(true);
    }
  }

  private async refreshImportsDatasets(): Promise<void>{
    this.blockUi = true;
    await this.logicService.getDataseByMoleculesPks(this.getProjectMoleculePks());
    this.grid.gridApi.redrawRows();
    this.blockUi = false;
  }

  public async onCopyDataset(datasetToImport: ImportDataSet): Promise<void> {
    const hasEndpoints: boolean = datasetToImport.hasEndpoints ?? false;
    const originDatasetDescription = this.logicService.datasetsToImport.find(d => d.dataSetPk == datasetToImport.originDataSetPk)?.description;

    if (this.environmentalAssessment == Constants.ENVIRONMENTAL_ASSESSMENTS.ENVIRONMENTAL_RISK_ASSESSMENT && originDatasetDescription?.toLowerCase().startsWith('echo-')) {
      let taxonGroup: string = '';
      this.subTabMenuService.activeItem.subscribe(value => {
        taxonGroup = value.label;
      }, pipe(take(1)));

      this.eraEcotoxImportPopupParameters = {
        datasetToImport,
        taxonGroup,
        geography: this.selectedProject!.geography!
      }
      await this.refreshImportsDatasets();
    }
    else {
      const messageText: string = hasEndpoints ? Constants.TITLE_CONFIRM_TO_OVERWRITE : Constants.TITLE_CONFIRM_TO_COPY;
      const actionResult = await Utils.showConfirmationMessage(messageText);
      if (actionResult.isConfirmed) {
        await this.blockUiAndSave(datasetToImport);
      }
    }
  }

  private disableImportDataset(disable: boolean): void {
    if (this.grid?.columnDefs == undefined) return;
    this.grid.columnDefs.find( (c: any) => c.colId == 'importDataset' ).cellRendererParams.disabled = disable;
    this.grid.gridApi.setColumnDefs(this.columnDefs);
  }

  private async showExistingDatasetMessage(
    existsDatasetProjectPk: number
  ): Promise<void> {
    const { name, description, projectPk, createdBy } =
      await this.logicService.getProjectByPk(existsDatasetProjectPk);
    Utils.showErrorMessageHtml(
      'The Dataset name already exists on selected active ingredient.',
      `<br> <b> Project:</b> ${name} - <b>${projectPk}</b>
     <br> <b> Created by:</b> ${createdBy}
     <br> <b> Description:</b> ${description || 'No description'}
     <br> <b><u>Please use another name</u></b>`
    );
  }

  private async ImportDataset(datasetToImport: ImportDataSet): Promise<void> {
    datasetToImport.source = this.environmentalAssessment;
    datasetToImport.dataToCopy = this.logicService.getDataToCopy( this.environmentalAssessment );
    datasetToImport.endpointNames = this.logicService.getEndpointsByModelsSelected();

    await this.logicService.importDataSetInfo(datasetToImport);
    if (this.grid?.transactionList) {
      let transactionList = this.grid ?.transactionList as unknown as EndpointDatasetGridTransaction[];
      this.grid.transactionList = transactionList.filter(  (t) => t.row.description != datasetToImport.description ) as any;
      await this.prepareToSave();
      this.grid.transactionList = [];
    }
  }

  private updateSelectedDataSetObservable(dataSet: DataSet | undefined): void {
    this.logicService.updateSelectedDataSet(dataSet);
  }

  private getSelectedDataSetObservable(): void {
    this.logicService.selectedDataSet$.subscribe((value) => {
      if (this.rowData.some((r) => r.dataSetPk == value?.dataSetPk))
        this.selectedDataSetObservable = value;
      else this.selectedDataSetObservable = undefined;
    });
  }

  private setSelectedDataSetObservable(): void {
    if (this.selectedDataSetObservable) {
      this.rowData.forEach((r) => {
        if (r.dataSetPk == this.selectedDataSetObservable?.dataSetPk)
          r.isExpanded = true;
      });
      this.onButtonExpandClick({ data: this.selectedDataSetObservable }, true);
    }
  }

  private async updateStatusFlagWhenEraDestroy(): Promise<void> {
    const row = this.rowData.find((r) => r.dataSetPk == this.savedEraDatasetPk);
    if (row != undefined) {
      this.isLoading = true;
      await this.setRowData();
      this.isLoading = false;
    }
  }

  public onImportEchoEra(datasetToImport: ImportDataSet): void {
    datasetToImport.importedFromEcho = true;
    this.blockUiAndSave(datasetToImport);
  }

  public async useExistingDS(event: Project, source: string): Promise<void> {
    this.prepareToSave();
    this.useExistingDSDialog.showDialog(true, event, source);
  }

  private async blockUiAndSave(datasetToImport: ImportDataSet): Promise<void> {
    this.blockUi = true;
    this.grid.selectOrUnselectRow(datasetToImport.rowIndex, true);
    await this.ImportDataset(datasetToImport);
    await this.setRowData();
    this.blockUi = false;
    await this.refreshImportsDatasets();
    Utils.showSuccessMessage(Constants.MESSAGE_COPY_SUCCESSFULLY);
  }

  private refreshSelectedValuesTimeout(): void {
    setTimeout(() => {
      this.refreshSelectedValues();
    }, 75);
  }

  private refreshSelectedValues(): void {
    if(! this.grid ) return;
    this.grid.refreshSelectedValues('useInProject');
  }

  public onSelectedRow (event: RowSelectedEvent): void {
    if( !this.grid ) return;
    this.createTransactionForSelectedRow( event.node );
  }

  public onSelectionChanged ( event: SelectionChangedEvent ){
    if( event.source !== 'uiSelectAll' || !this.grid ) return;
    this.grid.gridApi.forEachNode((node: any) => {
     this.createTransactionForSelectedRow(node);
    });
  }

  private createTransactionForSelectedRow( node: any ): void {
    const { id, data } = node;
    data.useInProject = node.isSelected();
    this.grid.CreateTransaction(id, id, data.useInProject, data);
  }

}
