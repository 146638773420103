<ng-container *ngIf="!floating && !useTitle">
  <label [ngClass]="!isValid && isRequired ? 'lbl-invalid' : 'lbl-valid'" *ngIf="label">{{label}}</label>
  <p-dropdown class="w-12" [ngClass]="!isValid && isRequired ? 'dd-invalid' : 'dd-valid'" [(ngModel)]="model" [options]="options" [id]="id" [optionLabel]="optionLabel" [optionValue]="optionValue" [showClear]="showClear" [filter]="filter" [filterBy]="filterBy" [style]="style" [placeholder]="placeholder" [dropdownIcon]="dropdownIcon" [disabled]="disabled" [scrollHeight]="scrollHeight" (onChange)="onSelectionChanged($event)"></p-dropdown>
</ng-container>

<span *ngIf="floating" class="p-float-label">
  <p-dropdown class="w-12" [ngClass]="!isValid && isRequired ? 'dd-invalid' : 'dd-valid'" [(ngModel)]="model" [options]="options" [id]="id" [optionLabel]="optionLabel" [optionValue]="optionValue" [showClear]="showClear" [filter]="filter" [filterBy]="filterBy" [style]="style" [placeholder]="placeholder" [dropdownIcon]="dropdownIcon" [disabled]="disabled" [scrollHeight]="scrollHeight" (onChange)="onSelectionChanged($event)"></p-dropdown>
  <label [ngClass]="!isValid && isRequired ? 'lbl-invalid' : 'lbl-valid'" [htmlFor]="id">{{label}}</label>
</span>

<div *ngIf="useTitle" class="flex flex-column gap-2">
  <label [ngClass]="!isValid && isRequired ? 'lbl-invalid' : 'lbl-valid'" [htmlFor]="id">{{label}}</label>
  <p-dropdown class="w-12" [ngClass]="!isValid && isRequired ? 'dd-invalid' : 'dd-valid'" [(ngModel)]="model" [options]="options" [id]="id" [optionLabel]="optionLabel" [optionValue]="optionValue" [showClear]="showClear" [filter]="filter" [filterBy]="filterBy" [style]="style" [placeholder]="placeholder" [dropdownIcon]="dropdownIcon" [disabled]="disabled" [scrollHeight]="scrollHeight" (onChange)="onSelectionChanged($event)"></p-dropdown>
</div>