import { Injectable } from '@angular/core';
import { Utils } from '../../../../../shared/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class EEAOutputProjectMacrogwInputsUsedInCalculationColdef {
  constructor() {}

  private columnDefs = [
    {
      headerName: 'General',
      children: [
        {
          headerName: 'Run ID', 
          editable: false, 
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'runId',
          width: 120,
          filter: 'agSetColumnFilter',
          pinned: 'left',
        },
        {
          headerName: 'Model',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'model',
          width: 120,
          filter: 'agSetColumnFilter',
          pinned: 'left',
        },
      ],
    },
    {
      headerName: 'Application Pattern (GAP)',

      children: [
        {
          headerName: 'Application Scheme',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'applicationSchemeName',
          width: 300,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Crop (Interception)',
          editable: false,
          wrapText: true,
          resizable: true,
          sortable: true,
          field: 'cropInterception',
          width: 170,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Crop (AppDate)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'cropAppDate',
          width: 150,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: `Number Of \n Application`,
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'applicationsNumber',
          width: 110,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: `Application  \n  Interval (d)` ,
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'applicationInterval',
          width: 120,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Substance Name',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          cellRenderer: 'agGroupCellRenderer',
          field: 'activeIngredient',
          width: 200,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: `Rate \n (g/ha)` ,
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'rate',
          width: 100,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: `BBCH \n (Earliest)` ,
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'BBCHEarliest',
          width: 110,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: `Interception \n (%)` ,
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'interception',
          width: 130,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: `Date Type` ,
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'dateType',
          width: 120,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: `Application Metod` ,
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'applicationMethod',
          width: 130,
          filter: 'agSetColumnFilter',
        },
        {
          headerName:`Soil Depth \n Incorporated (cm)` ,
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'soilDepthIncorporated',
          width: 150,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: `Repeat Interval \n for Application Event` ,
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'repeatIntervalforApplicationEvent',
          width: 130,
          type: 'select',
          filter: 'agSetColumnFilter',
        }
      ],
    },
    {
      headerName: 'Endpoints',

      children: [
        {
          headerName: 'Endpoint Data Set',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'endpointsDataSetName',
          width: 200,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Active Ingredient',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,          
          field: 'substanceName',
          width: 200,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Molecular \n Weight (g/mol)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'molecularWeight',
          width: 110,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Saturated Vapour \n Pressure (Pa)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'saturatedVapourPressure',
          width: 130,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Solubility \n In Water (mg/L)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'solubilityInWater',
          width: 110,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: `Kfoc` ,
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'kfoc',
          valueFormatter:(params:any)=> Utils.convertNumberToExponential(params.value),
          width: 110,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: `Freundlich sorption \n exponent in soil (-)` ,
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          valueFormatter:(params:any)=> Utils.convertNumberToExponential(params.value),
          field: 'freundlichSorptionExponentInSoil',
          width: 150, 
          filter: 'agSetColumnFilter',
        },
        {
          headerName: `Half-life \n in soil (d)` ,
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'halfLifeInSoil',
          width: 110,
          valueFormatter: (params:any)=> Utils.convertNumberToExponential(params.value),
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Coefficient For Uptake \n By Plant (TSCF)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'coefficientForUptakeByPlant',
          width: 180,
          valueFormatter: (params:any)=> Utils.convertNumberToExponential(params.value),
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Foliar \n Half-life (d)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'foliarHalfLife',
          width: 110,
          valueFormatter: (params:any)=> Utils.convertNumberToExponential(params.value),
          filter: 'agSetColumnFilter',
        },
      ],
    },
  ];

  getColumnsDefinition(): any {
    return this.columnDefs;
  }
}
