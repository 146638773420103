<div class="grid" *ngIf="!isLoading" style="margin-bottom: -11px;">
    <p-accordion class="col-12">
        <p-accordionTab header="{{headerNonDietary}}">
            <ng-template pTemplate="content">
                <div class="col-12">
                    <div class="flex flex-row-reverse flex-wrap">
                        <app-button label="All files" iconPos="right" [outlined]="true" severity="secondary"
                            [icon]="isAllFilesDownloading ? 'pi pi-spin pi-spinner' : 'pi pi-download'"
                            (onClickEvent)="onDownloadClick()"></app-button>
                        <app-button style="margin-right: 5px;" label="Refresh" iconPos="right" [outlined]="true" severity="secondary"
                            [icon]="isRefreshingIcon ? 'pi pi-spin pi-spinner' : 'pi pi-refresh'"
                            (onClickEvent)="refreshData()"></app-button>
                        <div class="col-2" style="padding-top: 8px; padding-left: 94px;">
                            <span>Project Status: &nbsp; <strong>{{ projectStatusStyle.statusName
                                    }}</strong></span>&nbsp;
                            <p-badge pTooltip="{{selectedProject?.description}}" tooltipPosition="top"
                                styleClass="{{projectStatusStyle.badgeStyle}}"></p-badge>
                        </div>
                    </div>
                    <app-hhra-run-risk-assessment-opex-result (status)="closeModal($event)"
                        [selectedProject]="selectedProject" [subTabMenuService]="menuService"
                        [isProjectOwnershipValid]="isProjectOwnershipValid"
                        [opexInputsCombinations]="opexInputsCombinations"></app-hhra-run-risk-assessment-opex-result>
                </div>
            </ng-template>
        </p-accordionTab>
        <p-accordionTab header="{{headerDietary}}">
            <ng-template pTemplate="content">
                <div class="col-12">

                </div>
            </ng-template>
        </p-accordionTab>
    </p-accordion>
</div>
<app-loading class="mt-2 flex align-items-center justify-content-center" *ngIf="isLoading"></app-loading>