import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { EfateApiService } from 'src/app/shared/services/echo/efate.api.service';
import { Constants } from 'src/app/shared/utils/constants';
import { EEALogicService } from '../../environmental-exposure-assessment/environmental-exposure-assessment.logic.service';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';

@Injectable({
  providedIn: 'root'
})
export class EEAEndpointsByCompartmentLogicService {

  constructor(private readonly efateService: EfateApiService,
    public EEALogicService: EEALogicService,
    public projectApiService: SelectedProjectApiService) { }

  private dataToSave = new BehaviorSubject<any>([]);
  public dataToSave$ = this.dataToSave.asObservable();

  private excludeMetabolite = new BehaviorSubject<boolean | undefined>(undefined);
  public excludeMetabolite$ = this.excludeMetabolite.asObservable();
  
  updateDataToSave(newValue: any){
    this.dataToSave.next(newValue);
  }

  public runSaveObservable(): void {
    let dataToSave: any = undefined;
    this.dataToSave$.subscribe((data) =>{
      dataToSave = data;
    });
    if(dataToSave){
      if(dataToSave.length > 0){
        this.saveData(dataToSave);
      }
    }
  }

  public saveData(pData: any) : Promise<boolean> {
    this.EEALogicService.updateIsLoading(true);
    return new Promise<boolean>((resolve, reject) =>{
      forkJoin([
        this.efateService.saveData(pData, Constants.THIS_APPLICATION)
      ]).subscribe({
        next: ([_afterSave]) => {
          this.updateDataToSave(undefined);
          this.EEALogicService.updateIsLoading(false);
          resolve(true);
        },
        error: (err) => {
          console.warn(err);
          reject(false);
        }
      });
    });
  }

  public excludeMetaboliteFromAI(value: boolean | undefined) {
    this.excludeMetabolite.next(value);
  }

}
